import React from 'react';
import {WHITE_LABEL_EXTERNAL_CALENDAR_URLS} from '../../utils/constants';
import useCompany from '../hooks/useCompany';
import Iframe from './Iframe';

const ExternalCalendar = ({...props}) => {
  const {slug} = useCompany();
  const calendarSrc = WHITE_LABEL_EXTERNAL_CALENDAR_URLS[slug];

  return <Iframe width="100%" src={calendarSrc} {...props} />;
};

export default ExternalCalendar;
