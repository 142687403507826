import React, {forwardRef} from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Flex from './Flex';
import Popover from './Popover';
import Loader from './Loader';

const Dropdown = ({listItems, children, ...props}) => (
  <Popover content={listItems} {...props}>
    {children}
  </Popover>
);

const DropdownButton = forwardRef((props, ref) => (
  <button type="button" ref={ref} {...props} />
));

const DropdownListItem = ({
  disabled,
  isLoading,
  iconName = null,
  onClick,
  children,
  ...props
}) => (
  <ListItem>
    <DropdownListButton
      disabled={disabled || isLoading}
      onClick={onClick}
      {...props}>
      {isLoading ? (
        <Loader color="primary" size={24} mr={3} />
      ) : (
        iconName !== null && <Icon mr={3} color="primary" name={iconName} />
      )}
      <Flex mr={3} flex={1} color="grey.5" fontSize={1} textAlign="left">
        {children}
      </Flex>
    </DropdownListButton>
  </ListItem>
);

const DropdownNavigationItem = ({
  disabled,
  isLoading,
  iconName,
  onClick,
  children,
  ...props
}) => (
  <ListItem>
    <DropdownListButton
      disabled={disabled || isLoading}
      onClick={onClick}
      {...props}>
      {isLoading ? (
        <Loader color="primary" size={24} mr={3} />
      ) : (
        iconName !== null && <Icon mr={3} color="primary" name={iconName} />
      )}
      <Flex mr={3} flex={1} color="grey.5" fontSize={1} textAlign="left">
        {children}
      </Flex>
      <Icon name="chevronRight" color="primary" />
    </DropdownListButton>
  </ListItem>
);

const DropdownList = styled.ul(
  ({theme}) => `
    padding: ${theme.space[5]};
    display: flex;
    flex-direction: column;
    min-width: 20rem;
    position: relative;
    box-shadow: ${theme.shadows[0]};
    border-radius: ${theme.radii[0]};
    background-color: ${theme.colors.white};
`,
);

const ListItem = styled.li(
  ({theme}) => `
    padding: ${theme.space[3]};
    border-radius: ${theme.radii[0]};
    :hover {
        background: ${theme.colors.grey[1]};
    }
`,
);

const DropdownListButton = styled.button.attrs({type: 'button'})(
  ({disabled}) => `
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: normal;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
`,
);

Dropdown.List = DropdownList;
Dropdown.Button = DropdownButton;
Dropdown.ListItem = ListItem;
Dropdown.DropdownListItem = DropdownListItem;
Dropdown.NavigationItem = DropdownNavigationItem;

export default Dropdown;
