import {useQuery, gql} from '@apollo/client';

export const EMBED_BLOCK_QUERY = gql`
  query embedBlockQuery($url: String!) {
    openGraph(url: $url)
      @rest(
        type: "OpenGraph"
        path: "/helpers/open-graph?{args}"
        method: "GET"
      ) {
      id
      title
      description
      og {
        siteName
        url
        title
        image
        imageWidth
        imageHeight
        description
        type
        videoUrl
        videoSecureUrl
        videoType
        videoWidth
        videoHeight
      }
    }
  }
`;

const useFetchEmbedBlockData = ({url}) => {
  const variables = {url};
  const {
    data = {},
    error,
    loading: isFetching,
  } = useQuery(EMBED_BLOCK_QUERY, {variables});

  if (typeof error !== 'undefined') {
    return {error};
  }
  if (isFetching) {
    return {
      isFetching,
      openGraph: {},
    };
  }

  const {openGraph} = data;

  return {
    isFetching,
    openGraph,
  };
};

export default useFetchEmbedBlockData;
