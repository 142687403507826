import React from 'react';
import MessagesPage from './MessagesPage';
import {MessagesFilterProvider} from './hooks/MessagesFilters';

const MessagesPageWithState = () => (
    <MessagesFilterProvider>
      <MessagesPage />
    </MessagesFilterProvider>
  );

export default MessagesPageWithState;
