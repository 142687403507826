import React from 'react';
import ReactTooltip from 'react-tooltip';
import {Trans} from '@lingui/macro';
import {useTheme} from 'styled-components';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Paragraph from '../../utils/Paragraph';
import Button from '../../utils/Button';
import Text from '../../utils/Text';

const ProfileInfoCard = ({children, ...props}) => {
  const theme = useTheme();

  return (
    <Flex
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      py={5}
      bg="grey.1"
      borderRadius={0}
      {...props}>
      <Icon name="info" />
      <Paragraph variant={3} flex={1} mx={3} mb="none">
        {children}
      </Paragraph>
      <Button
        display={['none', 'block']}
        variant="link2"
        color="primary"
        data-tip
        data-for="info">
        ?
      </Button>
      <ReactTooltip
        id="info"
        backgroundColor={theme.colors.primary}
        textColor={theme.colors.white}>
        <Text fontFamily="regular">
          <Trans>
            Contact the admin if you want to change these contact details.
          </Trans>
        </Text>
      </ReactTooltip>
    </Flex>
  );
};

export default ProfileInfoCard;
