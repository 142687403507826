import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

const parseUser = user => {
  const {id, email, firstName, lastName} = user;
  const userName = `${firstName} ${lastName}`;
  return {
    id,
    email,
    userName,
  };
};

export const scopeUser = user => {
  const parsedUser = parseUser(user);
  Sentry.setUser(parsedUser);
};

export const removeScopedUser = () => {
  Sentry.setUser(null);
};

export default function initializeSentry() {
  const {REACT_APP_ENV, REACT_APP_SENTRY_DSN} = process.env;

  if (REACT_APP_ENV !== 'local') {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_ENV,
      integrations: [new BrowserTracing()],
      autoSessionTracking: REACT_APP_ENV === 'production',
      beforeSend(event, hint) {
        const error = hint.originalException;
        if (
          error &&
          error.message &&
          error.message.startsWith(
            'Response not successful: Received status code ',
          ) &&
          error.message.match(/(401|403|404|422)$/)
        ) {
          return null;
        }

        return event;
      },
    });
  }
}
