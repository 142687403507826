import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useQueryParam, StringParam} from 'use-query-params';
import {Trans} from '@lingui/macro';
import useAppState from '../../hooks/useAppState';
import useNotifications from '../../hooks/useNotifications';
import LoginOtpPage from './LoginOtpPage';
import useLoginOtpMutation from './useLoginOtpMutation';
import useLoginOtpFormState, {TOKEN_LENGTH} from './useLoginOtpFormState';

const LoginOtpPageWithState = () => {
  const history = useHistory();
  const {login} = useAppState();
  const {dispatchError} = useNotifications();
  const [isLoading, setIsLoading] = useState();
  const [token] = useQueryParam('token', StringParam);
  const [driver] = useQueryParam('driver', StringParam);
  const [receiver] = useQueryParam('receiver', StringParam);
  const [redirect] = useQueryParam('redirect', StringParam);
  const formState = useLoginOtpFormState({
    token: typeof token !== 'undefined' ? token : '',
    [driver]: receiver,
  });
  const [loginOtp] = useLoginOtpMutation();

  const handleCancel = () => history.push('/login');

  const handleSubmit = async () => {
    if (formState.validate()) {
      setIsLoading(true);
      try {
        const body = formState.valuesToInput();
        const variables = {body};
        const {
          data: {loginOtp: response},
        } = await loginOtp({variables});
        await login(response);
        if (typeof redirect !== 'undefined') {
          history.push(redirect);
        } else {
          history.push('/');
        }
      } catch (e) {
        if (typeof e.networkError !== 'undefined') {
          if (e.networkError.statusCode === 401) {
            dispatchError({
              message: <Trans>The code you provided is not valid.</Trans>,
            });
          }
          if (e.networkError.statusCode === 403) {
            dispatchError({
              message: (
                <Trans>You are not authorized to perform this action.</Trans>
              ),
            });
          }
        } else {
          dispatchError();
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (formState.values.token.length === TOKEN_LENGTH) {
      (async () => handleSubmit())();
    }
  }, [formState.values.token]);

  return (
    <LoginOtpPage
      formState={formState}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export default LoginOtpPageWithState;
