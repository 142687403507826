import React from 'react';
import ChooseChannelWithState from '../../../modules/editor/ChooseChannelWithState';
import useDialog from '../../hooks/useDialog';
import useCurrentUser from '../../hooks/useCurrentUser';
import usePermissions from '../../hooks/usePermissions';
import {configureChannels} from '../../../utils/channels';
import useUpdateConfiguredChannels from '../profile/useUpdateConfiguredChannels';

const ChooseChannelBlockWithState = () => {
  const dialog = useDialog();
  const currentUser = useCurrentUser();
  const permissions = usePermissions();
  const [updateConfiguredChannels] = useUpdateConfiguredChannels();

  const handleUpdateUserChannels = async (
    userChannels,
    updateQuery,
    userChannel,
    updatedValue,
  ) => {
    const {newChannels} = await configureChannels(
      userChannels,
      userChannel,
      !updatedValue,
      dialog,
    );
    const variables = {
      body: {
        channels: newChannels,
      },
    };
    const {
      data: {
        updateConfiguredChannels: {data: updatedUserChannels},
      },
    } = await updateConfiguredChannels({variables});
    updateQuery(prev => ({
      ...prev,
      userChannels: {
        ...prev.userChannels,
        data: updatedUserChannels,
      },
    }));
  };

  return (
    <ChooseChannelWithState
      onUpdateUserChannels={handleUpdateUserChannels}
      currentUser={currentUser}
      permissions={permissions}
    />
  );
};

export default ChooseChannelBlockWithState;
