import React from 'react';
import {isValid} from 'date-fns';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Tag from '../../utils/Tag';
import Flex from '../../utils/Flex';
import Text from '../../utils/Text';
import Title from '../../utils/Title';
import Label from '../../utils/Label';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';
import SurveyStatus from './SurveyStatus';
import SurveyRSVPItem from './SurveyRSVPItem';
import {formatDate, parseDate} from '../../../utils/dates';
import Icon from '../../../components/utils/Icon';

const SurveyRSVP = ({
  survey,
  onChange = null,
  selectedOption,
  isPublished,
  isPublishing,
  onAddToCalendar,
}) => {
  const {content, options} = survey;
  const {title, location, dateTime, description} = content;

  let day;
  let month;
  let time;
  const parsedDate = parseDate(dateTime);
  if (isValid(parsedDate)) {
    day = formatDate(parsedDate, 'dd');
    month = formatDate(parsedDate, 'MMM');
    time = formatDate(parsedDate, 'HH:mm');
  }

  const hasTime = typeof time !== 'undefined';
  const hasDescription = typeof description !== 'undefined';
  return (
    <Box p={6}>
      <Flex flexDirection="row" justifyContent="space-between" mb={3}>
        <Flex flex={1} flexDirection="row" mr={3}>
          <Flex
            flexDirection="column"
            py={3}
            px={4}
            mr={3}
            bg="primary"
            borderRadius={0}>
            <Text color="white" fontSize={2} fontFamily="extraBold">
              {day}
            </Text>
            <Text color="white" fontSize={0} fontFamily="medium">
              {month.toUpperCase()}
            </Text>
          </Flex>
          <Box>
            <Title mb={1} variant={3}>
              {title}
            </Title>
            <Flex flexDirection="row" flexWrap="wrap">
              <Flex flexDirection="row" mr={2}>
                <Icon name="marker" size="smaller" />
                <Tag ml={1} variant={2}>
                  {location}
                </Tag>
              </Flex>
              {hasTime && (
                <Flex flexDirection="row">
                  <Icon name="clock" size="smaller" />
                  <Tag ml={1} variant={2}>
                    {time}
                  </Tag>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
        <SurveyStatus isPublished={isPublished} isPublishing={isPublishing} />
      </Flex>
      {hasDescription && (
        <Paragraph mb={3} variant={2}>
          {description}
        </Paragraph>
      )}
      <Flex flexDirection="row" mx={-2}>
        {options.map(option => {
          const isSelected =
            selectedOption && selectedOption.value === option.value;
          if (isSelected) {
            return (
              <Button
                key={option.value}
                onClick={() => onChange(option)}
                disabled={onChange === null}
                flex={1}
                fontFamily="bold"
                fontSize={2}
                mx={2}
                my={3}>
                {option.label}
              </Button>
            );
          }
          return (
            <Button
              key={option.value}
              onClick={() => onChange(option)}
              disabled={onChange === null}
              variant="link1Outline"
              mx={2}
              my={3}>
              {option.label}
            </Button>
          );
        })}
      </Flex>
      <Box>
        <SurveyRSVPItem
          width="100%"
          icon="calendarAdd"
          onClick={onAddToCalendar}
          disabled={onChange === null}>
          <Label variant={2} color="grey.5">
            <Trans>Add to calendar</Trans>
          </Label>
        </SurveyRSVPItem>
      </Box>
    </Box>
  );
};

export default SurveyRSVP;
