import React from 'react';
import Flex from './Flex';
import Header from './Header';
import Loader from './Loader';
import PageContainer from './PageContainer';
import useAppState from '../hooks/useAppState';
import Page from './Page';

const PageLoading = () => {
  const {isAuthenticated} = useAppState();
  return (
    <>
      {isAuthenticated && <Header />}
      <Page>
        <PageContainer height="100%">
          <Flex
            px={7}
            flex={1}
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <Loader color="primary" />
          </Flex>
        </PageContainer>
      </Page>
    </>
  );
};

export default PageLoading;
