import React from 'react';
import styled from 'styled-components';
import {variant} from 'styled-system';
import Text from './Text';

const TagWithVariants = styled(Text)(
  variant({
    variants: {
      1: {
        fontSize: 1,
        lineHeight: 1,
      },
      2: {
        fontSize: 0,
        lineHeight: 0,
      },
    },
  }),
);

const Tag = ({children, ...props}) => (
  <TagWithVariants fontFamily="regular" color="grey.4" {...props}>
    {children}
  </TagWithVariants>
);

export default Tag;
