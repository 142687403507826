import React from 'react';
import FillIcon from '../../utils/FillIcon';
import Loader from '../../../components/utils/Loader';

const SurveyStatus = ({isPublished, isPublishing}) => {
  if (isPublishing) {
    return <Loader color="primary" size={24} />;
  }

  if (isPublished) {
    return <FillIcon name="checkRound" />;
  }

  return <FillIcon name="circle" />;
};

export default SurveyStatus;
