import React, {useRef} from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Avatar, {AVATAR_SIZE_LARGE} from '../../utils/Avatar';
import Icon from '../../utils/Icon';
import Button from '../../utils/Button';
import Heading from '../../utils/Heading';
import ProfileActionCard from './ProfileActionCard';

const ProfilePageHeader = ({
  avatar,
  lastName,
  firstName,
  isUploadingAvatar,
  onFileChange,
  ...props
}) => {
  const inputRef = useRef(null);
  const handleEditAvatar = () => inputRef.current.click();
  return (
    <>
      <Flex
        width={AVATAR_SIZE_LARGE}
        position="relative"
        height={AVATAR_SIZE_LARGE}
        mt={-AVATAR_SIZE_LARGE / 2}
        mx="auto"
        {...props}>
        <Avatar
          key={avatar && avatar.id}
          size="large"
          avatar={avatar}
          isLoading={isUploadingAvatar}
        />
        <Flex
          width={40}
          height={40}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          right={0}
          bg="primary"
          borderRadius={5}>
          <Button
            variant="unstyled"
            disabled={isUploadingAvatar}
            onClick={handleEditAvatar}>
            <Icon name={avatar === null ? 'upload' : 'pen'} color="white" />
          </Button>
          <input
            type="file"
            ref={inputRef}
            style={{display: 'none'}}
            onChange={e => {
              onFileChange(e.target.files);
            }}
            accept="image/*"
          />
        </Flex>
      </Flex>
      <Heading variant={2} textAlign="center" my={6}>
        {`${firstName} ${lastName}`}
      </Heading>
      {avatar === null && (
        <ProfileActionCard
          icon="image"
          actionText={<Trans>Upload a picture</Trans>}
          action={handleEditAvatar}
          hasBadge
          my={7}>
          <Trans id="You don't have a picture on your profile yet. Give your profile a face and upload a picture." />
        </ProfileActionCard>
      )}
    </>
  );
};

export default ProfilePageHeader;
