import React from 'react';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Label from '../../utils/Label';
import {StyledAnimatedCard} from './FileListItem';

const DirectoryListItem = ({directory, onOpenDirectory, ...props}) => (
  <StyledAnimatedCard
    py={5}
    px={6}
    mb={6}
    width="100%"
    borderRadius={0}
    bg="white"
    alignItems="center"
    boxShadow={0}
    whileTap={{scale: 1}}
    whileHover={{scale: 1.01}}
    onClick={onOpenDirectory}
    {...props}>
    <Flex flex={1} flexDirection="row" alignItems="center" mr={2}>
      <Icon name="folder" />
      <Label ml={3} variant={1}>
        {directory.name}
      </Label>
    </Flex>
    <Flex justifyContent="center">
      <Icon name="chevronRight" color="greyLight" />
    </Flex>
  </StyledAnimatedCard>
);

export default DirectoryListItem;
