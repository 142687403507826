import {useMutation, gql} from '@apollo/client';
import DEFAULT_FILE_FRAGMENT from '../fragments/defaultFile';

export const UPLOAD_FILE_MUTATION = gql`
  mutation uploadFileMutation($body: FileBodyInput!) {
    uploadFile(input: $body)
      @rest(
        type: "File"
        path: "/media/items"
        method: "POST"
        bodySerializer: "fileEncode"
      ) {
      ...DefaultFile
    }
  }
  ${DEFAULT_FILE_FRAGMENT}
`;

export const useUploadFileMutation = () => useMutation(UPLOAD_FILE_MUTATION);

export default async function uploadFile(apollo, mediaFile) {
  const body = {mediaFile};
  const variables = {body};
  const mutation = UPLOAD_FILE_MUTATION;
  const response = await apollo.mutate({mutation, variables});
  return response.data.uploadFile;
}
