import React from 'react';
import {getUrlBySize, FULL, SMALL, EXPAND} from '../../../utils/sizeHelpers';
import Flex from '../../utils/Flex';
import FileBlock from './FileBlock';
import ImageBlock from './ImageBlock';
import AudioBlock from './AudioBlock';
import VideoBlock from './VideoBlock';

const getMediaPropsFromSize = size => {
  if (size === SMALL) {
    return {
      width: '75%',
      mx: 'auto',
    };
  }
  if (size === EXPAND || size === FULL) {
    return {
      width: ['calc(100% + 4rem)', 'calc(100% + 18rem)'],
      maxWidth: '100vw',
      position: 'relative',
      left: '50%',
      transform: 'translateX(-50%)',
    };
  }
  return {
    width: '100%',
    px: '2rem',
  };
};

const MediaBlock = ({mediaItem, type, size}) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    {...getMediaPropsFromSize(size)}>
    {(() => {
      switch (type) {
        case 'image':
          return (
            <ImageBlock src={getUrlBySize(mediaItem, size)} height="auto" />
          );
        case 'audio':
          return (
            <AudioBlock
              src={getUrlBySize(mediaItem, FULL)}
              type={mediaItem.mime_type}
              width="100%"
            />
          );
        case 'video':
          return <VideoBlock src={getUrlBySize(mediaItem, FULL)} />;
        default:
          return (
            <FileBlock
              file={mediaItem}
              onOpenFile={() =>
                window.open(getUrlBySize(mediaItem, FULL), '_blank')
              }
            />
          );
      }
    })()}
  </Flex>
);

export default MediaBlock;
