import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Heading from '../../utils/Heading';
import Link from '../../utils/Link';
import Flex from '../../utils/Flex';
import ColleagueCard from '../colleagues/ColleagueCard';

const HomePageColleagues = ({colleagues, ...props}) => (
  <Box {...props}>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mb={6}>
      <Heading variant={2}>
        <Trans>New colleagues</Trans>
      </Heading>
      <Link variant={1} to="/colleagues">
        <Trans>View all</Trans>
      </Link>
    </Flex>
    {colleagues.map(colleague => (
      <ColleagueCard key={colleague.id} user={colleague} />
    ))}
  </Box>
);

export default HomePageColleagues;
