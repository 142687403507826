import React from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Icon from '../../utils/Icon';

const ExtraProfileButton = ({title, href, icon, color, ...props}) => {
  const {language} = useCurrentUser();
  const label = title[language] || title[Object.keys(title)[0]];

  const link = href[language] || href[Object.keys(href)[0]];

  return (
    <Button
      bg={color}
      onClick={() => {
        window.location.href = link;
      }}
      {...props}>
      <Icon color="white" name={icon} />
      <Title variant={3} color="white" ml={5}>
        {label}
      </Title>
    </Button>
  );
};

export default ExtraProfileButton;
