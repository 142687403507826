import {useQuery, gql} from '@apollo/client';
import DEFAULT_FILE_FRAGMENT from '../fragments/defaultFile';

export const MEDIA_ITEM_QUERY = gql`
  query mediaItemQuery($id: ID!) {
    mediaItem(id: $id)
      @rest(type: "File", path: "/media/items/{args.id}", method: "GET") {
      ...DefaultFile
    }
  }
  ${DEFAULT_FILE_FRAGMENT}
`;

const useFetchMediaItem = id => {
  const variables = {id};
  const {data, error, loading} = useQuery(MEDIA_ITEM_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  });

  if (typeof error !== 'undefined') {
    return {
      error,
    };
  }

  if (loading) {
    return {
      error,
      isFetching: true,
      mediaItem: {},
    };
  }

  const {mediaItem} = data;

  return {
    mediaItem,
    isFetching: false,
  };
};

export default useFetchMediaItem;
