import React from 'react';
import {Trans} from '@lingui/macro';
import useFormState, {createFormValidation} from 'use-form-state';

export const TOKEN_LENGTH = 6;

const validation = createFormValidation([
  {
    path: 'token',
    validate: token => token.length === TOKEN_LENGTH,
    message: <Trans>Please fill in a valid token.</Trans>,
  },
]);

const valuesToInput = ({token, ...values}) => ({
  ...values,
  password: token,
  grant_type: 'otp',
});

const useLoginEmailFormState = (values, options) =>
  useFormState(values, {
    validation,
    valuesToInput,
    ...options,
  });

export default useLoginEmailFormState;
