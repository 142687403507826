import React, {useState} from 'react';
import useNotifications from '../../hooks/useNotifications';
import useDeleteCommentMutation from '../comments/useDeleteCommentMutation';
import DeleteCommentModal from './DeleteCommentModal';

const DeleteCommentModalWithState = ({
  isOpen,
  onClose,
  onOpen,
  comment,
  userMessage,
  onDeleteComplete,
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [deleteComment] = useDeleteCommentMutation();
  const {dispatchError} = useNotifications();

  const handleDelete = async () => {
    setIsConfirming(true);
    try {
      const {id} = comment;
      const variables = {commentId: id, messageId: userMessage.id};
      await deleteComment({variables});
      onDeleteComplete(comment);
      onClose();
    } catch (error) {
      setIsConfirming(false);
      dispatchError({
        message: error.message,
      });
    }
  };

  return (
    <DeleteCommentModal
      isOpen={isOpen}
      isConfirming={isConfirming}
      onOpen={onOpen}
      onDelete={handleDelete}
      onCancel={onClose}
    />
  );
};

export default DeleteCommentModalWithState;
