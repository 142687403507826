import React from 'react';
import {useTheme} from 'styled-components';
import CalendarPage from './CalendarPage';

const CalendarPageWithState = () => {
  const theme = useTheme();

  return <CalendarPage theme={theme} />;
};

export default CalendarPageWithState;
