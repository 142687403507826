import 'reset-css';
import 'intersection-observer';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './styles/fonts.css';
import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import initializeSentry from './services/Sentry';
import AppWithState from './components/AppWithState';

initializeSentry();
const Root = (
  <React.StrictMode>
    <AppWithState />
  </React.StrictMode>
);

ReactDOM.render(Root, document.getElementById('root'));

// quick fix to remove iframe from local environment (annoying issue with react-error-overlay and current react-scripts version)
if (process.env.REACT_APP_ENV === 'local') {
  window.setTimeout(() => {
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('iframe')?.remove();
  }, 1000);
}
