import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {
  DEFAULT_PAGE_LIMIT,
  DEFAULT_LIST_PAGE,
  DEFAULT_SORT,
} from '../../utils/constants';
import DEFAULT_APP_DOMAIN from '../fragments/defaultAppDomain';
import useAppState from '../../components/hooks/useAppState';

const DEFAULT_VARIABLES = {
  page: DEFAULT_LIST_PAGE,
  limit: DEFAULT_PAGE_LIMIT,
  isRead: 0,
  sort: DEFAULT_SORT,
};

export const UNREAD_MESSAGES_QUERY = gql`
  query unreadMessagesQuery(
    $page: Int
    $limit: Int
    $isRead: Boolean
    $sort: String
  ) {
    userMessages(page: $page, limit: $limit, is_read: $isRead, sort: $sort)
      @rest(
        type: "UserMessages"
        path: "/user-messages?{args}"
        method: "GET"
      ) {
      data {
        id
        title
        isBookmarked
        isCheered
        isRead
        cheerCount
        commentsCount
        createdAt
        updatedAt
        scheduledAt
        messageId
        acceptAnswers
        enableComments
        body {
          blocks {
            key
            text
            type
            depth
            entityRanges
            data
          }
          entityMap {
            type
            mutability
            data {
              url
            }
          }
        }
        appDomain {
          ...DefaultAppDomain
        }
        featuredImage {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            small
            regular
          }
          customProperties {
            generatedConversions {
              small
              thumb
              regular
            }
          }
        }
      }
      meta {
        currentPage
        from
        lastPage
        path
        perPage
        to
        total
      }
    }
  }
  ${DEFAULT_APP_DOMAIN}
`;

export const useUnreadMessagesQuery = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const {updateBadgeCount} = useAppState();

  const variables = DEFAULT_VARIABLES;
  const {error, loading, data, fetchMore, refetch} = useQuery(
    UNREAD_MESSAGES_QUERY,
    {variables},
  );

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (loading) {
    return {
      isFetching: true,
      userMessages: [],
    };
  }

  const {
    userMessages: {
      data: userMessages,
      meta: {currentPage, lastPage},
    },
  } = data;

  const hasMorePages = currentPage < lastPage;
  const handleFetchMore = async () => {
    if (!isFetchingMore && hasMorePages) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            userMessages: {
              ...fetchMoreResult.userMessages,
              data: [
                ...prev.userMessages.data,
                ...fetchMoreResult.userMessages.data,
              ],
            },
          };
        },
      });
      setIsFetchingMore(false);
    }
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    await updateBadgeCount();
    setIsRefetching(false);
  };

  return {
    isFetching: false,
    isFetchingMore,
    isRefetching,
    hasMorePages,
    fetchMore: handleFetchMore,
    refetch: handleRefetch,
    userMessages,
  };
};

export default async function getUnreadMessages(apollo, options) {
  const query = UNREAD_MESSAGES_QUERY;
  const variables = DEFAULT_VARIABLES;
  const response = await apollo.query({query, variables, ...options});
  return response.data.userMessages.data;
}
