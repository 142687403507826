import React from 'react';
import styled from 'styled-components';
import {StyledInput} from './Input';

const InputTextArea = ({...props}) => (
  <TextArea
    rows={4}
    as="textarea"
    borderRadius={0}
    p={3}
    bg="grey.2"
    color="grey.5"
    fontFamily="regular"
    {...props}
  />
);

const TextArea = styled(StyledInput)`
  resize: none;
`;

export default InputTextArea;
