import React from 'react';
import {Trans} from '@lingui/macro';
import {
  MEDIA_BLOCK,
  SURVEY_BLOCK,
  EMBED_BLOCK,
  CHOOSE_CHANNELS_BLOCK,
} from '../../../utils/customBlocks';

import EditorBlockContainer from '../../../modules/editor/EditorBlockContainer';
import MediaBlockWithState from '../../../modules/editor/media/MediaBlockWithState';
import EmbedBlockWithState from '../../../modules/editor/EmbedBlockWithState';

import PublicMediaBlock from '../../../modules/editor/media/PublicMediaBlock';
import SurveyBlockWithState from './SurveyBlockWithState';
import ChooseChannelBlockWithState from './ChooseChannelBlockWithState';
import Box from '../../../modules/utils/Box';
import Paragraph from '../../../modules/utils/Paragraph';

const AtomicBlock = ({block, blockProps}) => {
  const dataMap = block.getData();
  const type = dataMap.get('type');
  const {acceptAnswers, messageId, company, isPublic, mediaItems} = blockProps;

  switch (type) {
    case MEDIA_BLOCK:
      if (isPublic) {
        return (
          <PublicMediaBlock
            mediaItems={mediaItems}
            mediaItemId={dataMap.get('mediaItemId')}
            size={dataMap.get('size')}
          />
        );
      }
      return (
        <MediaBlockWithState
          mediaItemId={dataMap.get('mediaItemId')}
          size={dataMap.get('size')}
        />
      );
    case SURVEY_BLOCK:
      if (isPublic) {
        return null;
      }
      if (acceptAnswers) {
        return (
          <Box px={2}>
            <SurveyBlockWithState
              surveyId={dataMap.get('surveyId')}
              messageId={messageId}
              company={company}
            />
          </Box>
        );
      }
      return (
        <EditorBlockContainer p={6}>
          <Paragraph m={0} variant={2}>
            <Trans>This survey is closed.</Trans>
          </Paragraph>
        </EditorBlockContainer>
      );

    case EMBED_BLOCK:
      return (
        <Box px={2}>
          <EmbedBlockWithState url={dataMap.get('embedUrl')} />
        </Box>
      );
    case CHOOSE_CHANNELS_BLOCK:
      if (isPublic) {
        return null;
      }
      return (
        <Box px={2}>
          <ChooseChannelBlockWithState />
        </Box>
      );
    default:
      // @TODO: add better fallback
      return <p>Unsupported block type: {type}</p>;
  }
};

export default AtomicBlock;
