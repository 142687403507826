import React from 'react';
import Box from '../../utils/Box';
import Flex from '../../utils/Flex';
import Mood from '../../utils/Mood';
import Button from '../../utils/Button';
import SurveyBlockHeader from './SurveyBlockHeader';

const SurveyHappinessPoll = ({
  survey,
  onChange = null,
  selectedOption,
  isPublished,
  isPublishing,
}) => {
  const {content, options} = survey;
  return (
    <>
      <SurveyBlockHeader
        question={content.question}
        description={content.description}
        isPublished={isPublished}
        isPublishing={isPublishing}
      />
      <Flex flexDirection="row" justifyContent="space-around" pb={6}>
        {options.map(option => {
          const isSelected =
            selectedOption && selectedOption.value === option.value;
          return (
            <Button
              key={option.value}
              variant="unstyled"
              onClick={() => onChange(option)}
              disabled={onChange === null}>
              <Box
                borderWidth="3px"
                borderColor={isSelected ? 'primary' : 'transparent'}
                borderRadius={50}>
                <Mood value={option.value} isSelected={isSelected} />
              </Box>
            </Button>
          );
        })}
      </Flex>
    </>
  );
};

export default SurveyHappinessPoll;
