import React from 'react';
import {Trans} from '@lingui/macro';
import Paragraph from './utils/Paragraph';
import WelcomePageContainer from './pages/welcome/WelcomePageContainer';

const AppNotFound = () => (
  <WelcomePageContainer
    title={<Trans>Not found</Trans>}
    info={
      <Paragraph variant={2}>
        <Trans id="It looks like we couldn't find the page you were looking for." />
      </Paragraph>
    }
  />
);

export default AppNotFound;
