import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import HeaderButton from './HeaderButton';
import Flex from './Flex';
import usePermissions from '../hooks/usePermissions';

const HeaderButtons = ({avatar, ...props}) => {
  const {isExact, path} = useRouteMatch();
  const {hasAdminInbox} = usePermissions();
  return (
    <Flex flexDirection="row" alignItems="center" {...props}>
      {hasAdminInbox && (
        <HeaderButton
          icon="addMessageIcon"
          isActive={isExact && path === '/admin-message/create'}
          to="/admin-message/create"
        />
      )}
      <HeaderButton
        icon="comments"
        mx={3}
        to="/comments"
        isActive={isExact && path === '/comments'}
      />
      <HeaderButton
        key={avatar && avatar.id}
        icon="settings"
        avatar={avatar}
        to="/settings"
        isActive={isExact && path === '/settings'}
      />
    </Flex>
  );
};

export default HeaderButtons;
