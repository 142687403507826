import * as React from 'react';

const AdminInboxIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M13.18 10.198c.283-.51 2.484-2.913 2.07-3.745-.286-.575-1.267-.768-1.907-.768h-.147v-2.13A2.551 2.551 0 0 0 10.654 1h-.826a2.551 2.551 0 0 0-2.542 2.555v2.13h-.147c-.64 0-1.47.233-1.825.768-.828 1.249 1.787 3.236 2.07 3.745l-6.175 1.768A2.558 2.558 0 0 0 0 14.14v3.305A2.551 2.551 0 0 0 2.542 20h15.273a2.551 2.551 0 0 0 2.542-2.555V14.14c0-.86-.426-1.622-1.076-2.085l-6.102-1.857ZM6.818 7.634a.382.382 0 0 1-.022-.401.379.379 0 0 1 .342-.207H8.62V3.554c0-.669.542-1.213 1.207-1.213h.827c.666 0 1.207.545 1.207 1.214V7.026h1.482c.21 0 .31.145.342.207a.384.384 0 0 1-.022.401l-.485.622-1.045 1.341-1.572 2.018a.382.382 0 0 1-.32.17.383.383 0 0 1-.32-.17L8.347 9.596l-1.045-1.34-.485-.623Zm12.206 9.811c0 .67-.542 1.214-1.208 1.214H2.542a1.212 1.212 0 0 1-1.208-1.214V14.14c0-.67.542-1.214 1.208-1.214h3.45v.775c0 1.409.75 2.161 2.151 2.161h3.289c1.402 0 2.933-.753 2.933-2.161v-.775h3.45c.666 0 1.208.545 1.208 1.214v3.305Z" />
      <path d="M12.942 17.738H7.416c-.098 0-.178-.202-.178-.452s.08-.452.178-.452h5.526c.098 0 .177.202.177.452s-.08.452-.177.452Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20.581v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default AdminInboxIcon;
