import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {configureChannels} from '../../../utils/channels';
import useDialog from '../../hooks/useDialog';
import useAppState from '../../hooks/useAppState';
import PageLoading from '../../utils/PageLoading';
import ErrorPageWithState from '../error/ErrorPageWithState';
import ProfilePage from './ProfilePage';
import useUpdateUserMutation from './useUpdateUserMutation';
import useFetchSettingsPageData from './useFetchProfilePageData';
import useUpdateConfiguredChannels from './useUpdateConfiguredChannels';
import getTermsAndConditionsLink from '../../../apollo/getApiLinks';
import useNotifications from '../../hooks/useNotifications';
import useCompany from '../../hooks/useCompany';

const ProfilePageWithState = () => {
  const dialog = useDialog();
  const history = useHistory();
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const {dispatchError} = useNotifications();
  const {error, channels, userChannels, isFetching, updateQuery} =
    useFetchSettingsPageData();
  const {logout, currentUser, updateCurrentUser, updateActiveLanguage} =
    useAppState();
  const [updateUser] = useUpdateUserMutation();
  const [updateConfiguredChannels] = useUpdateConfiguredChannels();
  const company = useCompany();

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  if (isFetching) {
    return <PageLoading />;
  }

  const handleUpdateUser = async fields => {
    const variables = {
      body: {
        ...fields,
        first_name: currentUser.firstName,
        last_name: currentUser.lastName,
      },
    };
    const {
      data: {updateUser: updatedUser},
    } = await updateUser({variables});
    updateCurrentUser(updatedUser);
  };

  const handleUpdateAvatar = async avatarFile => {
    setIsUploadingAvatar(true);
    await handleUpdateUser({avatar_file: avatarFile});
    setIsUploadingAvatar(false);
  };

  const handleUpdateDescription = async description => {
    try {
      setIsUpdatingDescription(true);
      await handleUpdateUser({description});
    } catch (responseError) {
      dispatchError({
        message: responseError.message,
      });
    }
    setIsUpdatingDescription(false);
  };

  const handleUpdateLanguage = async language => {
    setIsUpdatingLanguage(true);
    await handleUpdateUser({language});
    updateActiveLanguage(language);
  };

  const openPrivacyPolicy = async () => {
    const termsUrl = await getTermsAndConditionsLink();
    window.open(termsUrl);
  };

  const handleUpdateUserChannels = async (userChannel, channelIsConfigured) => {
    const {newChannels, channelIsChanged} = await configureChannels(
      userChannels,
      userChannel,
      channelIsConfigured,
      dialog,
    );
    const variables = {
      body: {
        channels: newChannels,
      },
    };
    const {
      data: {
        updateConfiguredChannels: {data: updatedUserChannels},
      },
    } = await updateConfiguredChannels({variables});
    updateQuery(prev => ({
      ...prev,
      userChannels: {
        ...prev.userChannels,
        data: updatedUserChannels,
      },
    }));

    return channelIsChanged;
  };

  const handleLogout = async () => {
    history.push('/');
    await logout();
  };

  return (
    <ProfilePage
      channels={channels}
      onLogout={handleLogout}
      currentUser={currentUser}
      userChannels={userChannels}
      onUpdateAvatar={handleUpdateAvatar}
      isUploadingAvatar={isUploadingAvatar}
      onChangeLanguage={handleUpdateLanguage}
      isUpdatingLanguage={isUpdatingLanguage}
      isUpdatingDescription={isUpdatingDescription}
      onUpdateDescription={handleUpdateDescription}
      onUpdateUserChannels={handleUpdateUserChannels}
      onOpenPrivacyPolicy={openPrivacyPolicy}
      extraProfileButtons={company?.settings?.extraProfileButtons || []}
    />
  );
};

export default ProfilePageWithState;
