import React, {createContext, useContext, useMemo} from 'react';
import * as storage from '../../utils/storage';
import getApiRoot from '../../utils/getApiRoot';
import {MODEL_NOT_FOUND} from '../../utils/constants';

const RogerFetchContext = createContext();

export const RogerFetchProvider = ({children}) => {
  const authFetch = async (urlOrPath, options = {}) => {
    const url = urlOrPath.startsWith('http')
      ? urlOrPath
      : `${getApiRoot()}${urlOrPath}`;

    let newOptions = {
      ...options,
      headers: {
        ...options.headers,
        authorization: `Bearer ${await storage.getAccessToken()}`,
      },
    };
    const {body} = options;
    if (typeof body !== 'undefined') {
      const {mediaFile, mediaType} = body;
      if (typeof mediaFile !== 'undefined') {
        const formData = new FormData();
        formData.append('media_file', mediaFile);
        formData.append('media_type', mediaType);
        newOptions = {
          ...options,
          body: formData,
        };
      }
    }
    const response = await fetch(url, newOptions);

    if (response.status === 404) {
      return new Response(response.body, {
        status: 422,
        statusText: MODEL_NOT_FOUND,
        headers: response.headers,
      });
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };
  const refreshAccessToken = async body => {
    const response = await authFetch('/clients/web/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({input: body}),
    });

    return response;
  };
  const RogerFetchContextValue = useMemo(
    () => ({authFetch, refreshAccessToken}),
    [authFetch, refreshAccessToken],
  );
  return (
    <RogerFetchContext.Provider value={RogerFetchContextValue}>
      {children}
    </RogerFetchContext.Provider>
  );
};

export const useRogerFetch = () => useContext(RogerFetchContext);
