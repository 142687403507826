import React, {useState} from 'react';
import Tag from './Tag';
import Icon from './Icon';
import Button from './Button';

const CheerButton = ({
  onCheer,
  isCheered,
  isDisabled = false,
  cheerCount,
  ...props
}) => {
  const [buttonState, setButtonState] = useState({isCheered, cheerCount});

  const handleCheer = () => {
    onCheer();
    setButtonState(prevState => ({
      isCheered: !prevState.isCheered,
      cheerCount: prevState.isCheered
        ? prevState.cheerCount - 1
        : prevState.cheerCount + 1,
    }));
  };

  return (
    <Button
      variant="unstyled"
      flexDirection="row"
      alignItems="center"
      borderRadius={0}
      py={1}
      px={2}
      bg={buttonState.isCheered && !isDisabled ? 'primary' : 'transparent'}
      onClick={e => {
        e.stopPropagation();
        handleCheer();
      }}
      {...props}>
      {buttonState.cheerCount > 0 && (
        <Tag
          mr={0}
          variant={1}
          color={buttonState.isCheered ? 'white' : 'grey.5'}>
          {buttonState.cheerCount}
        </Tag>
      )}
      <Icon name="cheer" color={buttonState.isCheered ? 'white' : 'greyDark'} />
    </Button>
  );
};

export default CheerButton;
