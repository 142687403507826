import React from 'react';
import tinycolor from 'tinycolor2';
import Header from '../../utils/Header';
import Container from '../../utils/Container';
import HomePageWelcome from './HomePageWelcome';
import HomePageCalendar from './HomePageCalendar';
import HomePageMessages from './HomePageMessages';
import HomePageDocuments from './HomePageDocuments';
import HomePageColleagues from './HomePageColleagues';
import {PageContentScrollable} from '../../utils/Page';
import Box from '../../../modules/utils/Box';
import Flex from '../../../modules/utils/Flex';

const HomePage = ({
  theme,
  newFiles,
  onOpenFile,
  unreadCount,
  currentUser,
  userMessages,
  newColleagues,
  companyColors,
  companyBanner,
  hasSmallBanner,
  canViewCalendar,
  canViewDocuments,
  canViewColleagues,
  homeTitleIsInBanner,
  onNavigateToReadMessages,
  onNavigateToUnreadMessages,
}) => {
  const hasSecondColunm =
    canViewColleagues || canViewDocuments || canViewCalendar;

  return (
    <>
      <Header />
      <PageContentScrollable>
        <Box
          position="relative"
          backgroundImage={
            companyBanner
              ? `url('${companyBanner}')`
              : `linear-gradient(-45deg, ${companyColors.brand.base} 0%, ${tinycolor(companyColors.brand.base).darken(5)} 100%)`
          }
          backgroundSize="cover"
          backgroundPosition="center"
          width="100%"
          height={hasSmallBanner ? '37.5vw' : '50vw'}
          minHeight="20rem"
          maxHeight={hasSmallBanner ? '37.5rem' : '50rem'}>
          {homeTitleIsInBanner && (
            <Container>
              <HomePageWelcome
                color="white"
                textShadow="1px 1px black"
                top="25%"
                position="absolute"
                unreadCount={unreadCount}
                currentUser={currentUser}
              />
            </Container>
          )}
        </Box>
        <Container px={5}>
          {!homeTitleIsInBanner && (
            <HomePageWelcome
              mt={6}
              mb={[6, -5]}
              unreadCount={unreadCount}
              currentUser={currentUser}
            />
          )}
          <Flex
            flexDirection="row"
            mt={homeTitleIsInBanner ? 7 : 0}
            justifyContent="center">
            <HomePageMessages
              userMessages={userMessages}
              unreadCount={unreadCount}
              theme={theme}
              onNavigateToUnreadMessages={onNavigateToUnreadMessages}
              onNavigateToReadMessages={onNavigateToReadMessages}
              width={[
                '100%',
                hasSecondColunm ? `calc(50% - ${theme.space[7]})` : '50%',
              ]}
              mt={[0, 50]}
              mr={[0, 7]}
            />
            {hasSecondColunm && (
              <Box
                display={['none', 'block']}
                width={`calc(50% - ${theme.space[7]})`}
                ml={7}>
                {canViewColleagues && newColleagues.length > 0 && (
                  <HomePageColleagues colleagues={newColleagues} mb={7} />
                )}
                {canViewDocuments && newFiles.length > 0 && (
                  <HomePageDocuments
                    documents={newFiles}
                    onOpenFile={onOpenFile}
                    mb={7}
                  />
                )}
                {canViewCalendar && <HomePageCalendar mb={7} />}
              </Box>
            )}
          </Flex>
          {canViewCalendar && (
            <HomePageCalendar mb={7} display={['block', 'none']} />
          )}
        </Container>
      </PageContentScrollable>
    </>
  );
};

export default HomePage;
