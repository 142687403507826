import {useQuery, gql} from '@apollo/client';

export const CHANNELS_QUERY = gql`
  query channelsQuery {
    channels
      @rest(
        type: "Channels"
        path: "/notifications/configured-channels"
        method: "GET"
      ) {
      data
    }
    userChannels
      @rest(
        type: "UserChannels"
        path: "/user-notifications/configured-channels"
        method: "GET"
      ) {
      data
    }
  }
`;

const useFetchChannelsData = () => {
  const {
    data,
    error,
    loading: isFetching,
    updateQuery,
  } = useQuery(CHANNELS_QUERY);

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      channels: [],
      userChannels: [],
    };
  }

  const {
    channels: {data: channels},
    userChannels: {data: userChannels},
  } = data;

  return {
    isFetching,
    channels,
    userChannels,
    updateQuery,
  };
};

export default useFetchChannelsData;
