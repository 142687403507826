import {useMutation, gql} from '@apollo/client';

export const LOGIN_AZURE_AD = gql`
  mutation loginAzureADMutation($accessToken: String!) {
    loginAzureAD(input: {access_token: $accessToken})
      @rest(
        type: "LoginAzureADResponse"
        path: "/clients/web/azure-token"
        method: "POST"
      ) {
      tokenType
      expiresIn
      accessToken
      refreshToken
    }
  }
`;

const useLoginAzureADMutation = () =>
  useMutation(LOGIN_AZURE_AD, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

export default useLoginAzureADMutation;
