import React from 'react';
import {Trans, t} from '@lingui/macro';
import useLingui from '../../hooks/useLingui';
import WelcomePageContainer from '../welcome/WelcomePageContainer';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import FormItem from '../../utils/FormItem';
import Paragraph from '../../utils/Paragraph';
import InputPhone from '../../utils/InputPhone';

const LoginSmsPage = ({isLoading, formState, onCancel, onSubmit}) => {
  const lingui = useLingui();
  const {getInputProps, getErrorMessages} = formState;
  return (
    <WelcomePageContainer
      title={<Trans>Sms</Trans>}
      info={
        <Paragraph variant={2}>
          <Trans id="We'll send your login code via sms. Make sure to select the correct country code." />
        </Paragraph>
      }>
      <FormItem error={getErrorMessages('phone')} required>
        <InputPhone
          placeholder={lingui._(t`E.g: +32 472 78 90 01`)}
          {...getInputProps('phone')}
        />
      </FormItem>
      <Flex>
        <Button
          flex={1}
          onClick={onCancel}
          variant="outline"
          mr={5}
          disabled={isLoading}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          flex={1}
          onClick={onSubmit}
          disabled={isLoading}
          isLoading={isLoading}>
          <Trans>Send</Trans>
        </Button>
      </Flex>
    </WelcomePageContainer>
  );
};

export default LoginSmsPage;
