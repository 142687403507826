/* eslint-disable react/no-unused-state */
import React from 'react';
import * as Sentry from '@sentry/react';
import {fromPromise} from '@apollo/client';
import getMe from '../apollo/queries/getMe';
import getCompany from '../apollo/queries/getCompany';
import getAppHealth from '../apollo/queries/getAppHealth';
import getAppDomains from '../apollo/queries/getAppDomains';
import getPublicCompany from '../apollo/queries/getPublicCompany';
import getUnreadMessages from '../apollo/queries/getUnreadMessages';
import refreshAccessToken from '../apollo/mutations/refreshAccessToken';
import getUnreadMessagesCount from '../apollo/queries/getUnreadMessagesCount';
import {
  createAuthorizedApolloClient,
  createUnAuthorizedApolloClient,
} from '../apollo/createApolloClient';
import {scopeUser, removeScopedUser} from '../services/Sentry';
import * as storage from '../utils/storage';
import {isUnauthenticatedError} from '../utils/networking';
import WebSocketConnection from '../utils/WebSocketConnection';
import {getNotificationDispatcher} from '../utils/notifications';
import AppStateProvider from './providers/AppStateProvider';
import App from './App';
import AppProviders from './AppProviders';
import {EN, getActiveLanguage} from '../locales';
import {hasCustomFavicons} from '../utils/whiteLabel';

class AppWithState extends React.Component {
  constructor(props) {
    super(props);

    this.notificationDispatcher = getNotificationDispatcher();
    this.webSocketConnection = WebSocketConnection.getInstance();

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.refresh = this.refresh.bind(this);
    this.setNewTokens = this.setNewTokens.bind(this);
    this.migrateAuthKey = this.migrateAuthKey.bind(this);
    this.updateBadgeCount = this.updateBadgeCount.bind(this);
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.onServerUnavailable = this.onServerUnavailable.bind(this);
    this.updateCurrentCompany = this.updateCurrentCompany.bind(this);
    this.updateActiveLanguage = this.updateActiveLanguage.bind(this);
    this.onUnauthenticatedRequest = this.onUnauthenticatedRequest.bind(this);

    this.apolloClient = createUnAuthorizedApolloClient(
      this.onServerUnavailable,
    );

    this.state = {
      unreadCount: 0,
      isLoading: true,
      login: this.login,
      currentUser: null,
      activeLanguage: EN,
      logout: this.logout,
      pendingRequests: [],
      currentCompany: null,
      isAppAvailable: true,
      refresh: this.refresh,
      appNeedsUpdate: false,
      isAuthenticated: false,
      isRefreshingTokens: false,

      updateBadgeCount: this.updateBadgeCount,
      updateCurrentUser: this.updateCurrentUser,
      updateCurrentCompany: this.updateCurrentCompany,
      updateActiveLanguage: this.updateActiveLanguage,
    };
  }

  async componentDidMount() {
    try {
      await this.checkAppHealth();
      await this.migrateAuthKey();
      await this.checkAuthorizationState();
      await this.initApolloClient();
      await this.fetchInitialUnauthenticatedData();
      await this.fetchInitialData();
      this.setActiveLanguage();
      await this.updateBadgeCount();
      await this.initServices();
      // await this.subscribeToUrlLinking()
      // await this.checkAppPermissions()
      // last step needs to be made async somehow promise does not resolve
      this.initWebsocketConnection();
    } catch (error) {
      if (error.networkError && isUnauthenticatedError(error.networkError)) {
        await this.logout();
      } else {
        throw new Error(error.message);
      }
    } finally {
      this.setState({isLoading: false});
    }
  }

  componentDidUpdate(prevProps) {
    const {currentCompany} = this.state;
    const {currentCompany: prevCompany} = prevProps;
    if (prevCompany !== currentCompany && currentCompany) {
      this.configureWhiteLabelOptions(currentCompany);
    }
  }

  onUnauthenticatedRequest(forward, operation) {
    const {isRefreshingTokens} = this.state;
    if (!isRefreshingTokens) {
      this.setState({isRefreshingTokens: true});
      return fromPromise(
        this.setNewTokens()
          .then(() => {
            const {pendingRequests} = this.state;
            pendingRequests.map(callback => callback());
            this.setState({pendingRequests: []});
          })
          .catch(({networkError}) => {
            if (isUnauthenticatedError(networkError)) {
              this.logout();
            }
          })
          .finally(() => {
            this.setState({isRefreshingTokens: false});
          }),
      ).flatMap(() => forward(operation));
    }
    return fromPromise(
      new Promise(resolve => {
        const {pendingRequests} = this.state;
        this.setState({
          pendingRequests: [...pendingRequests, () => resolve()],
        });
      }),
    ).flatMap(() => forward(operation));
  }

  onServerUnavailable() {
    this.setState({isAppAvailable: false});
  }

  /* eslint-disable class-methods-use-this */
  async setNewTokens() {
    const refreshClient = createUnAuthorizedApolloClient(
      this.onServerUnavailable,
    );
    const {accessToken, refreshToken} = await refreshAccessToken(refreshClient);
    await storage.setAccessToken(accessToken);
    await storage.setRefreshToken(refreshToken);
  }

  setActiveLanguage(language = null) {
    const {currentUser, currentCompany} = this.state;
    let activeLanguage = language;
    if (language === null) {
      activeLanguage = getActiveLanguage(currentUser, currentCompany);
    }

    this.setState({activeLanguage});
  }

  async updateActiveLanguage(language) {
    this.setActiveLanguage(language);
    this.setState({isLoading: true});
    await this.apolloClient.resetStore();
    this.setState({isLoading: false});
  }

  // eslint-disable-next-line class-methods-use-this
  async migrateAuthKey() {
    try {
      const stateFromLocalStorage = await window.localStorage.getItem('state');
      const parsedState = JSON.parse(stateFromLocalStorage);
      if (parsedState !== null) {
        const {refreshToken, accessToken, expiresIn} = parsedState.auth;
        await storage.setAccessToken(accessToken);
        await storage.setRefreshToken(refreshToken);
        await storage.setExpiresIn(expiresIn);
        await window.localStorage.removeItem('state');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(
        'Meetroger info: Localstorage is disabled, if you need persistent login, enable it in your browser settings',
      );
    }
  }

  async updateBadgeCount() {
    const {isAuthenticated} = this.state;
    if (isAuthenticated) {
      const {apolloClient} = this;
      const count = await getUnreadMessagesCount(apolloClient, {
        fetchPolicy: 'network-only',
      });
      this.setState({unreadCount: count});
    }
  }

  async initApolloClient() {
    const {isAuthenticated} = this.state;
    if (isAuthenticated) {
      this.apolloClient = await createAuthorizedApolloClient(
        this.onServerUnavailable,
        this.onUnauthenticatedRequest,
      );
    }
  }

  async initServices() {
    const {isAuthenticated, currentUser} = this.state;
    if (isAuthenticated) {
      scopeUser(currentUser);
    }
  }

  async initWebsocketConnection() {
    const {isAuthenticated} = this.state;
    if (isAuthenticated && !this.webSocketConnection.isConnected) {
      await this.webSocketConnection.connect();
    }
  }

  async checkAuthorizationState() {
    const accessToken = await storage.getAccessToken();
    this.setState({
      isAuthenticated: accessToken !== null,
    });
  }

  async fetchInitialUnauthenticatedData() {
    const {apolloClient} = this;
    try {
      const currentCompany = await getPublicCompany(apolloClient);
      this.setState({currentCompany});
    } catch (error) {
      if (
        typeof error.networkError !== 'undefined' &&
        error.networkError.statusCode === 422
      ) {
        this.setState({
          currentCompany: null,
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async fetchInitialData() {
    const {apolloClient} = this;
    const {isAuthenticated, currentCompany} = this.state;
    if (isAuthenticated && currentCompany !== null) {
      try {
        const [currentUser, authorizedCompany] = await Promise.all([
          getMe(apolloClient, {fetchPolicy: 'network-only'}),
          getCompany(apolloClient, {fetchPolicy: 'network-only'}),
          getAppDomains(apolloClient, {fetchPolicy: 'network-only'}),
          getUnreadMessages(apolloClient, {fetchPolicy: 'network-only'}),
        ]);
        if (currentUser === null || typeof currentUser === 'undefined') {
          throw new Error('No user found.');
        }
        this.setState({
          currentUser,
          currentCompany: authorizedCompany,
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }

  async checkAppHealth() {
    const {apolloClient} = this;
    const {available} = await getAppHealth(apolloClient);
    this.setState({
      isAppAvailable: available,
      appNeedsUpdate: false,
    });
  }

  async login({accessToken, refreshToken}) {
    const isAuthenticated = accessToken !== null;
    this.setState({isLoading: true, isAuthenticated});
    await storage.setAccessToken(accessToken);
    await storage.setRefreshToken(refreshToken);
    this.apolloClient = await createAuthorizedApolloClient(
      this.onServerUnavailable,
      this.onUnauthenticatedRequest,
    );
    await this.fetchInitialData();
    this.setActiveLanguage();
    await this.initServices();
    await this.updateBadgeCount();
    this.initWebsocketConnection();
    this.setState({isLoading: false});
  }

  async logout() {
    await storage.removeAccessToken();
    await storage.removeRefreshToken();
    this.apolloClient = createUnAuthorizedApolloClient(
      this.onServerUnavailable,
    );
    removeScopedUser();

    this.setState({
      currentUser: null,
      currentCompany: null,
      isAuthenticated: false,
    });

    this.refresh();
  }

  async refresh() {
    this.setState({isAppAvailable: true, isLoading: true});
    const {isAuthenticated} = this.state;
    if (isAuthenticated) {
      await this.fetchInitialData();
    } else {
      await this.fetchInitialUnauthenticatedData();
    }
    this.setState({isLoading: false});
  }

  updateCurrentCompany(currentCompany) {
    this.setState({currentCompany});
  }

  updateCurrentUser(currentUser) {
    this.setState({currentUser});
  }

  configureWhiteLabelOptions(company) {
    const favicon = document.getElementById('head-favicon');
    const manifest = document.getElementById('head-manifest');
    const appleTouchIcon = document.getElementById('head-apple-touch-icon');
    const icon32x32 = document.getElementById('head-icon-32x32');
    const icon16x16 = document.getElementById('head-icon-16x16');

    if (hasCustomFavicons(company)) {
      document.title = company.name;
      favicon.href = `/custom/${company.slug}/favicon.ico`;
      manifest.href = `/custom/${company.slug}/site.webmanifest`;
      appleTouchIcon.href = `/custom/${company.slug}/apple-touch-icon.png`;
      icon32x32.href = `/custom/${company.slug}/favicon-32x32.png`;
      icon16x16.href = `/custom/${company.slug}/favicon-16x16.png`;
    } else {
      document.title = 'Meet Roger';
      favicon.href = '/favicon.ico';
      manifest.href = '/site.webmanifest';
      appleTouchIcon.href = '/apple-touch-icon.png';
      icon32x32.href = '/favicon-32x32.png';
      icon16x16.href = '/favicon-16x16.png';
    }
  }

  render() {
    const {activeLanguage} = this.state;
    const {apolloClient, notificationDispatcher} = this;
    return (
      <AppStateProvider value={this.state}>
        <AppProviders
          activeLanguage={activeLanguage}
          apolloClient={apolloClient}
          webSocketConnection={this.webSocketConnection}
          notificationDispatcher={notificationDispatcher}>
          <App />
        </AppProviders>
      </AppStateProvider>
    );
  }
}

export default AppWithState;
