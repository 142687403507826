import React from 'react';
import {
  LIST_TYPE_ALL,
  LIST_TYPE_READ,
  LIST_TYPE_SAVED,
} from '../../../utils/constants';
import Header from '../../utils/Header';
import UnreadMessagesPageWithState from './UnreadMessagesPage';
import ReadMessagesPageWithState from './ReadMessagesPageWithState';
import BookmarksPageWithState from './BookmarksPageWithState';
import AllMessagesPage from './AllMessagesPage';
import Page, {PageContentScrollable} from '../../utils/Page';
import MessagesPageHeader from './MessagesPageHeader';
import MessagesScreenTypeTabs from './MessagesScreenTypeTabs';
import Box from '../../utils/Box';
import {useMessagesFilter} from './hooks/MessagesFilters';

const MessagesPage = () => {
  const {statusFilter} = useMessagesFilter();
  return (
    <>
      <Header />

      <Page>
        <Box>
          <MessagesPageHeader />
          <MessagesScreenTypeTabs mb={6} />
        </Box>
        {(() => {
          if (statusFilter === LIST_TYPE_READ) {
            return (
              <PageContentScrollable>
                <ReadMessagesPageWithState />
              </PageContentScrollable>
            );
          }
          if (statusFilter === LIST_TYPE_SAVED) {
            return (
              <PageContentScrollable>
                <BookmarksPageWithState />
              </PageContentScrollable>
            );
          }
          if (statusFilter === LIST_TYPE_ALL) {
            return <AllMessagesPage />;
          }
          return (
            <PageContentScrollable>
              <UnreadMessagesPageWithState />
            </PageContentScrollable>
          );
        })()}
      </Page>
    </>
  );
};

export default MessagesPage;
