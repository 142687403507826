import React from 'react';
import useFetchEmbedBlockData from '../../apollo/queries/useFetchEmbedOpenGraph';
import EmbedBlock from './EmbedBlock';
import EditorBlockLoading from './EditorBlockLoading';
import EditorBlockContainer from './EditorBlockContainer';

const EmbedBlockWithState = ({url}) => {
  const {openGraph, isFetching} = useFetchEmbedBlockData({url});

  const handleOpenMedia = () => window.open(url);

  if (isFetching) {
    return <EditorBlockLoading />;
  }

  return (
    <EditorBlockContainer>
      <EmbedBlock url={url} data={openGraph} onOpenMedia={handleOpenMedia} />
    </EditorBlockContainer>
  );
};

export default EmbedBlockWithState;
