import React from 'react';
import {Trans} from '@lingui/macro';
import Icon from '../../utils/Icon';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';
import Box from '../../utils/Box';

const MobileAppInstallUnknown = ({onRedeemCode, onNavigateToPlayStore}) => (
  <>
    <Paragraph mb={5} variant={2}>
      <Trans id="We need to verify your identity on this device. Get the mobile app on your device's store." />
    </Paragraph>
    <Box mt={5}>
      <Button onClick={onRedeemCode} width="100%" mb={4}>
        <Icon mr={4} name="apple" color="white" />
        <Trans>Get the app from the App Store</Trans>
      </Button>
      <Button onClick={onNavigateToPlayStore} width="100%">
        <Icon mr={4} name="android" color="white" />
        <Trans>Get the app from the Google Play Store</Trans>
      </Button>
    </Box>
  </>
);

export default MobileAppInstallUnknown;
