import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {
  DEFAULT_COMMENT_SORT,
  DEFAULT_LIST_PAGE,
  DEFAULT_COMMENT_PAGE_LIMIT,
} from '../../utils/constants';

const DEFAULT_VARIABLES = {
  sort: DEFAULT_COMMENT_SORT,
  page: DEFAULT_LIST_PAGE,
  limit: DEFAULT_COMMENT_PAGE_LIMIT,
};

const USER_COMMENTS_QUERY = gql`
  query userCommentsScreenQuery($page: Int, $limit: Int, $sort: String) {
    comments(page: $page, limit: $limit, sort: $sort)
      @rest(
        type: "Comments"
        path: "/users/me/comments?{args}"
        method: "GET"
      ) {
      data {
        id
        text
        isCheered
        cheerCount
        updatedAt
        createdAt
        userMessage {
          id
          title
          isCheered
          cheersCount
          commentsCount
          createdAt
        }
      }
      meta {
        currentPage
        from
        lastPage
        path
        perPage
        to
        total
      }
    }
  }
`;

export const useUserCommentsQuery = options => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const variables = DEFAULT_VARIABLES;
  const {
    error,
    loading: isFetching,
    data,
    refetch,
    fetchMore,
  } = useQuery(USER_COMMENTS_QUERY, {variables, ...options});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      userComments: [],
    };
  }

  const {
    comments: {
      data: comments,
      meta: {lastPage, currentPage},
    },
  } = data;

  const hasMorePages = currentPage < lastPage;
  const handleFetchMore = async () => {
    if (!isFetchingMore && hasMorePages) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            comments: {
              ...fetchMoreResult.comments,
              data: [...prev.comments.data, ...fetchMoreResult.comments.data],
            },
          };
        },
      });
      setIsFetchingMore(false);
    }
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  return {
    isFetching,
    isRefetching,
    isFetchingMore,
    hasMorePages,
    userComments: comments,
    handleRefetch,
    handleFetchMore,
  };
};

export default async function getUserComments(apollo, options) {
  const query = USER_COMMENTS_QUERY;
  const variables = DEFAULT_VARIABLES;
  const response = await apollo.query({query, variables, ...options});
  return response.data.comments.data;
}
