import React, {useState, useContext, createContext, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../utils/Icon';
import Button from '../utils/Button';
import usePermissions from './usePermissions';

const FloatingButton = styled(Button)`
  position: fixed;
  transition: 0.2s ease all;
  font-size: 2rem;
  &:hover {
    transform: scale(1.1);
  }
`;
export function FloatingActionButton({icon, position, goto}) {
  const history = useHistory();
  return (
    <FloatingButton
      style={{
        bottom: '20px',
        right: '20px',
      }}
      {...{position}}
      onClick={() => {
        history.push(goto);
      }}>
      <Icon name={icon} color="white" />
    </FloatingButton>
  );
}

const FloatingButtonContext = createContext();

export function FloatingActionButtonProvider({children}) {
  const [position, setPosition] = useState({bottom: '2rem', right: '2rem'});
  const [enabled, setEnabled] = useState(true);
  const [goto, setGoto] = useState('/admin-message/create');
  const [icon, setIcon] = useState('addMessageIcon');
  const location = useLocation();
  const {hasAdminInbox} = usePermissions();
  const ProviderValue = useMemo(
    () => ({setPosition, setEnabled, setGoto, setIcon}),
    [setPosition, setEnabled, setGoto, setIcon],
  );
  return (
    <FloatingButtonContext.Provider value={ProviderValue}>
      {children}
      {hasAdminInbox && enabled && location.pathname !== goto && (
        <FloatingActionButton position={position} goto={goto} icon={icon} />
      )}
    </FloatingButtonContext.Provider>
  );
}

// Hook to use HoverContext
export function useFloatingActionButtonContext() {
  const context = useContext(FloatingButtonContext);
  if (context === undefined) {
    throw new Error(
      'useFloatingActionButtonContext must be used within a FloatingActionButtonProvider',
    );
  }
  return context;
}
