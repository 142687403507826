import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import styled from 'styled-components';
import {
  color,
  space,
  layout,
  flexbox,
  variant,
  typography,
  border,
  position,
} from 'styled-system';

// React router dom doesn't seem to automatically link the className
// given by styled components. We have to do it manually to avoid warnings.
const RouterLinkWithClassName = ({className, to, children}) => (
  <RouterLink className={className} to={to}>
    {children}
  </RouterLink>
);

const StyledRouterLink = styled(RouterLinkWithClassName)`
  ${color}
  ${space}
    ${layout}
    ${flexbox}
    ${typography}
    ${border}
    ${position}
`;

const StyledLink = styled.a`
  ${color}
  ${space}
    ${layout}
    ${flexbox}
    ${typography}
    ${border}
    ${position}
`;

const LinkWithVariants = styled(StyledLink)(
  variant({
    variants: {
      1: {
        fontSize: 2,
        lineHeight: 2,
      },
      2: {
        fontSize: 1,
        lineHeight: 1,
      },
    },
  }),
);

const RouterLinkWithVariants = styled(StyledRouterLink)(
  variant({
    variants: {
      1: {
        fontSize: 2,
        lineHeight: 2,
      },
      2: {
        fontSize: 1,
        lineHeight: 1,
      },
    },
  }),
);

const Link = ({children, isExternal = false, ...props}) => {
  if (isExternal) {
    return (
      <LinkWithVariants
        variant={1}
        fontFamily="bold"
        color="primary"
        href={props.to}
        target="_blank"
        {...props}>
        {children}
      </LinkWithVariants>
    );
  }
  return (
    <RouterLinkWithVariants
      variant={1}
      fontFamily="bold"
      color="primary"
      {...props}>
      {children}
    </RouterLinkWithVariants>
  );
};

export default Link;
