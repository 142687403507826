import React from 'react';
import {Trans} from '@lingui/macro';
import {
  CHANNEL_WHATS_APP as WHATS_APP,
  CHANNEL_PUSH_NOTIFICATION as PUSH_NOTIFICATION,
} from '../../utils/constants';
import Box from '../utils/Box';
import Flex from '../utils/Flex';
import Title from '../utils/Title';
import Switch from '../utils/Switch';
import EditorBlockContainer from './EditorBlockContainer';
import {getChannelInfo} from '../../utils/channels';
import Icon from '../../components/utils/Icon';

const ChooseChannelBlock = ({
  channels,
  userChannels,
  onUpdateUserChannels,
  currentUser,
  permissions,
}) => (
  <EditorBlockContainer p={6}>
    <Title mb={6} variant={3}>
      <Trans>How would you like to receive news?</Trans>
    </Title>
    <Flex flexDirection="row" flexWrap="wrap">
      {channels.map((channel, index) => {
        const channelInfo = getChannelInfo(channel, currentUser, permissions);
        if (channelInfo === null) {
          return null;
        }
        const channelIsConfigured = userChannels.includes(channel);
        const canToggleWhatsApp =
          !userChannels.includes(PUSH_NOTIFICATION) || channelIsConfigured;

        return (
          <Box
            key={channel}
            width={['100%', '50%']}
            mb={6}
            pr={['none', index % 2 === 0 ? 7 : 'none']}
            pl={['none', index % 2 !== 0 ? 7 : 'none']}>
            <Flex flexDirection="row" justifyContent="space-between">
              <Flex flexDirection="row" alignItems="center" mb={1}>
                <Icon
                  name={channelInfo.icon}
                  color={channel === WHATS_APP ? 'whatsapp' : 'default'}
                />
                <Title variant={4} ml={3}>
                  {channelInfo.title}
                </Title>
              </Flex>
              <Switch
                onChange={updatedValue =>
                  onUpdateUserChannels(channel, updatedValue)
                }
                disabled={
                  onUpdateUserChannels === null ||
                  (channel === WHATS_APP && !canToggleWhatsApp)
                }
                checked={channelIsConfigured}
              />
            </Flex>
          </Box>
        );
      })}
    </Flex>
  </EditorBlockContainer>
);
export default ChooseChannelBlock;
