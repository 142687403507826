import sortby from 'lodash.sortby';
import {useQuery, gql} from '@apollo/client';

export const SEGMENT_GROUPS_QUERY = gql`
  query segmentGroupsQuery {
    segmentGroups
      @rest(
        type: "SegmentGroups"
        path: "/segment-groups/filters"
        method: "GET"
      ) {
      data {
        id
        segmentGroupType
        name
        slug
        type
        createdAt
        updatedAt
        deletedAt
        segments {
          id
          name
          slug
          employeeCount
          createdAt
          updatedAt
          deletedAt
          translations {
            en
            fr
            nl
          }
          audienceFilter {
            filter
            type
            value
          }
        }
        translations {
          de
          en
          fr
          it
          nl
        }
      }
    }
  }
`;

const useFetchSegmentGroups = () => {
  const {error, loading: isFetching, data} = useQuery(SEGMENT_GROUPS_QUERY);

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      departments: [],
    };
  }

  const {
    segmentGroups: {data: segmentGroups},
  } = data;

  const {
    departments = [],
    locations = [],
    functions = [],
  } = segmentGroups.reduce(
    (map, segmentGroup) => ({
      [segmentGroup.slug]: sortby(segmentGroup.segments, 'name'),
      ...map,
    }),
    {},
  );
  return {
    isFetching,
    departments,
    locations,
    functions,
  };
};

export default useFetchSegmentGroups;
