import React from 'react';
import styled from 'styled-components';
import {color, flexbox, variant} from 'styled-system';
import Text from './Text';

const ParagraphWithVariants = styled(Text)(
  variant({
    variants: {
      1: {
        fontSize: 2,
        color: 'grey.9',
        lineHeight: 4,
      },
      2: {
        fontSize: 1,
        color: 'grey.5',
        lineHeight: 3,
      },
      3: {
        fontSize: 0,
        color: 'grey.5',
        lineHeight: 1,
      },
    },
  }),
  color,
  flexbox,
);

const Paragraph = ({children, ...props}) => (
  <ParagraphWithVariants mb={3} as="p" fontFamily="regular" {...props}>
    {children}
  </ParagraphWithVariants>
);

export default Paragraph;
