import React from 'react';
import ChooseChannelBlock from './ChooseChannelBlock';
import EditorBlockLoading from './EditorBlockLoading';
import useFetchChannelsData from '../../apollo/queries/useFetchChannelsData';

const ChooseChannelWithState = ({onUpdateUserChannels = null, ...props}) => {
  const {isFetching, channels, userChannels, updateQuery} =
    useFetchChannelsData();

  const handleUpdateUserChannels =
    onUpdateUserChannels === null
      ? null
      : (userChannel, updatedValue) =>
          onUpdateUserChannels(
            userChannels,
            updateQuery,
            userChannel,
            updatedValue,
          );

  if (isFetching) {
    return <EditorBlockLoading />;
  }

  return (
    <ChooseChannelBlock
      channels={channels}
      userChannels={userChannels}
      onUpdateUserChannels={handleUpdateUserChannels}
      {...props}
    />
  );
};

export default ChooseChannelWithState;
