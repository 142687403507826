import React, {useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import useCheerUserMessageMutation from './useCheerUserMessageMutation';
import useUncheerUserMessageMutation from './useUncheerUserMessageMutation';
import useBookmarkUserMessageMutation from './useBookmarkUserMessageMutation';
import useUnbookmarkUserMessageMutation from './useUnbookmarkUserMessageMutation';
import getBookmarks from '../../../apollo/queries/getBookmarks';
import MessageActions from './MessageActions';

const MessageActionsWithState = ({message, ...props}) => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [isOpen, setIsOpen] = useState(false);

  const [cheerUserMessage, {loading: isCheeringMessage}] =
    useCheerUserMessageMutation();
  const [uncheerUserMessage, {loading: isUncheeringMessage}] =
    useUncheerUserMessageMutation();
  const [bookmarkUserMessage, {loading: isBookmarkingMessage}] =
    useBookmarkUserMessageMutation();
  const [unbookmarkUserMessage, {loading: isUnbookmarkingMessage}] =
    useUnbookmarkUserMessageMutation();

  const handleCheerMessage = async () => {
    const variables = {id: message.id};
    if (message.isCheered) {
      await uncheerUserMessage({variables});
    } else {
      await cheerUserMessage({variables});
    }
    setIsOpen(false);
  };

  const handleBookmarkMessage = async () => {
    const variables = {id: message.id};
    if (message.isBookmarked) {
      await unbookmarkUserMessage({variables});
    } else {
      await bookmarkUserMessage({variables});
    }
    getBookmarks(apolloClient, {fetchPolicy: 'network-only'});
    setIsOpen(false);
  };

  const handleNavigateToComments = () =>
    history.push(`/messages/${message.id}/comments`);

  return (
    <MessageActions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      message={message}
      onBookmarkMessage={handleBookmarkMessage}
      isChangingBookmarkState={isBookmarkingMessage || isUnbookmarkingMessage}
      onCheerMessage={handleCheerMessage}
      isChangingCheerState={isCheeringMessage || isUncheeringMessage}
      onNavigateToComments={handleNavigateToComments}
      {...props}
    />
  );
};

export default MessageActionsWithState;
