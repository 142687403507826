import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Heading from '../../utils/Heading';

const CommentsPageHeader = ({onGoBack, ...props}) => (
  <Flex flexDirection="row" justifyContent="space-between" mb={6} {...props}>
    <Heading flex={1} variant={1} mb={3}>
      <Trans>Comments</Trans>
    </Heading>
    <Button
      variant="unstyled"
      onClick={onGoBack}
      flexDirection="row"
      alignItems="center"
      pr={2}>
      <Title variant={4} color="primary">
        <Trans>Back</Trans>
      </Title>
    </Button>
  </Flex>
);

export default CommentsPageHeader;
