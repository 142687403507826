import {useMutation, gql} from '@apollo/client';

export const UPDATE_CONFIGURED_CHANNELS_MUTATION = gql`
  mutation updateConfiguredChannelsMutation($body: AvatarBodyInput!) {
    updateConfiguredChannels(input: $body)
      @rest(
        type: "UserChannels"
        path: "/user-notifications/configured-channels"
        method: "POST"
      ) {
      data
    }
  }
`;

const useUpdateConfiguredChannels = () =>
  useMutation(UPDATE_CONFIGURED_CHANNELS_MUTATION);

export default useUpdateConfiguredChannels;
