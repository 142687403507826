/* eslint-disable prefer-destructuring */
export const colorGrey50 = '#FBFDFE';
export const colorGrey100 = '#F1F5F9';
export const colorGrey200 = '#E2E8F0';
export const colorGrey300 = '#CFD8E3';
export const colorGrey400 = '#97A6BA';
export const colorGrey500 = '#64748B';
export const colorGrey600 = '#475569';
export const colorGrey700 = '#364152';
export const colorGrey800 = '#27303F';
export const colorGrey900 = '#1A202E';

export const colorPrimary = '#0072FF';
export const colorWhite = '#ffffff';
export const colorBlack = colorGrey900;
export const colorText = colorGrey900;
export const colorTextGrey = colorGrey500;
export const colorTextLight = colorGrey400;
export const colorBorder = colorGrey200;
export const colorBackground = colorGrey100;
export const colorWhatsapp = '#25D366';
export const colorRed = '#F05252';
export const colorPlayItSafe = '#FF7700';
export const colorArteel = '#e53b37';
export const colorVanmoerSecondary = '#0097da';
export const colorVanmoerPrimary = '#192857';

export const colors = {
  grey: [
    colorGrey50, // 0
    colorGrey100, // 1
    colorGrey200, // 2
    colorGrey300, // 3
    colorGrey400, // 4
    colorGrey500, // 5
    colorGrey600, // 6
    colorGrey700, // 7
    colorGrey800, // 8
    colorGrey900, // 9
  ],
  appLoading: colorPrimary,
  primary: colorPrimary,
  white: colorWhite,
  black: colorBlack,
  text: colorText,
  textGrey: colorTextGrey,
  textLight: colorTextLight,
  background: colorBackground,
  border: colorBorder,
  whatsapp: colorWhatsapp,
  red: colorRed,
  playItSafe: colorPlayItSafe,
  arteel: colorArteel,
  vanmoer: colorVanmoerPrimary,
  vanmoerSecondary: colorVanmoerSecondary,
  shadow: 'rgba(0,0,0, .2)',
};

export const fontSize12 = 12;
export const fontSize14 = 14;
export const fontSize16 = 16;
export const fontSize18 = 18;
export const fontSize20 = 20;
export const fontSize22 = 22;
export const fontSize24 = 24;
export const fontSize32 = 32;

const fontSizes = [
  fontSize12, // 0
  fontSize14, // 1
  fontSize16, // 2
  fontSize18, // 3
  fontSize20, // 4
  fontSize22, // 5
  fontSize24, // 6
  fontSize32, // 7
];

export const lineHeight15 = '15px';
export const lineHeight17 = '17px';
export const lineHeight19 = '19px';
export const lineHeight20 = '20px';
export const lineHeight22 = '22px';
export const lineHeight24 = '24px';
export const lineHeight27 = '27px';
export const lineHeight29 = '29px';
export const lineHeight39 = '39px';
export const lineHeight40 = '40px';

const lineHeights = [
  lineHeight15, // 0
  lineHeight17, // 1
  lineHeight19, // 2
  lineHeight20, // 3
  lineHeight22, // 4
  lineHeight24, // 5
  lineHeight27, // 6
  lineHeight29, // 7
  lineHeight39, // 8
  lineHeight40, // 9
];

const fonts = {
  thin: '"Inter-Thin", sans-serif', // 100
  thinItalic: '"Inter-ThinItalic", sans-serif',
  extraLight: '"Inter-ExtraLight", sans-serif', // 200
  extraLightItalic: '"Inter-ExtraLightItalic", sans-serif',
  light: '"Inter-Light", sans-serif', // 300
  lightItalic: '"Inter-LightItalic", sans-serif',
  regular: '"Inter-Regular", sans-serif', // 400
  italic: '"Inter-Italic", sans-serif',
  medium: '"Inter-Medium", sans-serif', // 500
  mediumItalic: '"Inter-MediumItalic", sans-serif',
  semiBold: '"Inter-SemiBold", sans-serif', // 600
  semiBoldItalic: '"Inter-SemiBoldItalic", sans-serif',
  bold: '"Inter-Bold", sans-serif', // 700
  boldItalic: '"Inter-BoldItalic", sans-serif',
  extraBold: '"Inter-ExtraBold", sans-serif', // 800
  extraBoldItalic: '"Inter-ExtraBoldItalic", sans-serif',
  black: '"Inter-Black", sans-serif', // 900
  blackItalic: '"Inter-BlackItalic", sans-serif',
};

const fontWeights = [
  100, // 0
  200, // 1
  300, // 2
  400, // 3
  500, // 4
  600, // 5
  700, // 6
  800, // 7
];

fontWeights.extraThin = fontWeights[0];
fontWeights.thin = fontWeights[1];
fontWeights.light = fontWeights[2];
fontWeights.normal = fontWeights[3];
fontWeights.semiBold = fontWeights[4];
fontWeights.bold = fontWeights[5];
fontWeights.extraBold = fontWeights[6];
fontWeights.black = fontWeights[7];

const space = [
  2, // 0
  5, // 1
  8, // 2
  10, // 3
  12, // 4
  15, // 5
  20, // 6
  30, // 7
];

space.none = 0;

const radii = [
  10, // 0
  25, // 1
  30, // 2
  50, // 3
  75, // 4
  100, // 5
];

radii.none = 0;

const shadows = ['0px 4px 6px rgba(0, 0, 0, 0.04)'];

shadows.medium = shadows[0];

const logoIcon = 'roger';

export default {
  space,
  colors,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  radii,
  shadows,
  logoIcon,
};
