import {useQuery, gql} from '@apollo/client';

export const TOOLS_QUERY = gql`
  query toolssQuery {
    tools @rest(type: "Tools", path: "/extra-tools", method: "GET") {
      data {
        id
        href
        title
      }
    }
  }
`;

const useFetchToolsPageData = () => {
  const {data, error, loading} = useQuery(TOOLS_QUERY);

  return {
    error,
    isFetching: loading,
    tools: !error && !loading && data.tools.data,
  };
};

export default useFetchToolsPageData;
