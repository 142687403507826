import React from 'react';
import Box from '../../utils/Box';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import Paragraph from '../../utils/Paragraph';
import SurveyStatus from './SurveyStatus';

const SurveyBlockHeader = ({
  question,
  description,
  isPublished,
  isPublishing,
}) => {
  const hasQuestion = typeof question !== 'undefined';
  const hasDescription = typeof description !== 'undefined';
  return (
    <Box p={6}>
      <Flex flexDirection="row" justifyContent="space-between" mb={3}>
        <Title mr={3} flex={1} variant={3}>
          {hasQuestion && question}
        </Title>
        <SurveyStatus isPublished={isPublished} isPublishing={isPublishing} />
      </Flex>
      {hasDescription && <Paragraph variant={2}>{description}</Paragraph>}
    </Box>
  );
};

export default SurveyBlockHeader;
