const isValidNetworkError = networkError =>
  typeof networkError !== 'undefined' &&
  typeof networkError.response !== 'undefined';

export const isUnauthenticatedError = networkError => {
  if (isValidNetworkError(networkError)) {
    const {status} = networkError.response;
    if (status === 401) {
      return true;
    }
  }
  return false;
};

export const isWrongInputError = networkError => {
  if (isValidNetworkError(networkError)) {
    const {status} = networkError.response;
    if (status === 422) {
      return true;
    }
  }
  return false;
};

export const isUnauthorizedError = networkError => {
  if (isValidNetworkError(networkError)) {
    const {status} = networkError.response;
    if (status === 403) {
      return true;
    }
  }
  return false;
};

export const isNotFoundError = networkError => {
  if (isValidNetworkError(networkError)) {
    const {status} = networkError.response;
    if (status === 404) {
      return true;
    }
  }
  return false;
};
