import React from 'react';
import styled from 'styled-components';
import {
  color,
  space,
  layout,
  position,
  flexbox,
  shadow,
  border,
  typography,
  variant as styledVariant,
  background,
} from 'styled-system';
import Loader from '../../components/utils/Loader';

const ButtonWithVariants = styled.button(
  styledVariant({
    variants: {
      primary: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        bg: 'primary',
        py: 3,
        px: 6,
        borderRadius: 0,
      },
      outline: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'transparent',
        color: 'primary',
        py: 3,
        px: 6,
        borderRadius: 0,
        borderWidth: 1,
        borderColor: 'primary',
      },
      link1: {
        fontFamily: 'bold',
        color: 'primary',
        fontSize: 2,
        lineHeight: 2,
      },
      link2: {
        fontFamily: 'bold',
        color: 'primary',
        fontSize: 1,
        lineHeight: 1,
      },
      link1Outline: {
        fontFamily: 'bold',
        color: 'primary',
        fontSize: 2,
        lineHeight: 2,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        py: 3,
        px: 6,
        borderRadius: 0,
        borderWidth: 1,
        borderColor: 'primary',
      },
      unstyled: {},
      unstyledCentered: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        py: 5,
        px: 6,
      },
    },
  }),
  color,
  space,
  layout,
  position,
  flexbox,
  shadow,
  border,
  typography,
  background,
);

const Button = ({
  variant = 'primary',
  disabled,
  isLoading,
  children,
  ...props
}) => (
  <ButtonWithVariants
    variant={variant}
    disabled={isLoading || disabled}
    fontFamily="bold"
    display="flex"
    {...props}>
    {isLoading && (
      <Loader
        color={variant === 'primary' ? 'white' : 'primary'}
        size={18}
        mr={1}
      />
    )}
    {children}
  </ButtonWithVariants>
);

export default Button;
