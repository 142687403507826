import {useEffect, useState} from 'react';

export default function useOpen({isOpenFromProps, setIsOpenFromProps}) {
  const [isOpen, setIsOpen] = useState(isOpenFromProps || false);
  useEffect(() => {
    if (typeof setIsOpenFromProps === 'function') {
      setIsOpenFromProps(isOpen);
    }
  }, [isOpen, setIsOpenFromProps]);

  useEffect(() => {
    setIsOpen(isOpenFromProps);
  }, [isOpenFromProps]);

  return [isOpen, setIsOpen];
}
