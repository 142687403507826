import {useQuery, gql} from '@apollo/client';

export const DOCUMENTS_QUERY = gql`
  query documentsQuery {
    directories
      @rest(type: "Directories", path: "/user/directories", method: "GET") {
      id
      name
      parent
      createdAt
      updatedAt
      files {
        id
        name
        fileName
        mimeType
        manipulations
        orderColumn
        usedAt
        fileSize
        createdAt
        updatedAt
        sizes {
          full
          thumb
          regular
        }
      }
      children {
        id
        name
        createdAt
        updatedAt
        files
        children
        parent
      }
    }
  }
`;

export const DIRECTORY_QUERY = gql`
  query directoryQuery($id: Int) {
    directories(id: $id)
      @rest(
        type: "Directories"
        path: "/user/directories/{args.id}"
        method: "GET"
      ) {
      id
      name
      parent {
        id
        name
        createdAt
        updatedAt
        files
        children
        parent
      }
      createdAt
      updatedAt
      files {
        id
        name
        fileName
        mimeType
        manipulations
        orderColumn
        usedAt
        fileSize
        createdAt
        updatedAt
        sizes {
          full
          thumb
          regular
        }
      }
      children {
        id
        name
        createdAt
        updatedAt
        files
        children
        parent
      }
    }
  }
`;

const useFetchDocumentsPageData = id => {
  const variables = {id};
  const query = typeof id === 'undefined' ? DOCUMENTS_QUERY : DIRECTORY_QUERY;
  const {error, loading: isFetching, data} = useQuery(query, {variables});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      parent: null,
      documents: [],
    };
  }

  const {
    directories: {parent, children, files},
  } = data;
  let documents = [];
  if (children !== null) {
    documents = [
      ...documents,
      ...children.map(child => ({...child, isDirectory: true})),
    ];
  }
  if (files !== null) {
    documents = [
      ...documents,
      ...files.map(file => ({...file, isDirectory: false})),
    ];
  }

  return {
    isFetching,
    parent,
    documents,
  };
};

export default useFetchDocumentsPageData;
