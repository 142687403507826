/* eslint-disable react/jsx-pascal-case */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Container from '../../components/utils/Container';
import Header from '../../components/utils/Header';
import Box from '../../components/utils/Box';
import Select from '../../components/utils/Select';
import Input from '../../components/utils/Input';
import FormItem from '../../components/utils/FormItem';
import Button from '../../components/utils/Button';
import useAdminMessages from './useAdminMessages';
import TextArea from '../../components/utils/TextArea';
import Title from '../../components/utils/Title';
import Paragraph from '../../components/utils/Paragraph';
import Icon from '../../components/utils/Icon';
import Flex from '../../components/utils/Flex';
import Heading from '../../components/utils/Heading';
import Page, {PageContentScrollable} from '../../components/utils/Page';
import AttachmentUploader from './AttachmentUploader';
import useFetchSegmentGroups from '../../components/pages/colleagues/useFetchSegmentGroups';
import useCurrentUser from '../../components/hooks/useCurrentUser';
import {IDEA_BOX, WHISTLE_BLOWER} from './constants';

const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const formConfigs = {
  [IDEA_BOX]: {
    fields: ['title', 'body'],
    icon: 'idea',
    label: () => <Trans>Idea Box</Trans>,
  },
  [WHISTLE_BLOWER]: {
    fields: ['title', 'body'],
    icon: 'anonymous',
    description: () => (
      <Trans>
        This form allows you to report “sensitive information” anonymously. Be
        sure not to include personal information in the message. Please be
        clear, precise in your description and polite.
      </Trans>
    ),
    label: () => <Trans>Anonymous Message</Trans>,
  },
  default: {
    fields: ['title', 'body'],
    icon: 'pen',
  },
};

const mapFilterToSelect = currentLanguage => segmentGroup => ({
  label: segmentGroup?.translations?.[currentLanguage] || segmentGroup?.slug,
  value: segmentGroup,
});

const AdminMessageCreatePage = () => {
  const [messageType, setMessageType] = useState('');
  const [form, setForm] = useState({});
  const [attachments, setAttachments] = useState([]);
  const history = useHistory();
  const {departments} = useFetchSegmentGroups();
  const {postAdminInboxMessage, forms} = useAdminMessages();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const currentUser = useCurrentUser();
  const filterMapper = useCallback(mapFilterToSelect(currentUser.language), [
    currentUser.language,
  ]);
  const FormConfig = useMemo(() => {
    if (!messageType) return undefined;
    return formConfigs[messageType.value] || formConfigs.default;
  }, [forms, messageType]);

  const formOptions = useMemo(
    () =>
      forms?.reduce((acc, key) => {
        if (!formConfigs[key]) return acc;
        return [
          ...acc,
          {
            value: key,
            label: (
              <Title variant={5}>
                <Icon noStroke name={formConfigs[key].icon} />
                {formConfigs[key].label()}
              </Title>
            ),
          },
        ];
      }, []),
    [forms],
  );
  useEffect(() => {
    if (formOptions.length === 1 && !messageType) {
      setMessageType(formOptions[0]);
    }
  }, [formOptions]);
  const HeaderTitle = useMemo(() => {
    if (hasSubmitted) {
      return <Trans>Successfully submitted message!</Trans>;
    }
    if (messageType.value === IDEA_BOX) {
      return <Trans>Your voice matters: share your idea</Trans>;
    }
    if (messageType.value === WHISTLE_BLOWER) {
      return <Trans>Please submit your anonymous message here</Trans>;
    }
    return <Trans>Create a New Message</Trans>;
  }, [messageType]);
  return (
    <>
      <Header />
      <Page>
        <PageContentScrollable>
          <Heading variant={1} mb={3}>
            {HeaderTitle}
          </Heading>
          <Container borderRadius={0} p={6} bg="grey.0" boxShadow={0}>
            {!hasSubmitted ? (
              <Form mt={2}>
                <FormItem label={<Trans>Message Type</Trans>}>
                  <Select
                    name="messageType"
                    value={messageType}
                    onChange={value => setMessageType(value)}
                    options={formOptions}
                    disabled={formOptions.length === 1}
                    bg={1}
                  />
                </FormItem>
                {messageType && (
                  <>
                    {FormConfig.description && (
                      <Box p={3} borderRadius={0} mt={2} bg="grey.1">
                        <Paragraph mt={2}>{FormConfig.description()}</Paragraph>
                      </Box>
                    )}
                    <FormItem label={<Trans>Title</Trans>} required mt={2}>
                      <Input
                        type="text"
                        name="title"
                        value={form.title}
                        onChange={({target: {value}}) =>
                          setForm(f => ({...f, title: value}))
                        }
                        bg="grey.1"
                        required
                      />
                    </FormItem>
                    <FormItem label={<Trans>Body</Trans>} mt={2}>
                      <TextArea
                        name="body"
                        bg="grey.1"
                        noResize
                        rows={5}
                        value={form.body}
                        onChange={({target: {value}}) =>
                          setForm(f => ({...f, body: value}))
                        }
                      />
                    </FormItem>
                    {messageType.value === IDEA_BOX && (
                      <FormItem label={<Trans>Department</Trans>}>
                        {!!departments.length && (
                          <Select
                            id="departments-filter"
                            bg={1}
                            onChange={({value: department, label}) =>
                              setForm(oldForm => ({
                                ...oldForm,
                                ...(department?.id
                                  ? {
                                      segment_id: department.id,
                                      sub_type: label,
                                    }
                                  : {segment_id: null, sub_type: null}),
                              }))
                            }
                            options={[
                              {value: null, label: <Trans>None</Trans>},
                              ...departments.map(filterMapper),
                            ]}
                          />
                        )}
                      </FormItem>
                    )}
                    <FormItem label={<Trans>Attachments</Trans>}>
                      <AttachmentUploader
                        onChange={newAttachments => {
                          setForm(newForm => ({
                            ...newForm,
                            attachment_ids: newAttachments.map(({id}) => id),
                          }));
                          setAttachments(newAttachments);
                        }}
                      />
                    </FormItem>
                    <Button
                      disabled={!messageType || !form.title || !form.body}
                      onClick={async () => {
                        await postAdminInboxMessage({
                          type: messageType.value,
                          ...form,
                        });
                        setHasSubmitted(true);
                      }}>
                      <Trans>Submit</Trans>
                    </Button>
                  </>
                )}
              </Form>
            ) : (
              <Box bg="grey.1" p={2} m={3} borderRadius={0}>
                <Flex alignItems="center">
                  <Icon noStroke name={FormConfig.icon} />
                  <Title variant={0}>{FormConfig.label()}</Title>
                </Flex>
                <Title variant={2} mt={4}>
                  {form.title}
                </Title>
                {form.sub_type && <small>{form.sub_type}</small>}
                <Paragraph mt={4}>{form.body}</Paragraph>
                <Box mt={4}>
                  <AttachmentUploader attachments={attachments} noEdit />
                </Box>
                <Button
                  mt={4}
                  onClick={() => {
                    setForm(null);

                    history.push('/');
                  }}>
                  <Trans>Go to homepage</Trans>
                </Button>
              </Box>
            )}
          </Container>
        </PageContentScrollable>
      </Page>
    </>
  );
};

export default AdminMessageCreatePage;
