import React from 'react';
import {getFileType, getFileTypeGroup} from '../../../utils/fileTypeHelpers';
import MediaBlock from './MediaBlock';
import MediaBlockNotFound from './MediaBlockNotFound';
import {whereId} from '../../../utils';

const PublicMediaBlock = ({size, mediaItemId, mediaItems}) => {
  const mediaItem = mediaItems.find(whereId(mediaItemId));

  if (mediaItem && mediaItem.id === null) {
    return null;
  }
  if (typeof mediaItem === 'undefined') {
    return <MediaBlockNotFound />;
  }

  const fileType = getFileType(mediaItem.mimeType);
  const fileTypeGroup = getFileTypeGroup(fileType);

  return <MediaBlock mediaItem={mediaItem} type={fileTypeGroup} size={size} />;
};

export default PublicMediaBlock;
