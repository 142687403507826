import React from 'react';
import {getFileType, getFileTypeGroup} from '../../../utils/fileTypeHelpers';
import EditorBlockLoading from '../EditorBlockLoading';
import useFetchMediaItem from '../../../apollo/queries/useFetchMediaItem';
import MediaBlock from './MediaBlock';
import MediaBlockNotFound from './MediaBlockNotFound';

const MediaBlockWithState = ({size, mediaItemId}) => {
  const {isFetching, mediaItem} = useFetchMediaItem(mediaItemId);

  if (mediaItem && mediaItem.id === null) {
    return null;
  }

  if (isFetching) {
    return <EditorBlockLoading />;
  }

  if (typeof mediaItem === 'undefined') {
    return <MediaBlockNotFound />;
  }

  const fileType = getFileType(mediaItem.mimeType);
  const fileTypeGroup = getFileTypeGroup(fileType);

  return <MediaBlock mediaItem={mediaItem} type={fileTypeGroup} size={size} />;
};

export default MediaBlockWithState;
