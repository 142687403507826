import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import getTermsAndConditionsLink from '../../../apollo/getApiLinks';
import useAcceptTermsAndConditionsMutation from './useAcceptTermsAndConditionsMutation';
import useAppState from '../../hooks/useAppState';
import TermsAndConditionsPage from './TermsAndConditionsPage';

const TermsAndConditionsPageWithState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditionsHtml, setTermsAndConditionsHtml] = useState();
  const history = useHistory();

  const [acceptTermsMutation] = useAcceptTermsAndConditionsMutation();
  const {updateCurrentUser} = useAppState();

  useEffect(() => {
    const init = async () => {
      const termsUrl = await getTermsAndConditionsLink();
      const termsHtmlResponse = await fetch(termsUrl);
      const termsHtmlData = await termsHtmlResponse.text();
      setTermsAndConditionsHtml(termsHtmlData);
    };

    init();
  }, []);

  const acceptTermsAndConditions = async () => {
    setIsLoading(true);

    try {
      const response = await acceptTermsMutation();
      setIsLoading(false);
      await updateCurrentUser(response.data.acceptTermsAndConditions);
      history.push('/');
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <TermsAndConditionsPage
      accept={acceptTermsAndConditions}
      isLoading={isLoading}
      html={termsAndConditionsHtml}
    />
  );
};

export default TermsAndConditionsPageWithState;
