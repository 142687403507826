import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import ProfileInfoRow from './ProfileInfoRow';

const VERSION = process.env.REACT_APP_VERSION;

const ProfileOtherSection = ({onOpenPrivacyPolicy, ...props}) => (
  <Flex flexDirection="column" {...props}>
    <Title variant={2} mb={6}>
      <Trans>Other</Trans>
    </Title>
    <Button variant="unstyled" onClick={onOpenPrivacyPolicy} mt={6}>
      <Flex
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <ProfileInfoRow info={<Trans>Privacy Policy</Trans>} icon="privacy" />
        <Icon name="chevronRight" color="grey" />
      </Flex>
    </Button>
    <ProfileInfoRow
      info={
        <>
          <Trans>Version</Trans> {VERSION}
        </>
      }
      icon="box"
      mt={6}
    />
  </Flex>
);

export default ProfileOtherSection;
