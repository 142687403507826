import React, {useReducer, createContext, useMemo} from 'react';
import Dialog from '../utils/Dialog';

export const DialogContext = createContext();

const reducer = (state, action) => {
  const {type, values} = action;
  switch (type) {
    case 'OPEN': {
      const {actionCallback, children} = values;
      return {
        ...state,
        isOpen: true,
        actionCallback,
        children,
      };
    }
    case 'CONFIRM': {
      const {actionCallback} = state;
      actionCallback(true);
      return {
        isOpen: false,
      };
    }
    case 'DISMISS': {
      const {actionCallback} = state;
      actionCallback(false);
      return {
        isOpen: false,
      };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};

const DialogProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {});

  const ProviderValue = useMemo(() => ({dispatch}), [dispatch]);
  return (
    <DialogContext.Provider value={ProviderValue}>
      {state.isOpen && <Dialog {...state} />}
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
