import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../components/utils/Box';
import Flex from '../components/utils/Flex';
import Button from '../components/utils/Button';
import Paragraph from '../components/utils/Paragraph';
import ModalTopBar from '../components/utils/ModalTopBar';
import {
  CHANNEL_SMS as SMS,
  CHANNEL_MAIL as MAIL,
  CHANNEL_WHATS_APP as WHATS_APP,
  CHANNEL_PUSH_NOTIFICATION as PUSH_NOTIFICATION,
} from './constants';

export const configureChannels = async (
  userChannels,
  userChannel,
  channelIsConfigured,
  dialog,
) => {
  let newChannels = [];
  let channelIsChanged = true;

  if (channelIsConfigured) {
    // Remove a channel
    newChannels = userChannels.filter(channel => channel !== userChannel);
  } else {
    // Add a channel
    newChannels = [...userChannels, userChannel];
    // Disable SMS when enabling WhatsApp
    if (userChannel === WHATS_APP && userChannels.includes(SMS)) {
      newChannels = newChannels.filter(channel => channel !== SMS);
    }
    if (userChannel === SMS) {
      // Disable WhatsApp when enabling SMS
      if (userChannels.includes(WHATS_APP)) {
        newChannels = newChannels.filter(channel => channel !== WHATS_APP);
      }
      if (userChannels.includes(PUSH_NOTIFICATION)) {
        await dialog.open({
          children: (
            <>
              <ModalTopBar
                title={<Trans>Notifications through multiple channels</Trans>}
              />
              <Box>
                <Paragraph mb={6} variant={2}>
                  <Trans>
                    You already receive push notifications on your mobile
                    device. Is it okay if we keep sms notifications turned off?
                  </Trans>
                </Paragraph>
              </Box>
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  variant="unstyledCentered"
                  onClick={dialog.dismiss}
                  flex={1}>
                  <Trans>No</Trans>
                </Button>
                <Button
                  onClick={() => {
                    newChannels = newChannels.filter(
                      channel => channel !== SMS,
                    );
                    channelIsChanged = false;
                    dialog.confirm();
                  }}
                  flex={1}>
                  <Trans>Okay</Trans>
                </Button>
              </Flex>
            </>
          ),
        });
      }
    }
    if (userChannel === PUSH_NOTIFICATION) {
      // Disable WhatsApp when enabling push notifications
      if (userChannels.includes(WHATS_APP)) {
        newChannels = newChannels.filter(channel => channel !== WHATS_APP);
      }
      if (userChannels.includes(SMS)) {
        await dialog.open({
          children: (
            <>
              <ModalTopBar
                title={<Trans>Notifications through multiple channels</Trans>}
              />
              <Box>
                <Paragraph mb={6} variant={2}>
                  <Trans>
                    You will receive push notifications on your mobile device.
                    Is it okay if we turn sms notifications off?
                  </Trans>
                </Paragraph>
              </Box>
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  variant="unstyledCentered"
                  onClick={dialog.dismiss}
                  flex={1}>
                  <Trans>No</Trans>
                </Button>
                <Button
                  onClick={() => {
                    newChannels = newChannels.filter(
                      channel => channel !== SMS,
                    );
                    dialog.confirm();
                  }}
                  flex={1}>
                  <Trans>Okay</Trans>
                </Button>
              </Flex>
            </>
          ),
        });
      }
    }
  }

  return {
    newChannels,
    channelIsChanged,
  };
};

export const getChannelInfo = (channel, me, permissions) => {
  const {email, workEmail, mobile, workMobile} = me;
  const {canUseWhatsApp} = permissions;

  const {REACT_APP_NAME: APP_NAME} = process.env;

  switch (channel) {
    case MAIL:
      if (email !== null || workEmail !== null) {
        return {
          title: <Trans>Email</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you an email when new messages are available.
            </Trans>
          ),
          icon: 'email',
        };
      }
      return null;
    case SMS:
      if (mobile !== null || workMobile !== null) {
        return {
          title: <Trans>Sms</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you an sms when new messages are available.
            </Trans>
          ),
          icon: 'chat',
        };
      }
      return null;
    case PUSH_NOTIFICATION:
      return {
        title: <Trans>Mobile App</Trans>,
        description: (
          <Trans>
            {APP_NAME} will send you a push notification when new messages are
            available.
          </Trans>
        ),
        icon: 'mobilePhone',
      };
    case WHATS_APP:
      if (canUseWhatsApp && (mobile !== null || workMobile !== null)) {
        return {
          title: <Trans>WhatsApp</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you a message on Whatsapp when new messages
              are available.
            </Trans>
          ),
          icon: 'whatsapp',
        };
      }
      return null;
    default:
      return null;
  }
};
