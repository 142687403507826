import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import getUrlBySize, {FULL} from '../../../utils/sizeHelper';
import ErrorPageWithState from '../error/ErrorPageWithState';
import DocumentsPage from './DocumentsPage';
import useFetchDocumentsPageData from './useFetchDocumentsPageData';

const DocumentsPageWithState = () => {
  const history = useHistory();
  const {directoryId} = useParams();
  const {error, isFetching, parent, documents} =
    useFetchDocumentsPageData(directoryId);

  if (
    typeof error !== 'undefined' &&
    error.message !== 'Response not successful: Received status code 422'
  ) {
    return <ErrorPageWithState error={error} />;
  }

  const handleOpenDirectory = document =>
    history.push(`/documents/${document.id}`);
  const handleGoUpOneDirectory = () =>
    parent !== null && handleOpenDirectory(parent);
  const handleOpenFile = file => window.open(getUrlBySize(file, FULL));

  return (
    <DocumentsPage
      error={error}
      isFetching={isFetching}
      documents={documents}
      parentDirectory={parent}
      onOpenDirectory={handleOpenDirectory}
      onGoUpOneDirectory={handleGoUpOneDirectory}
      onOpenFile={handleOpenFile}
    />
  );
};

export default DocumentsPageWithState;
