import React from 'react';
import Box from './Box';

const CONTAINER_WIDTH = 102.4;

const Container = ({children, ...props}) => (
  <Box
    maxWidth={`${CONTAINER_WIDTH}rem`}
    width="100%"
    px={5}
    mx="auto"
    {...props}>
    {children}
  </Box>
);

export default Container;
