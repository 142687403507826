const {REACT_APP_API, REACT_APP_HOST} = process.env;
const getApiRoot = () => {
  const fqdn = (window && window.location && window.location.hostname) || '';
  const urlParts = fqdn.split('.');
  const hostParts = REACT_APP_HOST.split('.');

  if (fqdn === REACT_APP_HOST || hostParts.length + 1 !== urlParts.length) {
    const url = new URL(window.location.toString());
    url.hostname = `roger.${REACT_APP_HOST}`;
    window.location.replace(url.toString());
  }

  const companySlug = urlParts[0];
  let apiRoot = REACT_APP_API;
  if (typeof companySlug !== 'undefined') {
    apiRoot = apiRoot.replace('https://', `https://${companySlug}.`);
    apiRoot = apiRoot.replace('http://', `http://${companySlug}.`);
  }

  return apiRoot;
};

export default getApiRoot;
