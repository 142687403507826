import React from 'react';
import Icon from './Icon';
import Flex from './Flex';
import Label from './Label';
import Button from './Button';

const FilterTags = ({filters, onRemoveFilter, ...props}) => {
  if (filters.length === 0) {
    return null;
  }
  return (
    <Flex flexDirection="row" flexWrap="wrap" {...props}>
      {filters.map(filter => (
        <Flex
          key={filter.id}
          flexDirection="row"
          alignItems="center"
          bg="primary"
          px={5}
          py={1}
          mr={3}
          mt={2}
          borderRadius={0}>
          <Label variant={3} color="white" mr={3}>
            {filter.name}
          </Label>
          <Button variant="unstyled" onClick={() => onRemoveFilter(filter)}>
            <Icon name="cross" size="small" color="white" />
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};

export default FilterTags;
