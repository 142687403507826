import {useMutation, gql} from '@apollo/client';

export const DELETE_COMMENT_MUTATION = gql`
  mutation deleteCommentMutation($messageId: ID!, $commentId: ID!) {
    deleteComment(messageId: $messageId, commentId: $commentId)
      @rest(
        type: "Comment"
        path: "/user-messages/{args.messageId}/comments/{args.commentId}"
        method: "DELETE"
      ) {
      id
    }
  }
`;

const useDeleteCommentMutation = () => useMutation(DELETE_COMMENT_MUTATION);

export default useDeleteCommentMutation;
