import React from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import useLingui from '../hooks/useLingui';
import Box from './Box';
import Flex from './Flex';
import Icon from './Icon';
import Input from './Input';
import FillIcon from './FillIcon';

const SearchBox = ({value, onChange, ...props}) => {
  const lingui = useLingui();

  return (
    <Box position="relative" boxShadow={0} borderRadius={0} {...props}>
      <Flex
        pl={1}
        top={0}
        left={0}
        bottom={0}
        position="absolute"
        alignItems="center">
        <Icon name="magnifier" size="small" color="grey" />
      </Flex>
      <Input
        py={3}
        px={7}
        width="100%"
        bg="grey.2"
        borderRadius={0}
        placeholder={lingui._(t`Search`)}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
      <Flex
        pr={1}
        top={0}
        right={0}
        bottom={0}
        position="absolute"
        alignItems="center">
        {value !== '' && (
          <FillIconWithStyle
            onClick={() => onChange('')}
            name="clear"
            size="small"
          />
        )}
      </Flex>
    </Box>
  );
};

const FillIconWithStyle = styled(FillIcon)`
  cursor: pointer;
`;

export default SearchBox;
