import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import makeAnimated from 'react-select/animated';
import {background} from 'styled-system';
import Label from './Label';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const animatedComponents = makeAnimated();
// TODO: move select styling to separate component
const StyledSelect = styled(ReactSelect)`
  ${background}
  .select__control {
    border: none;
    outline: none;
    padding: 0.5rem;
    background: ${({theme, bg}) => theme.colors.grey[0 || bg]};
    border-radius: ${({theme}) => theme.radii[0]};
  }
  .select__input-container {
  }
  .select__multi-value {
    background-color: #0577fe;
    color: white;
    margin: 0.125rem;
    padding: 0.25rem;
    border-radius: ${({theme}) => theme.radii[0]};
  }
  .select__value-container {
    padding: 0;
  }
  .select__multi-value__label {
    color: white;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .select__multi-value__remove {
    cursor: pointer;
    &:hover {
      background: transparent;
      color: inherit;
    }
  }
  .select__indicator-separator {
    display: none;
  }
`;

const Select = ({name, label, ...props}) => (
  <Container>
    <Label htmlFor={name} mb={1} variant={3}>
      {label}
    </Label>
    <StyledSelect
      components={animatedComponents}
      {...props}
      classNamePrefix="select"
      name={name}
    />
  </Container>
);

export default Select;
