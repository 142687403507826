import React, {useState} from 'react';
import * as Msal from 'msal';
import {useHistory} from 'react-router-dom';
import {useQueryParam, StringParam} from 'use-query-params';
import WelcomePage from './WelcomePage';
import usePermissions from '../../hooks/usePermissions';
import useCompany from '../../hooks/useCompany';
import useNavigateWithSearch from '../../hooks/useNavigateWithSearch';
import useAppState from '../../hooks/useAppState';
import useNotifications from '../../hooks/useNotifications';
import useLoginAzureADMutation from './useLoginAzureADMutation';

const WelcomePageWithState = () => {
  const history = useHistory();
  const {
    canUseWhatsApp,
    canUseAzureAdAuth,
    canUseWhatsAppAuth,
    canUseEmailAuth,
    canUseSmsAuth,
  } = usePermissions();
  const {dispatchError} = useNotifications();
  const {login} = useAppState();
  const currentCompany = useCompany();
  const [isADLoginLoading, setIsADLoginLoading] = useState(false);

  const azureAdConfig = canUseAzureAdAuth
    ? currentCompany?.settings?.azureAdAuth
    : null;

  const [redirect] = useQueryParam('redirect', StringParam);
  const [loginAzureAD] = useLoginAzureADMutation();
  const navigateWithSearch = useNavigateWithSearch();

  const handleNavigateToLogin = method => {
    navigateWithSearch(`/login/${method}`);
  };
  const performAzureADLogin = async accessToken => {
    try {
      const {
        data: {loginAzureAD: response},
      } = await loginAzureAD({variables: {accessToken}});
      await login(response);

      if (typeof redirect !== 'undefined') {
        history.push(redirect);
      } else {
        history.push('/');
      }
    } catch (e) {
      dispatchError();
    }
  };

  const getADToken = (myMSALObj, loginRequest) =>
    new Promise((resolve, reject) => {
      myMSALObj
        .acquireTokenSilent(loginRequest)
        .then(response => {
          resolve(response);
        })
        .catch(() =>
          // fallback to interaction when silent call fails
          myMSALObj
            .acquireTokenPopup(loginRequest)
            .then(tokenResponse => {
              resolve(tokenResponse);
            })
            .catch(error => {
              reject(error);
            }),
        );
    });
  const onADLogin = () => {
    setIsADLoginLoading(true);

    const msalConfig = {
      auth: {
        clientId: azureAdConfig.config.clientId,
        redirectUri: azureAdConfig.config.callbackUrlWebapp,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    const myMSALObj = new Msal.UserAgentApplication(msalConfig);
    const loginRequest = {
      scopes: ['profile', 'User.Read'],
    };

    myMSALObj
      .loginPopup(loginRequest)
      .then(() => {
        getADToken(myMSALObj, loginRequest)
          .then(tokenResponse => {
            performAzureADLogin(tokenResponse.accessToken);

            setIsADLoginLoading(false);
          })
          .catch(() => {
            setIsADLoginLoading(false);
            dispatchError();
          });
      })
      .catch(() => {
        setIsADLoginLoading(false);
        dispatchError();
      });
  };

  return (
    <WelcomePage
      canUseWhatsApp={canUseWhatsApp && canUseWhatsAppAuth}
      canUseEmailAuth={canUseEmailAuth}
      canUseSmsAuth={canUseSmsAuth}
      canUseAD={canUseAzureAdAuth && azureAdConfig}
      onNavigateToLogin={handleNavigateToLogin}
      onADLogin={onADLogin}
      isADLoginLoading={isADLoginLoading}
    />
  );
};

export default WelcomePageWithState;
