import {useQuery, gql} from '@apollo/client';

export const PUBLIC_COMPANY_QUERY = gql`
  query companyQuery {
    company @rest(type: "Company", path: "/public/company", method: "GET") {
      name
      slug
      defaultCountry
      defaultLanguage
      createdAt
      updatedAt
      maxFileUpload
      countries
      languages
      branding {
        colors {
          brand {
            base
            dark
          }
        }
        logo {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            regular
          }
          customProperties {
            generatedConversions {
              small
              thumb
              regular
            }
          }
        }
        banner {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            banner
            regular
          }
          customProperties {
            generatedConversions {
              banner
            }
          }
        }
      }
      settings {
        brandingColor
        logo {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            regular
          }
          customProperties {
            generatedConversions {
              small
              thumb
              regular
            }
          }
        }
        externalUserManagement
        canUseAzureAdAuth
        canUseWhatsappAuth
        canUseSmsAuth
        canUseEmailAuth
        azureAdAuth {
          config {
            clientId
            callbackUrlWebapp
          }
        }
      }
    }
  }
`;

export const usePublicCompanyQuery = () => useQuery(PUBLIC_COMPANY_QUERY);

export default async function getPublicCompany(apollo, options) {
  const query = PUBLIC_COMPANY_QUERY;
  const response = await apollo.query({query, ...options});
  return response.data.company;
}
