import React from 'react';
import {Trans} from '@lingui/macro';
import {LIST_TYPE_UNREAD} from '../../../utils/constants';
import EmptyList from '../../utils/EmptyList';
import MessagesPageWithFilters from './MessagesPageWithFilters';

const ReadMessagesPage = ({
  userMessages,
  isFetching,
  isFetchingMore,
  hasMorePages,
  onFetchMore,
  statusFilter,
  onChangeStatusFilter,
  selectedAppDomains,
  onChangeFilters,
  search,
  onChangeSearch,
  onRemoveSelectedAppDomain,
}) => (
  <MessagesPageWithFilters
    userMessages={userMessages}
    isFetching={isFetching}
    isFetchingMore={isFetchingMore}
    hasMorePages={hasMorePages}
    onFetchMore={onFetchMore}
    emptyList={
      <EmptyList
        icon="inbox"
        title={
          typeof searchFilter === 'undefined' &&
          typeof selectedAppDomains === 'undefined' ? (
            <Trans id="You haven't read any messages yet." />
          ) : (
            <Trans>No messages found.</Trans>
          )
        }
        action={
          typeof searchFilter === 'undefined'
            ? () => onChangeStatusFilter(LIST_TYPE_UNREAD)
            : null
        }
        actionText={<Trans>New messages</Trans>}
        mt={7}
      />
    }
    selectedAppDomains={selectedAppDomains}
    onChangeFilters={onChangeFilters}
    statusFilter={statusFilter}
    onChangeStatusFilter={onChangeStatusFilter}
    search={search}
    onChangeSearch={onChangeSearch}
    onRemoveSelectedAppDomain={onRemoveSelectedAppDomain}
  />
);

export default ReadMessagesPage;
