import React from 'react';
import {Trans} from '@lingui/macro';
import styled from 'styled-components';
import Box from '../../utils/Box';
import Tag from '../../utils/Tag';
import Date from '../../utils/Date';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import MessageActionsWithState from '../message-details/MessageActionsWithState';
import MessagesListItemBanner from './MessagesListItemBanner';
import Icon from '../../utils/Icon';

const MessagesListItem = ({message, onNavigateToMessage, ...props}) => {
  const {appDomain, scheduledAt, featuredImage} = message;

  let {title} = message;
  const {isRead} = message;
  // For legacy titles which hold a translation key
  if (typeof title === 'object') {
    [title] = Object.values(title);
  }

  return (
    <Card
      mb={7}
      width="100%"
      maxWidth="40rem"
      display="flex"
      flexDirection="column"
      bg="white"
      borderRadius={0}
      boxShadow={0}
      onClick={onNavigateToMessage}
      {...props}>
      {featuredImage !== null && (
        <MessagesListItemBanner featuredImage={featuredImage} />
      )}
      <Box width="100%" p={5}>
        <Flex flexDirection="row" mb={2}>
          <Tag variant={2} mr={4}>
            {appDomain.name}
          </Tag>
          <Tag variant={2}>
            <Date value={scheduledAt} />
          </Tag>
          {!isRead && (
            <Box ml="auto" title="Unread">
              <Icon name="newMessage" size="smaller" />
            </Box>
          )}
        </Flex>
        <Flex justifyContent="space-between">
          <Title mb={5} variant={3} textAlign="left">
            {title}
          </Title>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Button variant="link1">
            <Trans>Read more</Trans>
          </Button>
          <MessageActionsWithState message={message} />
        </Flex>
      </Box>
    </Card>
  );
};

const Card = styled(Box)`
  cursor: pointer;
  transition: 0.3s ease all;
  &:hover {
    transform: scale(1.02);
  }
`;

export default MessagesListItem;
