import {useQuery, gql} from '@apollo/client';
import DEFAULT_MESSAGE_FRAGMENT from '../../../apollo/fragments/defaultUserMessage';
import DEFAULT_FILE_FRAGMENT from '../../../apollo/fragments/defaultFile';

export const USER_MESSAGE_QUERY = gql`
  query userMessageQuery($id: ID!) {
    userMessage(id: $id)
      @rest(
        type: "UserMessage"
        path: "/public/user-messages/{args.id}"
        method: "GET"
      ) {
      ...DefaultMessage
    }
    mediaItems(id: $id)
      @rest(
        type: "Files"
        path: "/public/user-messages/{args.id}/media"
        method: "GET"
      ) {
      data {
        ...DefaultFile
      }
    }
  }
  ${DEFAULT_MESSAGE_FRAGMENT}
  ${DEFAULT_FILE_FRAGMENT}
`;

const useFetchPublicMessageDetailsPageData = options => {
  const {
    data,
    error,
    loading: isFetching,
  } = useQuery(USER_MESSAGE_QUERY, options);

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      userMessage: {},
      isFetching,
    };
  }

  const {
    userMessage,
    mediaItems: {data: mediaItems},
  } = data;

  return {
    userMessage,
    mediaItems,
    isFetching,
  };
};

export default useFetchPublicMessageDetailsPageData;
