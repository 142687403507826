import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import useCompany from './hooks/useCompany';
import usePermissions from './hooks/usePermissions';
import useCurrentUser from './hooks/useCurrentUser';
import HomePageWithState from './pages/home/HomePageWithState';
import ToolsPageWithState from './pages/tools/ToolsPageWithState';
import WelcomePageWithState from './pages/welcome/WelcomePageWithState';
import ProfilePageWithState from './pages/settings/ProfilePageWithState';
import CommentsPageWithState from './pages/comments/CommentsPageWithState';
import MessagesPageWithState from './pages/messages/MessagesPageWithState';
import CalendarPageWithState from './pages/calendar/CalendarPageWithState';
import LoginOtpPageWithState from './pages/login-otp/LoginOtpPageWithState';
import LoginSmsPageWithState from './pages/login-sms/LoginSmsPageWithState';
import DocumentsPageWithState from './pages/documents/DocumentsPageWithState';
import ColleaguesPageWithState from './pages/colleagues/ColleaguesPageWithState';
import LoginEmailPageWithState from './pages/login-email/LoginEmailPageWithState';
import UserCommentsPageWithState from './pages/user-comments/UserCommentsPageWithState';
import LoginWhatsAppPageWithState from './pages/login-whatsapp/LoginWhatsAppPageWithState';
import MessageDetailsPageWithState from './pages/message-details/MessageDetailsPageWithState';
import ColleagueDetailsPageWithState from './pages/colleague-details/ColleagueDetailsPageWithState';
import MobileAppInstallPageWithState from './pages/mobile-app-install/MobileAppInstallPageWithState';
import PublicMessageDetailsPageWithState from './pages/message-details-public/PublicMessageDetailsPageWithState';
import TermsAndConditionsPageWithState from './pages/terms-and-conditions/TermsAndConditionsPageWithState';
import AdminMessageCreatePage from '../modules/AdminMessage/AdminMessageCreatePage';
import {hasWhiteLabel} from '../utils/whiteLabel';

const AppRouter = ({isLoading, isAuthenticated, ...props}) => {
  const currentCompany = useCompany();
  const currentUser = useCurrentUser();
  const {canViewCalendar, canViewColleagues, canViewTools, hasAdminInbox} =
    usePermissions();

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/login/sms">
          <LoginSmsPageWithState {...props} />
        </Route>
        <Route exact path="/login/email">
          <LoginEmailPageWithState {...props} />
        </Route>
        <Route exact path="/login/whatsapp">
          <LoginWhatsAppPageWithState {...props} />
        </Route>
        <Route exact path="/login/otp">
          <LoginOtpPageWithState {...props} />
        </Route>
        <Route exact path="/messages/:id">
          <PublicMessageDetailsPageWithState {...props} />
        </Route>
        {/* Legacy route used in mails */}
        <Route exact path="/message/:id">
          <PublicMessageDetailsPageWithState {...props} />
        </Route>
        {/* End legacy route */}
        {hasWhiteLabel(currentCompany) && (
          <Route exact path="/mobile-app-install">
            <MobileAppInstallPageWithState {...props} />
          </Route>
        )}
        <Route path="/">
          <WelcomePageWithState {...props} />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/documents">
        <DocumentsPageWithState {...props} />
      </Route>
      <Route exact path="/documents/:directoryId">
        <DocumentsPageWithState {...props} />
      </Route>
      <Route exact path="/settings">
        <ProfilePageWithState {...props} />
      </Route>
      <Route exact path="/messages">
        <MessagesPageWithState {...props} />
      </Route>
      <Route exact path="/messages/overview/:statusFilter">
        <MessagesPageWithState {...props} />
      </Route>
      <Route exact path="/messages/:id">
        <MessageDetailsPageWithState {...props} />
      </Route>
      <Route exact path="/messages/:messageId/comments">
        <CommentsPageWithState {...props} />
      </Route>
      {/* Legacy route used in mails */}
      <Route exact path="/message/:id">
        <MessageDetailsPageWithState {...props} />
      </Route>
      {/* End legacy route */}
      <Route exact path="/comments">
        <UserCommentsPageWithState {...props} />
      </Route>
      {canViewColleagues && (
        <Route exact path="/colleagues">
          <ColleaguesPageWithState {...props} />
        </Route>
      )}
      {canViewColleagues && (
        <Route exact path="/colleagues/:userId">
          <ColleagueDetailsPageWithState {...props} />
        </Route>
      )}
      {canViewCalendar && (
        <Route exact path="/calendar">
          <CalendarPageWithState {...props} />
        </Route>
      )}
      {canViewTools && (
        <Route exact path="/tools">
          <ToolsPageWithState {...props} />
        </Route>
      )}
      {hasWhiteLabel(currentCompany) && (
        <Route exact path="/mobile-app-install">
          <MobileAppInstallPageWithState {...props} />
        </Route>
      )}
      <Route exact path="/terms-and-conditions">
        <TermsAndConditionsPageWithState {...props} />
      </Route>
      {hasAdminInbox && (
        <Route exact path="/admin-message/create">
          <AdminMessageCreatePage {...props} />
        </Route>
      )}
      <Route path="/">
        {currentUser && !currentUser.acceptedTerms ? (
          <Redirect to="/terms-and-conditions" />
        ) : (
          <HomePageWithState {...props} />
        )}
      </Route>
    </Switch>
  );
};

export default AppRouter;
