import {gql} from '@apollo/client';
import DEFAULT_APP_DOMAIN from './defaultAppDomain';

const DEFAULT_MESSAGE_FRAGMENT = gql`
  fragment DefaultMessage on UserMessage {
    id
    title
    isBookmarked
    isCheered
    isRead
    cheerCount
    commentsCount
    createdAt
    updatedAt
    scheduledAt
    messageId
    acceptAnswers
    enableComments
    shareUrl
    body {
      blocks {
        key
        text
        type
        depth
        inlineStyleRanges {
          length
          offset
          style
        }
        entityRanges
        data
      }
      entityMap {
        type
        mutability
        data {
          url
        }
      }
    }
    appDomain {
      ...DefaultAppDomain
    }
    featuredImage {
      id
      name
      fileName
      mimeType
      manipulations
      orderColumn
      usedAt
      createdAt
      updatedAt
      sizes {
        full
        thumb
        small
        regular
      }
      customProperties {
        generatedConversions {
          small
          thumb
          regular
        }
      }
    }
  }
  ${DEFAULT_APP_DOMAIN}
`;

export default DEFAULT_MESSAGE_FRAGMENT;
