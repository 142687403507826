import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';
import TextAreaInput from '../../utils/InputTextArea';
import ProfileActionCard from './ProfileActionCard';

const ProfileDescription = ({
  description,
  onUpdateDescription,
  isUpdatingDescription,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(description);

  return (
    <Flex flexDirection="column" {...props}>
      <Title variant={2} mb={6}>
        <Trans>Description</Trans>
      </Title>
      {(() => {
        if (isEditing) {
          return (
            <>
              <TextAreaInput
                fontSize={2}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <Flex mt={5} alignItems="center" justifyContent="space-between">
                <Button
                  isLoading={isUpdatingDescription}
                  onClick={async () => {
                    await onUpdateDescription(value);
                    setIsEditing(false);
                  }}
                  flexGrow={1}>
                  <Title variant={4} color="white">
                    <Trans>Done</Trans>
                  </Title>
                </Button>
                <Button
                  disabled={isUpdatingDescription}
                  variant="unstyledCentered"
                  onClick={() => {
                    setValue(description);
                    setIsEditing(false);
                  }}
                  flexGrow={1}>
                  <Title variant={4} color="grey.6">
                    <Trans>Cancel</Trans>
                  </Title>
                </Button>
              </Flex>
            </>
          );
        }
        if (description === null) {
          return (
            <ProfileActionCard
              icon="info"
              actionText={<Trans>Add</Trans>}
              action={() => setIsEditing(true)}>
              <Trans id="You don't have a description on your profile yet. Tell your colleagues something more about yourself." />
            </ProfileActionCard>
          );
        }
        return (
          <>
            <Paragraph variant={1} color="grey.5">
              {description}
            </Paragraph>
            <Flex mt={6}>
              <Button variant="link1" onClick={() => setIsEditing(true)}>
                <Trans>Edit</Trans>
              </Button>
            </Flex>
          </>
        );
      })()}
    </Flex>
  );
};

export default ProfileDescription;
