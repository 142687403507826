import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from './Flex';
import Button from './Button';

const ModalConfim = ({
  isConfirming,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  ...props
}) => (
  <Flex alignItems="center" justifyContent="space-between" {...props}>
    <Button isLoading={isConfirming} onClick={onConfirm} flex={1}>
      {typeof confirmText === 'undefined' ? (
        <Trans>Confirm</Trans>
      ) : (
        confirmText
      )}
    </Button>
    <Button
      variant="unstyledCentered"
      disabled={isConfirming}
      onClick={onCancel}
      flex={1}>
      {typeof cancelText === 'undefined' ? <Trans>Cancel</Trans> : cancelText}
    </Button>
  </Flex>
);

export default ModalConfim;
