import {formatDistanceToNow as dateFnsformatDistanceToNow} from 'date-fns';
import {formatDateString as utilFormatDateString} from '../../utils/dates';
import useLocale from './useLocale';

const useLocalizedDateFunctions = language => {
  const locale = useLocale(language);

  const formatDistanceToNow = (date, options) =>
    dateFnsformatDistanceToNow(date, {...options, locale});

  const formatDateString = (dateString, format, options) =>
    utilFormatDateString(dateString, format, {
      ...options,
      locale,
    });

  return {
    formatDateString,
    formatDistanceToNow,
  };
};

export default useLocalizedDateFunctions;
