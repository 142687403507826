import React, {forwardRef, useEffect, useState, useRef} from 'react';
import {createPortal} from 'react-dom';
import useOnClickOutside from '../hooks/useOnClickOutside';
import {combineRefs} from '../../utils';

const Portal = forwardRef(
  ({el = 'div', onOutsideClick, className = 'root-portal', children}, ref) => {
    const [container] = useState(document.createElement(el));
    container.classList.add(className);
    const portalRef = useRef();

    useOnClickOutside(portalRef, () => {
      if (typeof onOutsideClick === 'function') {
        onOutsideClick();
      }
    });

    useEffect(() => {
      document.body.appendChild(container);
      return () => {
        document.body.removeChild(container);
      };
    }, [container]);

    const refs = ref !== null ? [ref, portalRef] : [portalRef];

    return createPortal(
      <div ref={combineRefs(refs)}>{children}</div>,
      container,
    );
  },
);

export default Portal;
