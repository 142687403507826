// Polyfill for CustomEvent
(function polyfillCustomEvent() {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params = {}) {
    const {bubbles, cancelable, detail} = params;
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, bubbles, cancelable, detail);
    return evt;
  }

  window.CustomEvent = CustomEvent;
  return true;
})();
