import {useQuery, gql} from '@apollo/client';

export const COMPANY_QUERY = gql`
  query companyQuery {
    company @rest(type: "Company", path: "/company", method: "GET") {
      name
      slug
      defaultCountry
      defaultLanguage
      createdAt
      updatedAt
      maxFileUpload
      countries
      languages
      branding
      settings
      roles
    }
  }
`;

export const useCompanyQuery = () => useQuery(COMPANY_QUERY);

export default async function getCompany(apollo, options) {
  const query = COMPANY_QUERY;
  const response = await apollo.query({query, ...options});
  return response.data.company;
}
