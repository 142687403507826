import React from 'react';
import {Trans} from '@lingui/macro';
import {useHistory} from 'react-router-dom';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import Header from '../../utils/Header';
import ProfilePageHeader from './ProfilePageHeaderWithState';
import ProfileDescription from './ProfileDescription';
import ProfileOtherSection from './ProfileOtherSection';
import ProfilePageContainer from './ProfilePageContainer';
import ProfileContactInformation from './ProfileContactInformation';
import ProfileNotificationSettings from './ProfileNotificationSettings';
import ProfileLanguageButtonWithState from './ProfileLanguageButtonWithState';
import Arteel, {ARTEEL} from './Arteel';
import Page, {PageContentScrollable} from '../../utils/Page';
import Icon from '../../utils/Icon';
import Title from '../../utils/Title';
import ExtraProfileButton from './ExtraProfileButton';

const ProfilePage = ({
  onLogout,
  channels,
  currentUser,
  userChannels,
  onUpdateAvatar,
  onChangeLanguage,
  isUpdatingLanguage,
  onOpenPrivacyPolicy,
  onUpdateDescription,
  onUpdateUserChannels,
  isUpdatingDescription,
  extraProfileButtons,
}) => {
  const {
    avatar,
    language,
    lastName,
    firstName,
    description,
    apiExporterUserConfigs: integrations,
  } = currentUser;
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  const wrap = children => {
    if (!children) {
      return null;
    }

    return (
      <Flex alignItems="center" flexDirection="column" my={4}>
        {children}
      </Flex>
    );
  };

  const arteelConfig = integrations?.find(
    ({driver}) => driver === ARTEEL,
  )?.config;
  const extraLinks = [];
  if (arteelConfig) {
    extraLinks.push(wrap(<Arteel config={arteelConfig} key={ARTEEL} />));
  }

  if (extraProfileButtons.length) {
    extraLinks.push(
      ...extraProfileButtons.map(buttonConfig =>
        wrap(<ExtraProfileButton {...buttonConfig} key={buttonConfig.id} />),
      ),
    );
  }
  return (
    <>
      <Header />
      <Page>
        <Flex flexDirection="row" justifyContent="space-between" pt={5} mb={3}>
          <Button
            variant="unstyled"
            onClick={handleGoBack}
            flexDirection="row"
            alignItems="center"
            pr={2}>
            <Icon name="chevronLeftLarge" strokeWidth={2.5} />
            <Title variant={4} color="primary">
              <Trans>Back</Trans>
            </Title>
          </Button>
        </Flex>
        <PageContentScrollable>
          <ProfilePageContainer>
            <ProfilePageHeader
              avatar={avatar}
              lastName={lastName}
              firstName={firstName}
              onUpdateAvatar={onUpdateAvatar}
            />
            {extraLinks}
            <ProfileDescription
              description={description}
              onUpdateDescription={onUpdateDescription}
              isUpdatingDescription={isUpdatingDescription}
              my={7}
            />
            <ProfileLanguageButtonWithState
              language={language}
              onChangeLanguage={onChangeLanguage}
              isUpdatingLanguage={isUpdatingLanguage}
              my={7}
            />
            <ProfileContactInformation user={currentUser} my={7} />
            <ProfileNotificationSettings
              channels={channels}
              userChannels={userChannels}
              onUpdateUserChannels={onUpdateUserChannels}
              user={currentUser}
              my={7}
            />
            <ProfileOtherSection
              onOpenPrivacyPolicy={onOpenPrivacyPolicy}
              my={7}
            />
            <Button bg="red" width="100%" my={7} onClick={onLogout}>
              <Trans>Logout</Trans>
            </Button>
          </ProfilePageContainer>
        </PageContentScrollable>
      </Page>
    </>
  );
};

export default ProfilePage;
