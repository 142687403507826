import {useQuery, gql} from '@apollo/client';
import DEFAULT_APP_HEALTH_FRAGMENT from '../fragments/defaultAppHealth';

export const APP_HEALTH_QUERY = gql`
  query appHealthQuery {
    appHealth
      @rest(type: "AppHealth", path: "/health-check/web", method: "GET") {
      ...DefaultAppHealth
    }
  }
  ${DEFAULT_APP_HEALTH_FRAGMENT}
`;

export const useAppHealthQuery = () => useQuery(APP_HEALTH_QUERY);

export default async function getAppHealth(apollo) {
  const query = APP_HEALTH_QUERY;
  const response = await apollo.query({query});
  return response.data.appHealth;
}
