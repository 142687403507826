import React from 'react';
import {
  TYPE_YES_NO_QUESTION,
  TYPE_HAPPINESS_POLL,
  TYPE_MULTIPLE_CHOICE,
  TYPE_RSVP,
  TYPE_OPINION_SCALE,
  TYPE_RATING,
} from '../../../utils/constants';
import SurveyRating from './SurveyRating';
import SurveyOpinionScale from './SurveyOpinionScale';
import SurveyRSVPWithState from './SurveyRSVPWithState';
import SurveyHappinessPoll from './SurveyHappinessPoll';
import SurveyYesNoQuestion from './SurveyYesNoQuestion';
import SurveyMultipleChoice from './SurveyMultipleChoice';

const SurveyBlock = ({survey, ...props}) => {
  const {type} = survey;

  switch (type) {
    case TYPE_YES_NO_QUESTION:
      return <SurveyYesNoQuestion survey={survey} {...props} />;
    case TYPE_HAPPINESS_POLL:
      return <SurveyHappinessPoll survey={survey} {...props} />;
    case TYPE_MULTIPLE_CHOICE:
      return <SurveyMultipleChoice survey={survey} {...props} />;
    case TYPE_RSVP:
      return <SurveyRSVPWithState survey={survey} {...props} />;
    case TYPE_OPINION_SCALE:
      return <SurveyOpinionScale survey={survey} {...props} />;
    case TYPE_RATING:
      return <SurveyRating survey={survey} {...props} />;
    default:
      return null;
  }
};

export default SurveyBlock;
