import React, {forwardRef} from 'react';
import {TYPE_SUCCESS, TYPE_ERROR, TYPE_WARNING} from '../../utils/constants';
import Box from './Box';
import Flex from './Flex';
import Icon from './Icon';
import Title from './Title';
import Button from './Button';
import Paragraph from './Paragraph';

const TOAST_WIDTH = '34.5rem';

const Toast = forwardRef(({data, removeNotification}, ref) => {
  const {title, message, type} = data;
  let iconType = '';

  switch (type) {
    case TYPE_SUCCESS:
      iconType = 'success';
      break;
    case TYPE_WARNING:
    case TYPE_ERROR:
      iconType = 'error';
      break;
    default:
      iconType = 'success';
  }

  return (
    <Box
      ref={ref}
      width={TOAST_WIDTH}
      bg="primary"
      p={6}
      mb={6}
      boxShadow={0}
      borderRadius={0}>
      <Flex alignItems="center" mb={3}>
        <Flex flex={1} flexDirection="row" alignItems="center">
          <Icon color="white" name={iconType} />
          <Title ml={2} color="white" variant={4}>
            {title}
          </Title>
        </Flex>
        <Button
          variant="unstyled"
          onClick={removeNotification}
          backgroundColor="transparent">
          <Icon color="white" name="cross" />
        </Button>
      </Flex>
      <Paragraph mb="none" color="white" variant={2}>
        {message}
      </Paragraph>
    </Box>
  );
});

export default Toast;
