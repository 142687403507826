import React from 'react';
import Modal from './Modal';

const Dialog = ({isOpen, children}) => (
  <Modal isOpen={isOpen} isDismissable={false}>
    {children}
  </Modal>
);

export default Dialog;
