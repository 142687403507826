import React, {useEffect} from 'react';
import copy from 'copy-to-clipboard';
import {Trans} from '@lingui/macro';
import {useApolloClient} from '@apollo/client';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {
  LIST_TYPE_UNREAD,
  LIST_TYPE_READ,
  LIST_TYPE_SAVED,
} from '../../../utils/constants';
import getReadMessages from '../../../apollo/queries/getReadMessages';
import getUnreadMessages from '../../../apollo/queries/getUnreadMessages';
import useCompany from '../../hooks/useCompany';
import useAppState from '../../hooks/useAppState';
import useNotifications from '../../hooks/useNotifications';
import PageLoading from '../../utils/PageLoading';
import ErrorPageWithState from '../error/ErrorPageWithState';
import MessageDetailsPage from './MessageDetailsPage';
import useFetchMessageDetailsScreenData from './useFetchMessageDetailsPageData';

const isInvalidMessageId = id =>
  id === LIST_TYPE_UNREAD || id === LIST_TYPE_READ || id === LIST_TYPE_SAVED;

const MessageDetailsPageWithState = () => {
  const history = useHistory();
  const {id} = useParams();
  if (isInvalidMessageId(id)) {
    history.push(`/messages/overview/${id}`);
  }
  const company = useCompany();
  const {state} = useLocation();
  const {updateBadgeCount} = useAppState();
  const apolloClient = useApolloClient();
  const {dispatchSuccess} = useNotifications();
  const {error, isFetching, userMessage} = useFetchMessageDetailsScreenData(id);

  useEffect(() => {
    if (typeof state !== 'undefined') {
      const {isRead} = state;
      if (!isFetching && !isRead) {
        getUnreadMessages(apolloClient, {fetchPolicy: 'network-only'});
        getReadMessages(apolloClient, {fetchPolicy: 'network-only'});
        updateBadgeCount();
      }
    }
  }, [state, isFetching, apolloClient, updateBadgeCount]);

  const onShareMessage = url => {
    copy(url);
    dispatchSuccess({
      message: <Trans>Copied to clipboard!</Trans>,
    });
  };

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  if (isFetching) {
    return <PageLoading />;
  }
  const goBackIfHistory = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/messages/overview/unread');
    }
  };

  return (
    <MessageDetailsPage
      message={userMessage}
      company={company}
      onGoBack={goBackIfHistory}
      onShareMessage={onShareMessage}
    />
  );
};

export default MessageDetailsPageWithState;
