import React from 'react';
import {useTheme} from 'styled-components';
import InfiniteScroll from '../../utils/InfiniteScroll';
import MessagesListItemWithState from './MessagesListItemWithState';
import Box from '../../../modules/utils/Box';
import Flex from '../../../modules/utils/Flex';

const MessagesList = ({
  userMessages,
  isFetching,
  isFetchingMore,
  hasMorePages,
  onFetchMore,
  emptyList,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box {...props}>
      <InfiniteScroll
        hasMore={hasMorePages}
        isFetchingMore={isFetchingMore || isFetching}
        onFetchMore={onFetchMore}>
        <Flex flexDirection="column" alignItems="center">
          {!isFetching && userMessages.length === 0
            ? emptyList
            : userMessages.map(userMessage => (
                <MessagesListItemWithState
                  key={userMessage.id}
                  message={userMessage}
                  width={['100%', `calc(100% - (2 * ${theme.space[7]}))`]}
                />
              ))}
        </Flex>
      </InfiniteScroll>
    </Box>
  );
};

export default MessagesList;
