export const pixelsToRem = item => `${item / 10}rem`;

export const delay = (time, value) =>
  new Promise(resolve => {
    setTimeout(resolve, time, value);
  });

export const where =
  (...keys) =>
  (...values) =>
  obj =>
    keys.every(key =>
      key.includes('!')
        ? !values.includes(obj[key.replace('!', '')])
        : values.includes(obj[key]),
    );

export const whereIsNot = key => value => obj => obj[key] !== value;

export const whereId = where('id');

export const whereIsNotId = whereIsNot('id');

export const whereSlug = where('slug');

export const whereType = where('type');

export const whereVariant = where('variant');

export const whereSize = where('size');

export const whereCode = where('code');

export const sortByLabel = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const flatten = (a, b) => [...a, ...b];

export const combineRefs =
  (refs = []) =>
  el => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = el;
      }
    });
  };

export const getSegmentsFromSegmentGroupsBySlug = (segmentGroups, slug) => {
  const segmentGroup = segmentGroups.filter(whereSlug(slug))[0];
  if (typeof segmentGroup === 'undefined') {
    return null;
  }
  return segmentGroup.segments.map(({name}) => name);
};

/**
 * Determine if the browser is on a mobile device or pc.
 * This function returns true if it's a mobile browser.
 *
 * @returns {Boolean}
 */

export function isMobileBrowser() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android' or 'unknown'.
 *
 * @returns {String}
 */

export const IOS = 'iOS';
export const ANDROID = 'Android';
export const UNKNOWN = 'unknown';

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return UNKNOWN;
}
