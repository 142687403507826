import styled from 'styled-components';
import {
  space,
  layout,
  position,
  flexbox,
  shadow,
  border,
  background,
} from 'styled-system';

const Audio = styled.audio`
  ${space}
  ${layout}
    ${position}
    ${flexbox}
    ${shadow}
    ${border}
    ${background}
`;

export default Audio;
