import {gql, useMutation} from '@apollo/client';
import DEFAULT_USER_FRAGMENT from '../../../apollo/fragments/defaultUser';

export const ACCEPT_TERMS_AND_CONDITIONS = gql`
  mutation acceptTermsAndConditions {
    acceptTermsAndConditions(input: {})
      @rest(
        type: "User"
        path: "/users/me/accept-terms-and-conditions"
        method: "POST"
      ) {
      ...DefaultUser
    }
  }
  ${DEFAULT_USER_FRAGMENT}
`;

const useAcceptTermsAndConditionsMutation = () =>
  useMutation(ACCEPT_TERMS_AND_CONDITIONS);

export default useAcceptTermsAndConditionsMutation;
