import React from 'react';
import Box from './Box';
import Flex from './Flex';
import Loader from './Loader';
import ScrollSentinel from './ScrollSentinel';

const LOADING_CONTAINER_HEIGHT = 10;

const InfiniteScroll = ({
  hasMore,
  isFetchingMore,
  onFetchMore,
  children,
  ...props
}) => (
  <Box position="relative" overflow="auto" {...props}>
    {children}
    {isFetchingMore && (
      <Flex
        height={`${LOADING_CONTAINER_HEIGHT}rem`}
        alignItems="center"
        justifyContent="center">
        <Loader color="primary" />
      </Flex>
    )}
    {!isFetchingMore && hasMore && (
      <ScrollSentinel
        height={LOADING_CONTAINER_HEIGHT}
        onIntersect={onFetchMore}
      />
    )}
  </Box>
);

export default InfiniteScroll;
