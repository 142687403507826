import {camelCase} from 'lodash';
import {IDEA_BOX, WHISTLE_BLOWER} from '../../modules/AdminMessage/constants';
import useCompany from './useCompany';
import useCustomApp from './useCustomApp';

const usePermissions = () => {
  const {is21Comms, isPulse, isWappla} = useCustomApp();
  const currentCompany = useCompany();
  if (!currentCompany.settings) return {};
  // Permissions
  const {canUseAzureAdAuth} = currentCompany.settings;
  const canUseWhatsApp = !is21Comms;
  const canUseWhatsAppAuth = currentCompany.settings.canUseWhatsappAuth;
  const {canUseSmsAuth} = currentCompany.settings;
  const {canUseEmailAuth} = currentCompany.settings;
  const canViewColleagues = !!currentCompany?.settings.showColleagues;
  const canViewTools =
    isPulse || isWappla || currentCompany.settings.showExtraTools;
  const canViewCalendar = isPulse || isWappla;
  const hasAdminInbox =
    currentCompany.settings.adminInbox?.enabled &&
    (currentCompany.settings.adminInbox?.forms?.[camelCase(IDEA_BOX)]
      ?.enabled ||
      currentCompany.settings.adminInbox?.forms?.[camelCase(WHISTLE_BLOWER)]
        ?.enabled);
  const hasAllMessagesPage = currentCompany.settings.hasOldMessagesCategory;

  return {
    canViewTools,
    canUseWhatsApp,
    canUseWhatsAppAuth,
    canUseSmsAuth,
    canUseEmailAuth,
    canViewCalendar,
    canViewColleagues,
    canUseAzureAdAuth,
    hasAdminInbox,
    hasAllMessagesPage,
  };
};

export default usePermissions;
