import {useMutation, gql} from '@apollo/client';

export const UNBOOKMARK_USER_MESSAGE_MUTATION = gql`
  mutation unbookmarkUserMessageMutation($id: ID!) {
    unbookmarkUserMessage(input: {}, id: $id)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}/unbookmark"
        method: "POST"
      ) {
      id
      isBookmarked
    }
  }
`;

export default function useBookmarkUserMessageMutation() {
  return useMutation(UNBOOKMARK_USER_MESSAGE_MUTATION);
}
