import Pusher from 'pusher-js';
import Echo from 'laravel-echo/dist/echo';
import {getAccessToken} from './storage';

let webSocketConnection = null;

export default class WebSocketConnection {
  constructor() {
    this.echo = null;
    this.isConnected = false;
  }

  static getInstance() {
    if (webSocketConnection === null) {
      webSocketConnection = new WebSocketConnection();
      return webSocketConnection;
    }
    return webSocketConnection;
  }

  async connect() {
    const {REACT_APP_API_URL, REACT_APP_WEB_SOCKET_KEY} = process.env;
    const accessToken = await getAccessToken();

    const options = {
      cluster: 'eu',
      encrypted: false,
      key: REACT_APP_WEB_SOCKET_KEY,
      disableStats: true,
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      authEndpoint: `${REACT_APP_API_URL}/broadcasting/auth`,
      activityTimeout: 55000,
    };

    const PusherClient = new Pusher(options.key, options);

    this.echo = new Echo({
      broadcaster: 'pusher',
      client: PusherClient,
      ...options,
    });
    this.echo.connector.pusher.connection.bind('disconnected', () => {
      this.isConnected = false;
    });
    this.echo.connector.pusher.connection.bind('connected', () => {
      this.isConnected = true;
    });
    return new Promise(resolve => {
      this.echo.connector.pusher.bind('connected', resolve);
    });
  }

  checkConnection() {
    if (this.echo === null || !this.isConnected) {
      throw new Error('WebSocketConnection failed');
    }
  }

  subscribeToCommentIsCreated(companySlug, messageId, listener) {
    this.addCommentIsCreatedListener(companySlug, messageId, listener);
    return () => {
      this.removeCommentCreatedListener(companySlug, messageId);
    };
  }

  addCommentIsCreatedListener(companySlug, messageId, listener) {
    this.echo
      .private(`comments.${companySlug}.${messageId}`)
      .listen('CommentCreated', response => {
        listener(response);
      });
  }

  removeCommentCreatedListener(companySlug, messageId) {
    this.echo
      .private(`comments.${companySlug}.${messageId}`)
      .stopListening('CommentCreated');
  }
}
