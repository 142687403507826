export const getFileType = type => {
  if (type) {
    switch (type) {
      case 'application/pdf':
      case 'application/vnd.cups-pdf':
      case 'application/vnd.sealedmedia.softseal-pdf':
        return 'pdf';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.openxmlformats-officedocument.presentationml.template':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
      case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
      case 'application/vnd.oasis.opendocument.presentation':
        return 'powerpoint';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.oasis.opendocument.text':
        return 'word';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      case 'application/vnd.ms-excel.template.macroEnabled.12':
      case 'application/vnd.ms-excel.addin.macroEnabled.12':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.oasis.opendocument.spreadsheet':
        return 'excel';
      default:
        if (type.lastIndexOf('image/', 0) === 0 || type === 'unsplash') {
          return 'image';
        }

        if (type.lastIndexOf('audio/', 0) === 0) {
          return 'audio';
        }

        if (type.lastIndexOf('video/', 0) === 0) {
          return 'video';
        }

        return '';
    }
  }
  return '';
};

export const getFileTypeGroup = type => {
  switch (type) {
    case 'image':
    case 'video':
    case 'audio':
      return type;
    default:
      return 'file';
  }
};
