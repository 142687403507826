import React from 'react';
import Flex from './Flex';
import Image from './Image';

const CompanyLogo = ({companyName, logoSrc = null, ...props}) => (
  <Flex flexDirection="row" alignItems="center" {...props}>
    {logoSrc !== null && <Image mr={4} maxHeight={40} src={logoSrc} />}
  </Flex>
);

export default CompanyLogo;
