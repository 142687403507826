import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import {MODEL_NOT_FOUND} from '../../../utils/constants';
import {updateFormErrors} from '../../../utils/validation';
import useNotifications from '../../hooks/useNotifications';
import useNavigateWithSearch from '../../hooks/useNavigateWithSearch';
import useRequestOtpMutation from '../login-email/useRequestOtpMutation';
import useLoginPhoneFormState from '../login-sms/useLoginPhoneFormState';
import LoginWhatsAppPage from './LoginWhatsAppPage';

const LoginWhatsAppPageWithState = () => {
  const formState = useLoginPhoneFormState(
    {},
    {
      valuesToInput: ({phone}) => ({
        whats_app: `+${phone}`,
        grant_type: 'otp',
      }),
    },
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigateWithSearch = useNavigateWithSearch();
  const [requestOtp] = useRequestOtpMutation();
  const {dispatchError} = useNotifications();

  const handleCancel = () => navigateWithSearch('/login');

  const handleSubmit = async () => {
    if (formState.validate()) {
      setIsLoading(true);
      const body = formState.valuesToInput();
      const variables = {body};
      try {
        await requestOtp({variables});
        navigateWithSearch(
          `/login/otp?driver=phone&receiver=${encodeURIComponent(body.whats_app)}`,
        );
      } catch (error) {
        const errorMessage = updateFormErrors(error, formState.updateErrors);
        if (errorMessage === MODEL_NOT_FOUND) {
          dispatchError({
            message: (
              <Trans>
                We were unable to find a user with this phone number.
              </Trans>
            ),
          });
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <LoginWhatsAppPage
      isLoading={isLoading}
      formState={formState}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default LoginWhatsAppPageWithState;
