import React, {useEffect, useRef, useState} from 'react';
import {Trans} from '@lingui/macro';
import styled from 'styled-components';
import {useUploadFileMutation} from '../../apollo/mutations/uploadFile';
import useNotifications from '../../components/hooks/useNotifications';
import useCompany from '../../components/hooks/useCompany';
import Button from '../../components/utils/Button';
import Icon from '../../components/utils/Icon';
import {useRogerFetch} from '../../components/hooks/RogerFetch';
import Box from '../../components/utils/Box';

const HiddenInput = styled.input`
  display: none;
`;

const FileListItem = styled.li`
  display: flex;
  padding: 1rem;
  gap: 1rem;
  background-color: ${({theme}) => theme.colors.grey[1]};
  align-items: center;
  justify-content: space-between;
  span {
    width: 100%;
  }
  img {
    max-width: 5rem;
    max-height: 5rem;
  }
`;
const AttachmentUploader = ({onChange, noEdit, attachments = []}) => {
  const [uploadFile] = useUploadFileMutation();
  const {dispatchError} = useNotifications();
  const {maxFileUpload} = useCompany();
  const [selectedFiles, setSelectedFiles] = useState(attachments);
  useRef(null);
  const {authFetch} = useRogerFetch();
  const deleteFile = async fileId => {
    await authFetch(`/media/items/${fileId}`, {
      method: 'DELETE',
    });
    setSelectedFiles(files => files.filter(file => file.id !== fileId));
  };
  const inputRef = useRef(null);
  const handleFileChange = async event => {
    const uploadedFiles = [];
    const {files} = event.target;
    await Promise.all(
      [...files].map(async file => {
        if (file === null) {
          return;
        }
        if (file.size > maxFileUpload) {
          dispatchError({
            message: <Trans>The file is larger than the allowed size.</Trans>,
          });
        } else {
          try {
            const {
              data: {uploadFile: uploadedFile},
            } = await uploadFile({
              variables: {
                body: {
                  mediaFile: file,
                  mediaType: 'admin_inbox',
                },
              },
            });
            uploadedFiles.push(uploadedFile);
          } catch (error) {
            dispatchError({
              message: error.message,
            });
          }
        }
      }),
    );
    setSelectedFiles(oldFiles => [...oldFiles, ...uploadedFiles]);
  };

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(selectedFiles);
    }
  }, [selectedFiles]);
  return (
    <>
      <HiddenInput
        ref={inputRef}
        type="file"
        multiple
        onChange={handleFileChange}
      />
      <ul>
        {selectedFiles.map(file => (
          <FileListItem key={file.id}>
            <Box width="6rem" textAlign="center">
              {/^(video|image)/.test(file.mimeType) ? (
                <img src={file.sizes.thumb} alt={file.name} />
              ) : (
                <Icon name="document" />
              )}
            </Box>
            <span>{file.name}</span>
            {!noEdit && (
              <Icon
                name="bin"
                onClick={() => {
                  deleteFile(file.id);
                }}
              />
            )}
          </FileListItem>
        ))}
      </ul>
      {!noEdit && (
        <Button onClick={() => inputRef.current.click()} variant="link2" mt={1}>
          + <Trans>Upload</Trans>
        </Button>
      )}
    </>
  );
};

export default AttachmentUploader;
