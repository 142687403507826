import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import EmptyList from '../../utils/EmptyList';
import PageContainer from '../../utils/PageContainer';
import InfiniteScroll from '../../utils/InfiniteScroll';
import UserComment from './UserComment';
import UserCommentsPageHeader from './UserCommentsPageHeader';
import Page from '../../utils/Page';

const UserCommentsPage = ({
  isFetching,
  isFetchingMore,
  hasMorePages,
  comments,
  editingCommentId,
  onFetchMore,
  onEditComment,
  onCheerComment,
  onDeleteComment,
  onCancelEditComment,
  onSubmitEditComment,
  onNavigateToMessage,
}) => (
  <>
    <Header />
    <Page>
      <PageContainer>
        <UserCommentsPageHeader />
        <Flex flexDirection="column" alignItems="center">
          <Box width={['100%', '66%', '50%']}>
            <InfiniteScroll
              hasMore={hasMorePages}
              isFetchingMore={isFetchingMore || isFetching}
              onFetchMore={onFetchMore}>
              <Flex flexDirection="column">
                {!isFetching && comments.length === 0 ? (
                  <EmptyList
                    icon="comments"
                    title={<Trans>No comments yet</Trans>}
                    subTitle={
                      <Trans>You have not yet commented on a message.</Trans>
                    }
                  />
                ) : (
                  comments.map(comment => (
                    <UserComment
                      key={comment.id}
                      comment={comment}
                      isEditing={editingCommentId === comment.id}
                      onCheerComment={() => onCheerComment(comment)}
                      onEditComment={() => onEditComment(comment)}
                      onDeleteComment={() => onDeleteComment(comment)}
                      onNavigateToMessage={() => onNavigateToMessage(comment)}
                      onCancelEditComment={onCancelEditComment}
                      onSubmitEditComment={value =>
                        onSubmitEditComment(comment, value)
                      }
                    />
                  ))
                )}
              </Flex>
            </InfiniteScroll>
          </Box>
        </Flex>
      </PageContainer>
    </Page>
  </>
);

export default UserCommentsPage;
