import React from 'react';
import {Editor} from 'medium-draft';
import {Trans} from '@lingui/macro';
import {createRogerEditorState} from '../../../utils/editor';
import customRenderer from '../../../utils/customRenderer';
import Box from '../../utils/Box';
import Date from '../../utils/Date';
import Heading from '../../utils/Heading';
import PublicHeader from '../../utils/PublicHeader';
import PageContainer from '../../utils/PageContainer';
import Tag from '../../../modules/utils/Tag';
import Flex from '../../../modules/utils/Flex';
import Button from '../../../modules/utils/Button';

const PublicMessageDetailsPage = ({
  language,
  message,
  mediaItems,
  company,
  onShareMessage,
}) => (
  <>
    <PublicHeader />
    <PageContainer px="none">
      <Box maxWidth={['100%', '50rem']} mx="auto">
        <Flex justifyContent="space-between" my={7} px={2}>
          <Box>
            <Heading variant={1} mb={2}>
              {message.title}
            </Heading>
            {message.scheduledAt && (
              <Tag variant={1}>
                <Date language={language} value={message.scheduledAt} />
              </Tag>
            )}
          </Box>
          {message.shareUrl !== null && (
            <Button
              variant="link1"
              onClick={() => onShareMessage(message.shareUrl)}
              pt={4}>
              <Trans>Share</Trans>
            </Button>
          )}
        </Flex>
        <Editor
          editorState={createRogerEditorState(message.body)}
          onChange={() => {}}
          editorEnabled={false}
          placeholder=""
          rendererFn={(setEditorState, getEditorState) => contentBlock =>
            customRenderer(
              setEditorState,
              getEditorState,
              contentBlock,
              message.id,
              message.acceptAnswers,
              company,
              true,
              mediaItems,
            )
          }
        />
      </Box>
    </PageContainer>
  </>
);

export default PublicMessageDetailsPage;
