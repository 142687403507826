import React from 'react';
import Flex from './Flex';
import Icon from './Icon';
import Title from './Title';
import Paragraph from './Paragraph';
import Button from './Button';

const EmptyList = ({
  icon,
  title,
  subTitle,
  action = null,
  actionText,
  ...props
}) => (
  <Flex
    height="50vh"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    {...props}>
    <Icon name={icon} size="larger" />
    <Title variant={3} m={3} textAlign="center">
      {title}
    </Title>
    <Paragraph variant={2} mx={3} textAlign="center">
      {subTitle}
    </Paragraph>
    {action !== null && (
      <Button variant="link1" mt={6} onClick={action}>
        {actionText}
      </Button>
    )}
  </Flex>
);

export default EmptyList;
