import React from 'react';
import Box from '../utils/Box';
import Tag from '../utils/Tag';
import Flex from '../utils/Flex';
import Image from '../utils/Image';
import Title from '../utils/Title';
import Button from '../utils/Button';
import Paragraph from '../utils/Paragraph';
import Icon from '../../components/utils/Icon';

const EmbedBlock = ({url, data = {}, onOpenMedia}) => {
  const embed = {
    title: data.title,
    description: data.description,
  };
  if (typeof data.og !== 'undefined' && data.og !== null) {
    const thumbnail = /(http|https)/.test(data.og.image)
      ? data.og.image
      : `https:${data.og.image}`;
    embed.thumbnail = thumbnail;
    embed.siteName = data.og.siteName;
    embed.title = data.og.title;
    embed.description = data.og.description;
    embed.type = data.og.type;
  }
  const isVideo =
    typeof embed.type !== 'undefined' &&
    embed.type !== null &&
    embed.type.includes('video');

  return (
    <Button
      onClick={onOpenMedia}
      variant="unstyled"
      display="flex"
      flexDirection="column"
      maxWidth="100%">
      {typeof embed.thumbnail !== 'undefined' && (
        <Box position="relative">
          <Image
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            resizeMode="cover"
            src={embed.thumbnail}
          />
          {isVideo && (
            <Flex
              position="absolute"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              top={0}
              left={0}>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                width={48}
                height={48}
                bg="primary"
                borderRadius={1}>
                <Icon name="play" color="white" size="large" />
              </Flex>
            </Flex>
          )}
        </Box>
      )}
      <Flex p={6} flexDirection="column">
        <Flex flexDirection="row" mb={3}>
          <Title mr={6} flex={1} variant={3} textAlign="left">
            {embed.title}
          </Title>
          {embed.siteName && <Tag>{embed.siteName.toUpperCase()}</Tag>}
        </Flex>
        <Paragraph variant={2} textAlign="left">
          {embed.description || url}
        </Paragraph>
      </Flex>
    </Button>
  );
};

export default EmbedBlock;
