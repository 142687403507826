import React, {useCallback, useEffect, useMemo, useState} from 'react';

import styled from 'styled-components';
import {Trans} from '@lingui/macro';
import useFetchSegmentGroups from './useFetchSegmentGroups';
import useCurrentUser from '../../hooks/useCurrentUser';
import Box from '../../utils/Box';
import Select from '../../utils/Select';
import Button from '../../utils/Button';
import Flex from '../../utils/Flex';
import Heading from '../../utils/Heading';

const Wrapper = styled(Box)`
  width: 100%;
  background-color: #e2e8f0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
  grid-gap: 1rem;
  border-radius: ${({theme}) => theme.radii[0]};
  transition: 0.4s ease all;
  ${({isOpened}) =>
    isOpened
      ? `
  `
      : `
    height: 0;
    opacity: 0;
    margin-top: -2.4rem;
    pointer-events: none;
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const mapFilterToSelect = currentLanguage => segmentGroup => ({
  label: segmentGroup?.translations?.[currentLanguage] || segmentGroup?.slug,
  value: segmentGroup,
});

const ColleaguesFilters = ({onChangeFilters}) => {
  const {isFetching, departments, locations, functions} =
    useFetchSegmentGroups();

  const [filters, setFilters] = useState({
    functions: [],
    locations: [],
    departments: [],
  });
  const [showFilters, setShowFilters] = useState(false);
  const currentUser = useCurrentUser();
  const filterMapper = useCallback(mapFilterToSelect(currentUser.language), [
    currentUser.language,
  ]);
  const filterCount = useMemo(
    () => Object.values(filters).flat().length,
    [filters],
  );
  useEffect(() => {
    onChangeFilters(Object.values(filters).flat());
  }, [filters]);
  if (isFetching) return null;
  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline">
        <Heading flex={1} variant={1}>
          <Trans>Colleagues</Trans>
        </Heading>
        <Button variant="link2" onClick={() => setShowFilters(v => !v)} mb={4}>
          {showFilters ? (
            <Trans>Hide filters</Trans>
          ) : (
            <>
              <Trans>Show filters</Trans>{' '}
            </>
          )}
          {filterCount > 0 && ` (${filterCount})`}
        </Button>
      </Flex>
      <Wrapper py={4} px={6} mb={4} isOpened={showFilters}>
        {!!departments.length && (
          <Column>
            <Select
              id="departments-filter"
              label={<Trans>Departments</Trans>}
              onChange={(newFilters = []) =>
                setFilters(oldFilters => ({
                  ...oldFilters,
                  departments: newFilters.map(({value}) => value),
                }))
              }
              isMulti
              options={departments.map(filterMapper)}
            />
          </Column>
        )}
        {!!locations.length && (
          <Select
            classNamePrefix="select"
            isMulti
            label={<Trans>Locations</Trans>}
            name="locations-filter"
            options={locations.map(filterMapper)}
            onChange={(newFilters = []) =>
              setFilters(oldFilters => ({
                ...oldFilters,
                locations: newFilters.map(({value}) => value),
              }))
            }
          />
        )}
        {!!functions.length && (
          <Select
            classNamePrefix="select"
            isMulti
            label={<Trans>Functions</Trans>}
            options={functions.map(filterMapper)}
            onChange={(newFilters = []) =>
              setFilters(oldFilters => ({
                ...oldFilters,
                functions: newFilters.map(({value}) => value),
              }))
            }
          />
        )}
      </Wrapper>
    </>
  );
};
export default ColleaguesFilters;
