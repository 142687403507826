import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Heading from '../../utils/Heading';
import ExternalCalendar from '../../utils/ExternalCalendar';

const HomePageCalendar = ({...props}) => (
  <Box {...props}>
    <Heading mb={6} variant={2}>
      <Trans>Calendar</Trans>
    </Heading>
    <ExternalCalendar height="50vh" borderRadius={0} />
  </Box>
);

export default HomePageCalendar;
