import {gql} from '@apollo/client';

const DEFAULT_SURVEY_FRAGMENT = gql`
  fragment DefaultSurvey on Survey {
    id
    type
    content {
      title
      question
      description
      location
      dateTime
    }
    options {
      label
      value
      type
    }
    response {
      id
      option {
        label
        value
        answer
      }
      optionPublished {
        label
        value
        answer
      }
    }
    createdAt
    updatedAt
  }
`;

export default DEFAULT_SURVEY_FRAGMENT;
