import React from 'react';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import SurveyBlockHeader from './SurveyBlockHeader';

const SurveyYesNoQuestion = ({
  survey,
  onChange = null,
  selectedOption,
  isPublished,
  isPublishing,
}) => {
  const {content, options} = survey;
  return (
    <>
      <SurveyBlockHeader
        question={content.question}
        description={content.description}
        isPublished={isPublished}
        isPublishing={isPublishing}
      />
      <Flex flexDirection="row" mx={-2} my={3} pb={6} px={6}>
        {options.map(option => {
          const isSelected =
            selectedOption && selectedOption.value === option.value;
          if (isSelected) {
            return (
              <Button
                key={option.value}
                onClick={() => onChange(option)}
                disabled={onChange === null}
                flex={1}
                fontFamily="bold"
                fontSize={2}
                mx={2}>
                {option.label}
              </Button>
            );
          }
          return (
            <Button
              key={option.value}
              onClick={() => onChange(option)}
              disabled={onChange === null}
              variant="link1Outline"
              mx={2}>
              {option.label}
            </Button>
          );
        })}
      </Flex>
    </>
  );
};

export default SurveyYesNoQuestion;
