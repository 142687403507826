import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {QueryParamProvider} from 'use-query-params';
import {BrowserRouter} from 'react-router-dom/cjs/react-router-dom';
import EditorStyles from '../modules/editor/styles/EditorStyles';
import ToastMessages from './utils/ToastMessages';
import ThemeProvider from './providers/ThemeProvider';
import LinguiProvider from './providers/LinguiProvider';
import LanguageProvider from './providers/LanguageProvider';
import NotificationsProvider from './providers/NotificationsProvider';
import WebSocketConnectionProvider from './providers/WebSocketConnectionProvider';
import AppGlobalStyles from './AppGlobalStyles';
import AppErrorBoundary from './AppErrorBoundary';
import DialogProvider from './providers/DialogProvider';
import {RogerFetchProvider} from './hooks/RogerFetch';
import {FloatingActionButtonProvider} from './hooks/useFloatingActionButton';

const AppProviders = ({
  activeLanguage,
  apolloClient,
  notificationDispatcher,
  webSocketConnection,
  children,
}) => (
  <ApolloProvider client={apolloClient}>
    <RogerFetchProvider>
      <NotificationsProvider dispatcher={notificationDispatcher}>
        <WebSocketConnectionProvider webSocketConnection={webSocketConnection}>
          <ThemeProvider>
            <BrowserRouter>
              <LanguageProvider activeLanguage={activeLanguage}>
                <LinguiProvider>
                  <DialogProvider>
                    <QueryParamProvider>
                      <AppGlobalStyles />
                      <EditorStyles />
                      <AppErrorBoundary>
                        <FloatingActionButtonProvider>
                          {children}
                        </FloatingActionButtonProvider>
                      </AppErrorBoundary>
                      <ToastMessages />
                    </QueryParamProvider>
                  </DialogProvider>
                </LinguiProvider>
              </LanguageProvider>
            </BrowserRouter>
          </ThemeProvider>
        </WebSocketConnectionProvider>
      </NotificationsProvider>
    </RogerFetchProvider>
  </ApolloProvider>
);

export default AppProviders;
