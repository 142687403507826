import {useReducer} from 'react';

const MAX_ATTEMPTS = 5;

export const STATUS_INITIAL = 'INITIAL';
export const STATUS_FAILURE = 'FAILURE';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_LOADING = 'LOADING';

const UPDATE_STATUS = 'UPDATE_STATUS';
const UPDATE_SOURCE = 'UPDATE_SOURCE';
const UPDATE_ATTEMPTS = 'UPDATE_ATTEMPTS';

const reducer = (state, action) => {
  const {type} = action;
  switch (type) {
    case UPDATE_STATUS: {
      const {status} = action;
      return {
        ...state,
        status,
      };
    }
    case UPDATE_ATTEMPTS: {
      const {src} = action;
      const {attempts} = state;
      const updatedAttempts = attempts + 1;
      return {
        ...state,
        attempts: updatedAttempts,
        status: STATUS_LOADING,
        src: `${src}#attempt${updatedAttempts}`,
      };
    }
    case UPDATE_SOURCE:
      return {
        ...state,
        src: action.src,
        status: STATUS_SUCCESS,
      };
    default:
      throw new Error(`Action not defined: ${type}`);
  }
};

const useImageLoadReducer = initialState => {
  const [state, dispatch] = useReducer(reducer, {
    src: null,
    attempts: 0,
    status: STATUS_INITIAL,
    ...initialState,
  });

  const handleUpdateSource = src => {
    dispatch({
      src,
      type: UPDATE_SOURCE,
    });
  };

  const handleUpdateAttempts = src => {
    if (state.attempts < MAX_ATTEMPTS) {
      setTimeout(
        () => {
          dispatch({
            src,
            type: UPDATE_ATTEMPTS,
          });
        },
        500 + state.attempts * 500,
      );
    } else {
      dispatch({
        type: UPDATE_STATUS,
        status: STATUS_FAILURE,
      });
    }
  };

  return {
    ...state,
    isFailed: state.status === STATUS_FAILURE,
    isLoading: state.status === STATUS_LOADING,
    handleUpdateSource,
    handleUpdateAttempts,
  };
};

export default useImageLoadReducer;
