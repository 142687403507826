import React from 'react';
import QRCode from 'react-qr-code';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Paragraph from '../../utils/Paragraph';
import useCompany from '../../hooks/useCompany';

const MobileAppInstallDesktop = () => {
  const {branding} = useCompany();
  return (
    <>
      <Paragraph mb={6} variant={2}>
        <Trans>
          To install the mobile app you need to open this link on a mobile
          device. You can scan the QR code below to open this link on your
          mobile phone.
        </Trans>
      </Paragraph>
      <Flex alignItems="center" justifyContent="center">
        <QRCode
          size="200"
          fgColor={branding.colors.brand.base}
          value={window.location.href}
        />
      </Flex>
    </>
  );
};

export default MobileAppInstallDesktop;
