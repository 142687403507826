import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Heading from '../../utils/Heading';

const UserCommentsPageHeader = ({...props}) => (
  <Flex mb={6} flex={1} alignItems="center" {...props}>
    <Heading flex={1} variant={1}>
      <Trans>My comments</Trans>
    </Heading>
  </Flex>
);

export default UserCommentsPageHeader;
