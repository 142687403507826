import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import DEFAULT_APP_DOMAIN from '../fragments/defaultAppDomain';
import {
  DEFAULT_LIST_PAGE,
  DEFAULT_PAGE_LIMIT,
  DEFAULT_SORT,
} from '../../utils/constants';


const DEFAULT_VARIABLES = {
  page: DEFAULT_LIST_PAGE,
  limit: DEFAULT_PAGE_LIMIT,
  isBookmarked: 1,
  sort: DEFAULT_SORT,
  query: '',
};

export const BOOKMARKS_QUERY = gql`
  query userMessagesQuery(
    $page: Int
    $limit: Int
    $isBookmarked: Boolean
    $appDomain: [String]
    $query: String
    $sort: String
  ) {
    userMessages(
      page: $page
      limit: $limit
      is_bookmarked: $isBookmarked
      app_domain: $appDomain
      query: $query
      sort: $sort
    )
      @rest(
        type: "UserMessages"
        path: "/user-messages?{args}"
        method: "GET"
      ) {
      data {
        id
        title
        isBookmarked
        isCheered
        isRead
        cheerCount
        commentsCount
        createdAt
        updatedAt
        scheduledAt
        messageId
        acceptAnswers
        enableComments
        body {
          blocks {
            key
            text
            type
            depth
            entityRanges
            data
          }
          entityMap {
            type
            mutability
            data {
              url
            }
          }
        }
        appDomain {
          ...DefaultAppDomain
        }
        featuredImage {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            small
            regular
          }
          customProperties {
            generatedConversions {
              small
              thumb
              regular
            }
          }
        }
      }
      meta {
        currentPage
        from
        lastPage
        path
        perPage
        to
        total
      }
    }
  }
  ${DEFAULT_APP_DOMAIN}
`;

export const useBookmarksQuery = ({appDomainsFilter, query, options = {}}) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  let appDomain;
  if (appDomainsFilter && appDomainsFilter.length > 0) {
    appDomain = appDomainsFilter.map(({slug}) => slug).join();
  }
  const variables = {
    ...DEFAULT_VARIABLES,
    appDomain,
    query,
  };
  const {
    error,
    loading: isFetching,
    data,
    fetchMore,
    refetch,
    updateQuery,
  } = useQuery(BOOKMARKS_QUERY, {variables, ...options});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      userMessages: [],
    };
  }

  const {
    userMessages: {
      data: userMessages,
      meta: {currentPage, lastPage},
    },
  } = data;

  const hasMorePages = currentPage < lastPage;
  const handleFetchMore = async () => {
    if (!isFetchingMore && hasMorePages) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            userMessages: {
              ...fetchMoreResult.userMessages,
              data: [
                ...prev.userMessages.data,
                ...fetchMoreResult.userMessages.data,
              ],
            },
          };
        },
      });
      setIsFetchingMore(false);
    }
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  return {
    isFetching,
    isFetchingMore,
    isRefetching,
    hasMorePages,
    fetchMore: handleFetchMore,
    refetch: handleRefetch,
    userMessages,
    updateQuery,
  };
};

export default async function getBookmarks(apollo, options) {
  const query = BOOKMARKS_QUERY;
  const variables = DEFAULT_VARIABLES;
  const response = await apollo.query({query, variables, ...options});
  return response.data.userMessages.data;
}
