import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';
import getUserComments from '../../../apollo/queries/getUserComments';
import useCompany from '../../hooks/useCompany';
import useModalState from '../../hooks/useModalState';
import useCurrentUser from '../../hooks/useCurrentUser';
import useWebSocketConnection from '../../hooks/useWebSocketConnection';
import ErrorPageWithState from '../error/ErrorPageWithState';
import DeleteCommentModalWithState from '../user-comments/DeleteCommentModalWithState';
import CommentsPage from './CommentsPage';
import useCheerCommentMutation from './useCheerCommentMutation';
import useFetchCommentPageData from './useFetchCommentsPageData';
import useCreateCommentMutation from './useCreateCommentMutation';
import useUpdateCommentMutation from './useUpdateCommentMutation';
import useUncheerCommentMutation from './useUncheerCommentMutation';

const CommentsPageWithState = () => {
  const history = useHistory();
  const {messageId} = useParams();
  const currentUser = useCurrentUser();
  const apolloClient = useApolloClient();
  const deleteModalState = useModalState();
  const {slug: companySlug} = useCompany();
  const webSocketConnection = useWebSocketConnection();
  const [editingCommentId, setEditingCommentId] = useState(null);

  const [cheerComment] = useCheerCommentMutation();
  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [uncheerComment] = useUncheerCommentMutation();

  const {
    error,
    isFetching,
    isFetchingMore,
    hasMorePages,
    userMessage,
    userMessageComments,
    handleRefetch,
    handleFetchMore,
    updateQuery,
  } = useFetchCommentPageData(messageId);

  const handleEditComment = comment => setEditingCommentId(comment.id);

  const handleCancelEditComment = () => setEditingCommentId(null);

  const handleSubmitEditComment = async (comment, text) => {
    const variables = {
      messageId,
      commentId: comment.id,
      input: {text},
    };
    const {data} = await updateComment({variables});
    const {updateComment: updatedComment} = data;
    updateQuery(
      ({comments: cachedComments, userMessage: cachedUserMessage}) => ({
        comments: {
          ...cachedComments,
          data: cachedComments.data.map(cachedComment => {
            if (cachedComment.id === updatedComment.id) {
              return updatedComment;
            }
            return cachedComment;
          }),
        },
        userMessage: cachedUserMessage,
      }),
    );
    setEditingCommentId(null);
  };

  const handleDeleteComment = comment => {
    deleteModalState.open({comment, userMessage});
  };

  const handleDeleteComplete = () => {
    handleRefetch();
    getUserComments(apolloClient, {fetchPolicy: 'network-only'});
  };

  const handleCheerComment = async comment => {
    const {isCheered} = comment;
    const variables = {commentId: comment.id, messageId: userMessage.id};
    if (isCheered) {
      uncheerComment({variables});
    } else {
      cheerComment({variables});
    }
  };

  const handleSubmitCreateComment = async text => {
    const variables = {body: {text}, messageId};
    const {data} = await createComment({variables});
    const {createComment: newComment} = data;
    updateQuery(
      ({comments: cachedComments, userMessage: cachedUserMessage}) => ({
        comments: {
          ...cachedComments,
          data: [newComment].concat(cachedComments.data),
        },
        userMessage: {
          ...cachedUserMessage,
          commentsCount: cachedUserMessage.commentsCount + 1,
        },
      }),
    );
    getUserComments(apolloClient, {fetchPolicy: 'network-only'});
  };

  const handleGoBack = () =>
    history.push(`/messages/${userMessage.id}`, {isRead: userMessage.isRead});

  useEffect(() => {
    if (typeof userMessage.messageId !== 'undefined') {
      webSocketConnection.subscribeToCommentIsCreated(
        companySlug,
        userMessage.messageId,
        () => {
          handleRefetch();
        },
      );
    }
  }, [companySlug, userMessage?.messageId]);

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  return (
    <>
      <CommentsPage
        isFetching={isFetching}
        isFetchingMore={isFetchingMore}
        hasMorePages={hasMorePages}
        currentUser={currentUser}
        comments={userMessageComments}
        editingCommentId={editingCommentId}
        onGoBack={handleGoBack}
        onFetchMore={handleFetchMore}
        onEditComment={handleEditComment}
        onCheerComment={handleCheerComment}
        onDeleteComment={handleDeleteComment}
        onSubmitEditComment={handleSubmitEditComment}
        onCancelEditComment={handleCancelEditComment}
        onSubmitCreateComment={handleSubmitCreateComment}
      />
      {deleteModalState.isOpen && (
        <DeleteCommentModalWithState
          onClose={() => deleteModalState.close()}
          onDeleteComplete={handleDeleteComplete}
          {...deleteModalState}
        />
      )}
    </>
  );
};

export default CommentsPageWithState;
