import * as React from 'react';

const IdeaIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="#fff"
      d="M7.48 14.63H4.6c-.391 0-.708.8-.708 1.786v1.973c0 .986 1.756 1.074 2.147 1.074.392 0 2.148-.088 2.148-1.074v-1.973c0-.986-.317-1.785-.709-1.785Z"
    />
    <path
      fill="#64748B"
      fillRule="evenodd"
      d="M4.6 15.352c-.098.246-.17.622-.17 1.064v1.973c0 .063.018.113.093.177.09.077.241.153.45.215.415.124.893.145 1.067.145.175 0 .652-.021 1.068-.145.209-.062.36-.138.45-.215.074-.064.093-.114.093-.177v-1.973c0-.442-.072-.818-.17-1.064a.892.892 0 0 0-.094-.184H4.694a.892.892 0 0 0-.095.184Zm-.666-.948a.917.917 0 0 1 .667-.31H7.48c.295 0 .52.153.668.31.146.156.253.353.332.552.16.4.246.917.246 1.46v1.973c0 .431-.2.762-.467.991-.252.217-.565.348-.844.43-.56.167-1.157.19-1.374.19-.217 0-.813-.023-1.373-.19-.28-.082-.592-.213-.844-.43a1.282 1.282 0 0 1-.467-.99v-1.974c0-.543.086-1.06.245-1.46.08-.199.186-.396.333-.552Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M11.41 6.098C11.41 3.075 8.963.63 5.962.672 3.072.712.711 3.098.672 6.022a5.44 5.44 0 0 0 1.737 4.074 3.345 3.345 0 0 1 1.05 2.455v1.317c0 .48.384.868.859.868h3.444c.475 0 .86-.388.86-.868V12.55c0-.933.378-1.83 1.056-2.462a5.437 5.437 0 0 0 1.731-3.99Z"
    />
    <path
      fill="#64748B"
      fillRule="evenodd"
      d="M5.954 0c3.383-.047 6.127 2.708 6.127 6.098 0 1.771-.75 3.367-1.945 4.481a2.693 2.693 0 0 0-.844 1.971v1.318c0 .844-.678 1.54-1.53 1.54H4.318c-.852 0-1.53-.696-1.53-1.54v-1.317c0-.753-.302-1.468-.835-1.963A6.11 6.11 0 0 1 0 6.012C.045 2.732 2.692.047 5.954.001Zm4.784 6.098c0-2.656-2.146-4.792-4.765-4.755-2.522.035-4.595 2.121-4.63 4.688a4.768 4.768 0 0 0 1.523 3.573A4.016 4.016 0 0 1 4.13 12.55v1.317c0 .116.09.197.188.197h3.444a.192.192 0 0 0 .188-.197V12.55c0-1.113.452-2.189 1.27-2.953a4.767 4.767 0 0 0 1.518-3.499Z"
      clipRule="evenodd"
    />
    <path
      fill="#64748B"
      fillRule="evenodd"
      d="M6.846 2.819c0-.148.12-.269.268-.269.65 0 1.392.47 1.89 1.11.506.652.818 1.55.512 2.465a.268.268 0 1 1-.509-.17c.232-.695.006-1.408-.427-1.965-.442-.568-1.043-.903-1.466-.903a.268.268 0 0 1-.268-.268Z"
      clipRule="evenodd"
    />
    <path
      stroke="#64748B"
      strokeLinecap="round"
      strokeWidth={0.75}
      d="M4.966 16.376h2.148M4.966 17.852h2.148"
    />
  </svg>
);
export default IdeaIcon;
