import React, {useEffect, useState, useContext} from 'react';
import {Trans} from '@lingui/macro';
import {TYPE_ERROR, TYPE_SUCCESS, TYPE_WARNING} from '../../utils/constants';
import {NotificationsContext} from '../providers/NotificationsProvider';

const useNotifications = () => {
  const notificationDispatcher = useContext(NotificationsContext);
  const [notifications, setNotifications] = useState([]);
  useEffect(
    () =>
      notificationDispatcher.subscribe(newNotifications => {
        setNotifications(newNotifications);
      }),
    [notificationDispatcher],
  );

  const dispatchSuccess = (data = {}) =>
    notificationDispatcher.dispatch({
      type: TYPE_SUCCESS,
      title: <Trans>Successful</Trans>,
      message: <Trans>Your request was handled successfully.</Trans>,
      ...data,
    });

  const tryParse = obj => {
    try {
      return JSON.parse(obj);
    } catch {
      return obj;
    }
  };
  const dispatchError = (data = {}) => {
    notificationDispatcher.dispatch({
      type: TYPE_ERROR,
      title: <Trans>Warning</Trans>,
      message: (
        <Trans>
          Oops, looks like something went wrong, please try again later.
        </Trans>
      ),
      ...tryParse(data),
    });
  };

  const dispatchWarning = (data = {}) =>
    notificationDispatcher.dispatch({
      ...data,
      type: TYPE_WARNING,
    });

  const removeNotification = notification => {
    notificationDispatcher.removeNotification(notification);
  };

  return {
    notifications,
    dispatchError,
    dispatchWarning,
    dispatchSuccess,
    dispatch: data => notificationDispatcher.dispatch(data),
    removeNotification,
  };
};

export default useNotifications;
