import React, {useRef, useEffect} from 'react';
import Video from '../../utils/Video';

const VideoBlock = ({src, ...props}) => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.setAttribute('playsInline', true);
  }, []);

  return (
    <Video
      ref={videoRef}
      controls
      src={src}
      width="100%"
      whiteSpace="normal"
      {...props}
    />
  );
};

export default VideoBlock;
