import React from 'react';
import FilterTags from '../../utils/FilterTags';
import InputSearch from '../../utils/InputSearch';
import MessagesList from './MessagesList';
import MessagesFilters from './MessagesFilters';
import Flex from '../../../modules/utils/Flex';

const MessagesPageWithFilters = ({
  userMessages,
  isFetching,
  isFetchingMore,
  hasMorePages,
  onFetchMore,
  emptyList,
  selectedAppDomains,
  search,
  onChangeSearch,
  onRemoveSelectedAppDomain,
}) => (
  <>
    <InputSearch value={search} onChange={onChangeSearch} mb={6} />
    <FilterTags
      filters={selectedAppDomains}
      onRemoveFilter={onRemoveSelectedAppDomain}
      display={['block', 'none']}
      mb={6}
    />
    <Flex mt={7}>
      <MessagesFilters
        disabled={isFetching}
        display={['none', 'block']}
        width="25%"
      />
      <MessagesList
        userMessages={userMessages}
        isFetching={isFetching}
        isFetchingMore={isFetchingMore}
        hasMorePages={hasMorePages}
        onFetchMore={onFetchMore}
        emptyList={emptyList}
        width={['100%', '75%', '50%']}
      />
    </Flex>
  </>
);

export default MessagesPageWithFilters;
