import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Heading from '../../utils/Heading';
import Link from '../../utils/Link';
import Flex from '../../utils/Flex';
import FileListItem from '../documents/FileListItem';

const HomePageDocuments = ({documents, onOpenFile, ...props}) => (
  <Box {...props}>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mb={6}>
      <Heading variant={2}>
        <Trans>New documents</Trans>
      </Heading>
      <Link variant={1} to="/documents">
        <Trans>View all</Trans>
      </Link>
    </Flex>
    {documents.map(document => (
      <FileListItem
        key={document.id}
        file={document}
        onOpenFile={() => onOpenFile(document)}
      />
    ))}
  </Box>
);

export default HomePageDocuments;
