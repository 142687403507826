export const THUMB = 'thumb';
export const SMALL = 'small';
export const REGULAR = 'regular';
export const FULL = 'full';

const getUrlBySize = (object, size) => {
  if (!object || typeof !object === 'object') {
    return '';
  }

  if (object.sizes && object.sizes[size]) {
    return object.sizes[size];
  }
  return '';
};

export default getUrlBySize;
