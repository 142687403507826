import React from 'react';
import {useHistory} from 'react-router-dom';
import MessagesListItem from './MessagesListItem';

const MessagesListItemWithState = ({message, ...props}) => {
  const history = useHistory();

  return (
    <MessagesListItem
      message={message}
      onNavigateToMessage={() =>
        history.push(`/messages/${message.id}`, {isRead: message.isRead})
      }
      {...props}
    />
  );
};

export default MessagesListItemWithState;
