import React from 'react';
import {useTheme} from 'styled-components';

const Sad = ({fill}) => (
  <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 46a22 22 0 1 1 22-22 22.025 22.025 0 0 1-22 22z" fill={fill} />
    <path
      d="M14 25a3 3 0 1 1 0-5.999A3 3 0 0 1 14 25zM34 25a3 3 0 1 1 0-5.999A3 3 0 0 1 34 25zM30.942 39a1 1 0 0 1-.868-.5 6.99 6.99 0 0 0-12.148 0 1 1 0 1 1-1.735-.995 8.99 8.99 0 0 1 15.618 0A1 1 0 0 1 30.942 39z"
      fill="#444"
    />
  </svg>
);

const Neutral = ({fill}) => (
  <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 46a22 22 0 1 1 22-22 22.025 22.025 0 0 1-22 22z" fill={fill} />
    <path
      d="M31 37H17a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2zM14 25a3 3 0 1 1 0-5.999A3 3 0 0 1 14 25zM34 25a3 3 0 1 1 0-5.999A3 3 0 0 1 34 25z"
      fill="#444"
    />
  </svg>
);

const Happy = ({fill}) => (
  <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 46a22 22 0 1 1 22-22 22.025 22.025 0 0 1-22 22z" fill={fill} />
    <path
      d="M14 25a3 3 0 1 1 0-5.999A3 3 0 0 1 14 25zM34 25a3 3 0 1 1 0-5.999A3 3 0 0 1 34 25zM24 39a9.023 9.023 0 0 1-7.809-4.537 1 1 0 0 1 1.735-.994 6.99 6.99 0 0 0 12.148 0 1 1 0 0 1 1.735.994A9.023 9.023 0 0 1 24 39z"
      fill="#444"
    />
  </svg>
);

const VeryHappy = ({fill, isSelected}) => {
  const theme = useTheme();
  return (
    <svg width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 46a22 22 0 1 1 22-22 22.025 22.025 0 0 1-22 22z"
        fill={fill}
      />
      <path
        d="M30 23a1 1 0 0 0 1-1 2 2 0 0 1 4 0 1 1 0 0 0 2 0 4 4 0 1 0-8 0 1 1 0 0 0 1 1zM18 23a1 1 0 0 1-1-1 2 2 0 0 0-4 0 1 1 0 0 1-2 0 4 4 0 1 1 8 0 1 1 0 0 1-1 1z"
        fill="#444"
      />
      <path
        d="M35.6 32c.26-.979.395-1.987.4-3a1 1 0 0 0-1-1H13a1 1 0 0 0-1 1c.004 1.013.137 2.02.395 3H35.6z"
        fill="#fff"
      />
      <path
        d="M12.4 32a11.981 11.981 0 0 0 23.2 0H12.4z"
        fill={isSelected ? '#AE453E' : theme.colors.grey[6]}
      />
      <path
        d="M18.014 39.385a11.9 11.9 0 0 0 11.972 0 6.985 6.985 0 0 0-11.972 0z"
        fill={isSelected ? '#FA645A' : theme.colors.grey[4]}
      />
    </svg>
  );
};

const Mood = ({value, isSelected}) => {
  const theme = useTheme();
  const fillColor = isSelected ? '#FFD764' : theme.colors.grey[2];
  switch (value) {
    case 0:
      return <Sad fill={fillColor} />;
    case 1:
      return <Neutral fill={fillColor} />;
    case 2:
      return <Happy fill={fillColor} />;
    case 3:
      return <VeryHappy isSelected={isSelected} fill={fillColor} />;
    default:
      return null;
  }
};

export default Mood;
