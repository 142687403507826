import React, {useEffect} from 'react';
import copy from 'copy-to-clipboard';
import {Trans} from '@lingui/macro';
import {useParams, useHistory} from 'react-router-dom';
import PageLoading from '../../utils/PageLoading';
import useAppState from '../../hooks/useAppState';
import useNotifications from '../../hooks/useNotifications';
import ErrorPageWithState from '../error/ErrorPageWithState';
import PublicMessageDetailsPage from './PublicMessageDetailsPage';
import useFetchPublicMessageDetailsPageData from './useFetchPublicMessageDetailsPageData';

const PublicMessageDetailsPageWithState = () => {
  const {id} = useParams();
  const history = useHistory();
  const {currentCompany} = useAppState();
  const {dispatchSuccess, dispatchError} = useNotifications();
  const {error, isFetching, userMessage, mediaItems} =
    useFetchPublicMessageDetailsPageData({variables: {id}});

  useEffect(() => {
    if (typeof error !== 'undefined') {
      // If a user is unauthenticated and visits a personal message (when it's bookmarked for
      // example) it gets treated like a public message and ends up here,
      // throwing 403 forbidden.
      if (
        typeof error.networkError !== 'undefined' &&
        error.networkError.statusCode === 403
      ) {
        // eslint-disable-next-line max-len
        dispatchError({
          message: (
            <Trans>You need to be logged in to view this message.</Trans>
          ),
        });
        history.push('/');
      }
    }
  }, [error, dispatchError, history]);

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }
  if (isFetching) {
    return <PageLoading />;
  }

  const onShareMessage = url => {
    copy(url);
    dispatchSuccess({message: <Trans>Copied to clipboard!</Trans>});
  };

  return (
    <PublicMessageDetailsPage
      message={userMessage}
      mediaItems={mediaItems}
      company={currentCompany}
      onShareMessage={onShareMessage}
      language={currentCompany.defaultLanguage}
    />
  );
};

export default PublicMessageDetailsPageWithState;
