import React from 'react';
import {Trans} from '@lingui/macro';
import Icon from '../../utils/Icon';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import MessageActionsWithState from './MessageActionsWithState';

const MessageDetailsPageHeader = ({message, onGoBack, ...props}) => (
  <Flex flexDirection="row" justifyContent="space-between" {...props}>
    <Button
      variant="link1"
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={onGoBack}>
      <Icon name="chevronLeftLarge" strokeWidth={2.5} />
      <Trans>Back</Trans>
    </Button>
    <MessageActionsWithState message={message} />
  </Flex>
);

export default MessageDetailsPageHeader;
