import React from 'react';
import {getHours} from 'date-fns';
import {Plural, Trans} from '@lingui/macro';
import Box from '../../../modules/utils/Box';
import Title from '../../../modules/utils/Title';

const START_MORNING = 5;
const START_AFTERNOON = 12;
const START_EVENING = 18;
const START_NIGHT = 22;

const getWelcomeString = (currentUser, hour) => {
  const {firstName: name} = currentUser;

  if (hour >= START_NIGHT || hour < START_MORNING) {
    return <Trans id="Good night, {name}." values={{name}} />;
  }
  if (hour >= START_MORNING && hour < START_AFTERNOON) {
    return <Trans id="Good morning, {name}." values={{name}} />;
  }
  if (hour >= START_AFTERNOON && hour < START_EVENING) {
    return <Trans id="Good afternoon, {name}." values={{name}} />;
  }
  if (hour >= START_EVENING && hour < START_NIGHT) {
    return <Trans id="Good evening, {name}." values={{name}} />;
  }

  return <Trans id="Hello, {name}." />;
};

const HomePageWelcome = ({
  color = 'text',
  textShadow = 'none',
  unreadCount: count,
  currentUser,
  ...props
}) => (
  <Box {...props}>
    <Title variant={2} color={color} textShadow={textShadow}>
      {getWelcomeString(currentUser, getHours(new Date()))}
    </Title>
    <Title variant={2} color={color} textShadow={textShadow}>
      <Plural
        value={count}
        _0="You have no unread messages."
        one="You have 1 unread message."
        other="You have {count} unread messages."
      />
    </Title>
  </Box>
);

export default HomePageWelcome;
