import {
  CUSTOM_FAVICON_WHITE_LABEL_SLUGS,
  WHITE_LABEL_SLUGS,
  WHITE_LABEL_INTERNATIONAL_URLS,
} from './constants';

export const hasWhiteLabel = company =>
  WHITE_LABEL_SLUGS.includes(company.slug);

export const hasCustomFavicons = company =>
  CUSTOM_FAVICON_WHITE_LABEL_SLUGS.includes(company.slug);

export const hasInternationalFlow = company =>
  Object.keys(WHITE_LABEL_INTERNATIONAL_URLS).includes(company.slug);

export const getAppStoreUrl = company =>
  `https://apps.apple.com/${WHITE_LABEL_INTERNATIONAL_URLS[company.slug]}`;
