export default {
  UserMessage: {
    body: 'UserMessageBody',
    'body.blocks': 'BodyBlock',
    'body.entityMap': 'UserMessageEntityMap',
    'body.entityMap.data': 'EntityMapData',
    appDomain: 'AppDomain',
    featuredImage: 'Image',
    'featuredImage.sizes': 'ImageSizes',
    'featuredImage.customProperties': 'ImageCustomProperties',
    'featuredImage.customProperties.generatedConversions':
      'GeneratedImageConversions',
  },
  UserMessages: {
    data: 'UserMessage',
    'data.body': 'UserMessageBody',
    'data.body.blocks': 'BodyBlock',
    'data.body.entityMap': 'UserMessageEntityMap',
    'data.body.entityMap.data': 'EntityMapData',
    'data.appDomain': 'AppDomain',
    'data.featuredImage': 'Image',
    'data.featuredImage.sizes': 'ImageSizes',
    'data.featuredImage.customProperties': 'ImageCustomProperties',
    'data.featuredImage.customProperties.generatedConversions':
      'GeneratedImageConversions',
    meta: 'Meta',
  },
  AppDomains: {
    data: 'AppDomain',
  },
  Users: {
    data: 'User',
    'data.segmentGroups': 'SegmentGroup',
    'data.segmentGroups.segments': 'Segment',
    'data.roles': 'UserRole',
    'data.avatar': 'Avatar',
    'data.avatar.sizes': 'AvatarSizes',
    'data.avatar.customProperties': 'AvatarCustomProperties',
    'data.avatar.customProperties.generatedConversions':
      'GeneratedAvatarConversions',
    meta: 'Meta',
  },
  User: {
    segmentGroups: 'SegmentGroup',
    'segmentGroups.segments': 'Segment',
    roles: 'UserRole',
    avatar: 'Avatar',
    'avatar.sizes': 'AvatarSizes',
    'avatar.customProperties': 'AvatarCustomProperties',
    'avatar.customProperties.generatedConversions':
      'GeneratedAvatarConversions',
    apiExporterUserConfigs: 'Integrations',
    'apiExporterUserConfigs.config': 'IntegrationConfig',
  },
  SegmentGroups: {
    data: 'SegmentGroup',
    'data.segments': 'Segment',
    'data.segments.translations': 'SegmentTranslations',
    'data.segments.audienceFilter': 'SegmentAudienceFilter',
    'data.translations': 'SegmentGroupTranslations',
  },
  Directories: {
    files: 'File',
    'files.sizes': 'FileSizes',
    children: 'Directory',
    parent: 'Directory',
  },
  Comments: {
    data: 'Comment',
    'data.createdBy': 'User',
    'data.userMessage': 'UserMessage',
    meta: 'Meta',
  },
  Company: {
    branding: 'CompanyBranding',
    'branding.colors': 'CompanyColors',
    'branding.colors.brand': 'CompanyBrandingColors',
    'branding.logo': 'Logo',
    'branding.logo.sizes': 'LogoSizes',
    'branding.logo.customProperties': 'LogoCustomProperties',
    'branding.logo.customProperties.generatedConversions':
      'GeneratedLogoConversions',
    'branding.banner': 'Banner',
    'branding.banner.sizes': 'BannerSizes',
    'branding.banner.customProperties': 'BannerCustomProperties',
    'branding.banner.customProperties.generatedConversions':
      'GeneratedBannerConversions',
    settings: 'CompanySettings',
    'settings.logo': 'Logo',
    'settings.logo.sizes': 'LogoSizes',
    'settings.logo.customProperties': 'LogoCustomProperties',
    'settings.logo.customProperties.generatedConversions':
      'GeneratedLogoConversions',
    roles: 'UserRole',
  },
  Files: {
    data: 'File',
    meta: 'Meta',
  },
  File: {
    sizes: 'FileSizes',
    customProperties: 'FileCustomProperties',
    'customProperties.generatedConversions': 'GeneratedFileConversions',
  },
  Survey: {
    data: 'Survey',
    options: 'SurveyOption',
    content: 'SurveyContent',
    response: 'SurveyResponse',
    'response.option': 'SurveyOption',
    'response.optionPublished': 'SurveyOptionPublished',
  },
  OpenGraph: {
    og: 'Og',
  },
  Comment: {
    createdBy: 'User',
    'createdBy.avatar': 'Avatar',
    'createdBy.avatar.sizes': 'AvatarSizes',
    'createdBy.avatar.customProperties': 'AvatarCustomProperties',
    'createdBy.avatar.customProperties.generatedConversions':
      'GeneratedAvatarConversions',
  },
};
