import React from 'react';
import Flex from './utils/Flex';
import Loader from './utils/Loader';

const AppLoading = () => (
  <Flex
    px={7}
    flex={1}
    height="100%"
    flexDirection="column"
    alignItems="center"
    justifyContent="center">
    <Loader color="primary" />
  </Flex>
);

export default AppLoading;
