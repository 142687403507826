import React from 'react';
import Box from '../../utils/Box';
import Flex from '../../utils/Flex';
import {AVATAR_SIZE_LARGE} from '../../utils/Avatar';
import {PageContentScrollable} from '../../utils/Page';

const ProfilePageContainer = ({children}) => (
  <PageContentScrollable>
    <Flex
      position="relative"
      bg="white"
      borderRadius={0}
      flexDirection="column"
      alignItems="center"
      px={5}
      pb={5}
      my={6}
      top={AVATAR_SIZE_LARGE / 2}>
      <Box width={['100%', '66%', '50%']}>{children}</Box>
    </Flex>
  </PageContentScrollable>
);

export default ProfilePageContainer;
