import React, {useState} from 'react';
import {whereIsNotId} from '../../../utils';
import {useBookmarksQuery} from '../../../apollo/queries/getBookmarks';
import ErrorPageWithState from '../error/ErrorPageWithState';
import BookmarksPage from './BookmarksPage';
import useDebounce from '../../hooks/useDebounce';
import {useMessagesFilter} from './hooks/MessagesFilters';

const BookmarksPageWithState = () => {
  const [query, setQuery] = useState('');
  const [selectedAppDomains, setSelectedAppDomains] = useState([]);
  const debouncedQuery = useDebounce(query, 300);
  const {statusFilter, onChangeStatusFilter} = useMessagesFilter();
  const {
    error,
    isFetching,
    isFetchingMore,
    hasMorePages,
    fetchMore,
    userMessages,
  } = useBookmarksQuery({
    appDomainsFilter: selectedAppDomains,
    query: debouncedQuery,
  });

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  return (
    <BookmarksPage
      userMessages={userMessages}
      isFetching={isFetching}
      isFetchingMore={isFetchingMore}
      hasMorePages={hasMorePages}
      onFetchMore={fetchMore}
      statusFilter={statusFilter}
      onChangeStatusFilter={onChangeStatusFilter}
      selectedAppDomains={selectedAppDomains}
      onChangeFilters={setSelectedAppDomains}
      search={query}
      onChangeSearch={setQuery}
      onRemoveSelectedAppDomain={({id}) =>
        setSelectedAppDomains(selectedAppDomains.filter(whereIsNotId(id)))
      }
    />
  );
};

export default BookmarksPageWithState;
