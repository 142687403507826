import React from 'react';
import useImageLoadReducer from '../hooks/useImageLoadReducer';
import Flex from './Flex';
import Image from './Image';
import Loader from './Loader';

const ImageWithState = ({
  src: imageSource,
  FallbackComponent = null,
  ...props
}) => {
  const {src, isFailed, isLoading, handleUpdateSource, handleUpdateAttempts} =
    useImageLoadReducer({src: imageSource});

  return (
    <>
      <Image
        src={src}
        display={isLoading || isFailed ? 'none' : 'block'}
        onLoad={() => handleUpdateSource(imageSource)}
        onError={() => handleUpdateAttempts(imageSource)}
        {...props}
      />
      {(() => {
        if (isLoading) {
          return (
            <Flex display="flex" justifyContent="center">
              <Loader color="primary" />
            </Flex>
          );
        }
        if (isFailed) {
          return FallbackComponent;
        }
        return null;
      })()}
    </>
  );
};

export default ImageWithState;
