import React from 'react';
import Box from '../../utils/Box';
import Button from '../../utils/Button';
import Icon from '../../../components/utils/Icon';

const SurveyRSVPItem = ({icon, children, ...props}) => (
  <Button
    my={2}
    variant="unstyled"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    {...props}>
    <Icon name={icon} />
    <Box ml={4} flex={1} textAlign="left">
      {children}
    </Box>
    <Icon name="chevronRight" color="grey" />
  </Button>
);

export default SurveyRSVPItem;
