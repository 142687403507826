import React from 'react';
import {convertToRaw} from 'draft-js';
import {Editor} from 'medium-draft';
import {Trans} from '@lingui/macro';
import {createRogerEditorState} from '../../../utils/editor';
import customRenderer from '../../../utils/customRenderer';
import Box from '../../utils/Box';
import Date from '../../utils/Date';
import Header from '../../utils/Header';
import Heading from '../../utils/Heading';
import MessageDetailsPageHeader from './MessageDetailsPageHeader';
import Page, {PageContentScrollable} from '../../utils/Page';
import Tag from '../../../modules/utils/Tag';
import Flex from '../../../modules/utils/Flex';
import Button from '../../../modules/utils/Button';

const MessageDetailsPage = ({message, company, onGoBack, onShareMessage}) => {
  const body = createRogerEditorState(message.body);
  const content = body.getCurrentContent();
  const hasText = content.hasText();
  const {blocks} = convertToRaw(body.getCurrentContent());
  const firstBlockIsText = blocks[0].type === 'unstyled';
  const hasNoContent = !hasText && firstBlockIsText;
  let {title} = message;
  // For legacy titles which hold a translation key
  if (typeof title === 'object') {
    [title] = Object.values(title);
  }
  return (
    <>
      <Header />
      <Page>
        <PageContentScrollable>
          <MessageDetailsPageHeader
            message={message}
            onGoBack={onGoBack}
            mt={6}
            px={2}
          />
          <Box maxWidth={['100%', '50rem']} mx="auto">
            <Flex justifyContent="space-between" my={7} px={2}>
              <Box>
                <Heading variant={1} mb={2}>
                  {title}
                </Heading>
                {message.scheduledAt && (
                  <Tag variant={1}>
                    <Date value={message.scheduledAt} />
                  </Tag>
                )}
              </Box>
              {message.shareUrl !== null && (
                <Button
                  variant="link1"
                  onClick={() => onShareMessage(message.shareUrl)}
                  pt={4}>
                  <Trans>Share</Trans>
                </Button>
              )}
            </Flex>

            {!hasNoContent && (
              <Editor
                editorState={body}
                onChange={() => {}}
                editorEnabled={false}
                placeholder=""
                rendererFn={(setEditorState, getEditorState) => contentBlock =>
                  customRenderer(
                    setEditorState,
                    getEditorState,
                    contentBlock,
                    message.id,
                    message.acceptAnswers,
                    company,
                  )
                }
              />
            )}
          </Box>
        </PageContentScrollable>
      </Page>
    </>
  );
};

export default MessageDetailsPage;
