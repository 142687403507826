import React, {useMemo, useState} from 'react';
import {Trans} from '@lingui/macro';
import styled from 'styled-components';
import InfiniteScroll from '../../utils/InfiniteScroll';
import EmptyList from '../../utils/EmptyList';
import useAllMessages from './hooks/useAllMessages';
import Flex from '../../../modules/utils/Flex';
import MessagesListItemWithState from './MessagesListItemWithState';
import InputSearch from '../../utils/InputSearch';
import {PageContentScrollable} from '../../utils/Page';
import Button from '../../utils/Button';
import Icon from '../../utils/Icon';
import Filters from './MessageFiltersNew';

const FilterButton = styled(Button)`
  ${({theme, isActive}) =>
    isActive
      ? `
  `
      : `color:${theme.colors.grey[4]};`}
`;

const Asterisk = ({isVisible}) => {
  if (!isVisible) return null;
  return <span style={{color: 'red'}}>*</span>;
};
const AllMessagesPage = () => {
  const {
    messages,
    // total,
    hasMore,
    isFetching,
    fetchMore,
    onChangeSearch,
    filters,
    setFilters,
  } = useAllMessages();

  //
  const [openFilters, setOpenFilters] = useState(true);
  const hasActiveFilters = useMemo(
    () => !!filters?.app_domain?.length || filters?.onlyUnread,
    [filters],
  );
  return (
    <>
      <Flex
        justifyContent="space-between"
        style={{gap: '1rem', alignItems: 'center'}}>
        <InputSearch
          onChange={onChangeSearch}
          mb={1}
          width="100%"
          style={{height: 'fit-content'}}
        />
        <FilterButton
          variant="link2"
          onClick={() => setOpenFilters(v => !v)}
          isActive={openFilters || hasActiveFilters}
          style={{textWrap: 'nowrap'}}>
          <Icon
            name="filter"
            color={openFilters || hasActiveFilters ? 'default' : 'grey[4]'}
          />
          {openFilters ? (
            <Trans>Hide filters</Trans>
          ) : (
            <>
              <Trans>Show filters</Trans>{' '}
              <Asterisk isVisible={hasActiveFilters} />
            </>
          )}
        </FilterButton>
      </Flex>
      <Filters
        isOpened={openFilters}
        filters={filters}
        setFilters={setFilters}
      />
      <PageContentScrollable>
        <InfiniteScroll
          hasMore={hasMore}
          isFetchingMore={isFetching}
          onFetchMore={fetchMore}>
          <Flex flexDirection="column" alignItems="center">
            {!isFetching && messages.length === 0 ? (
              <EmptyList
                icon="inbox"
                title={
                  typeof searchFilter === 'undefined' &&
                  typeof selectedAppDomains === 'undefined' ? (
                    <Trans id="You haven't read any messages yet." />
                  ) : (
                    <Trans>No messages found.</Trans>
                  )
                }
                mt={7}
              />
            ) : (
              messages.map(userMessage => (
                <MessagesListItemWithState
                  key={userMessage.id}
                  message={userMessage}
                />
              ))
            )}
          </Flex>
        </InfiniteScroll>
      </PageContentScrollable>
    </>
  );
};

export default AllMessagesPage;
