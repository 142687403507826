// Import date-fns translations
import dateTranslationsDE from 'date-fns/locale/de';
import dateTranslationsEN from 'date-fns/locale/en-GB';
import dateTranslationsES from 'date-fns/locale/es';
import dateTranslationsFR from 'date-fns/locale/fr';
import dateTranslationsIT from 'date-fns/locale/it';
import dateTranslationsNL from 'date-fns/locale/nl';
import dateTranslationsPL from 'date-fns/locale/pl';
import dateTranslationsPT from 'date-fns/locale/pt';
import dateTranslationsTR from 'date-fns/locale/tr';
import useCurrentUser from './useCurrentUser';

const translations = {
  de: dateTranslationsDE,
  en: dateTranslationsEN,
  es: dateTranslationsES,
  fr: dateTranslationsFR,
  it: dateTranslationsIT,
  nl: dateTranslationsNL,
  pl: dateTranslationsPL,
  pt: dateTranslationsPT,
  tr: dateTranslationsTR,
};

const useLocale = language => {
  const currentUser = useCurrentUser();
  if (typeof language !== 'undefined') {
    return translations[language];
  }
  return translations[currentUser.language];
};

export default useLocale;
