import React from 'react';
import {Trans} from '@lingui/macro';
import {
  LIST_TYPE_ALL,
  LIST_TYPE_SAVED,
  LIST_TYPE_UNREAD,
  LIST_TYPE_READ,
} from '../../../utils/constants';
import Button from '../../utils/Button';
import Heading from '../../utils/Heading';
import Flex from '../../utils/Flex';
import {useMessagesFilter} from './hooks/MessagesFilters';
import usePermissions from '../../hooks/usePermissions';
//
const MessagesScreenTypeTabs = ({...props}) => {
  const {hasAllMessagesPage} = usePermissions();
  const {statusFilter, onChangeStatusFilter} = useMessagesFilter();
  return (
    <Flex flexDirection="row" {...props}>
      <Button
        mr={5}
        variant="unstyled"
        onClick={() => onChangeStatusFilter(LIST_TYPE_UNREAD)}>
        <Heading
          px={1}
          variant={4}
          color={statusFilter === LIST_TYPE_UNREAD ? 'primary' : 'grey.4'}>
          <Trans>New</Trans>
        </Heading>
      </Button>
      {!hasAllMessagesPage && (
        <Button
          mr={5}
          variant="unstyled"
          onClick={() => onChangeStatusFilter(LIST_TYPE_READ)}>
          <Heading
            px={1}
            variant={4}
            color={statusFilter === LIST_TYPE_READ ? 'primary' : 'grey.4'}>
            <Trans>Read</Trans>
          </Heading>
        </Button>
      )}
      <Button
        mr={5}
        variant="unstyled"
        onClick={() => onChangeStatusFilter(LIST_TYPE_SAVED)}>
        <Heading
          px={1}
          variant={4}
          color={statusFilter === LIST_TYPE_SAVED ? 'primary' : 'grey.4'}>
          <Trans>Saved</Trans>
        </Heading>
      </Button>
      {hasAllMessagesPage && (
        <Button
          mr={5}
          variant="unstyled"
          onClick={() => onChangeStatusFilter(LIST_TYPE_ALL)}>
          <Heading
            px={1}
            variant={4}
            color={statusFilter === LIST_TYPE_ALL ? 'primary' : 'grey.4'}>
            <Trans>All</Trans>
          </Heading>
        </Button>
      )}
    </Flex>
  );
};

export default MessagesScreenTypeTabs;
