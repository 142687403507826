import React from 'react';

export const WebSocketConnectionProviderContext = React.createContext();

const WebSocketConnectionProvider = ({webSocketConnection, ...props}) => (
  <WebSocketConnectionProviderContext.Provider
    value={webSocketConnection}
    {...props}
  />
);

export default WebSocketConnectionProvider;
