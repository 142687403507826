import React, {useState} from 'react';
import useCompany from '../../hooks/useCompany';
import useAppState from '../../hooks/useAppState';
import WelcomePageLanguageDropdown from './WelcomePageLanguageDropdown';

const WelcomePageLanguageDropdownWithState = ({...props}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {activeLanguage, updateActiveLanguage} = useAppState();
  const {languages} = useCompany();

  const handleChangeLanguage = language => {
    updateActiveLanguage(language);
    setIsOpen(false);
  };

  return (
    <WelcomePageLanguageDropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      currentLanguage={activeLanguage}
      languages={languages}
      onChangeLanguage={handleChangeLanguage}
      {...props}
    />
  );
};

export default WelcomePageLanguageDropdownWithState;
