import React, {useState, useRef, useEffect} from 'react';
import {FILE_TYPE_IMAGE, FILE_TYPE_VIDEO} from '../../../utils/constants';
import {getFileTypeFromFileName} from '../documents/FileListItem';
import Flex from '../../../modules/utils/Flex';
import Image from '../../../modules/utils/Image';

const MIN_HEIGHT = 150;
const MAX_HEIGHT = 200;

const MessagesListItemBanner = ({featuredImage = {}, ...props}) => {
  const ref = useRef();
  const [bannerProps, setBannerProps] = useState({});
  const [imageDimensions, setImageDimensions] = useState();
  const imageDimensionsRef = useRef(imageDimensions);
  const {sizes: {small: imageUrl} = {}} = featuredImage;
  const {fileName} = featuredImage;
  const fileType = getFileTypeFromFileName(fileName);

  const setImageDimensionsRef = data => {
    imageDimensionsRef.current = data;
    setImageDimensions(data);
  };

  const getBannerProps = () => {
    if (typeof imageDimensionsRef.current !== 'undefined') {
      const maxWidth = ref.current.clientWidth;
      const {naturalWidth, naturalHeight} = imageDimensionsRef.current;
      let width = naturalWidth;
      let height = naturalHeight;
      const aspect = width / height;
      let borderTopLeftRadius = 0;
      let borderTopRightRadius = 0;

      // Check if height is between bounds
      if (height > MAX_HEIGHT) {
        height = MAX_HEIGHT;
        width = aspect * MAX_HEIGHT;
      } else if (height < MIN_HEIGHT) {
        height = MIN_HEIGHT;
        width = aspect * MIN_HEIGHT;
      }
      // Check if width is bewteen bounds
      if (width > maxWidth) {
        width = maxWidth;
        height = width / aspect;
      } else {
        // If the image still has room to grow increase width incrementally while
        // maintaining aspect ratio until either width or height reaches it's bounds
        const increaseWidth = () => {
          if (height <= MAX_HEIGHT && width < maxWidth) {
            width += 1;
            height = width / aspect;
            increaseWidth();
          }
        };
        increaseWidth();
      }
      // Remove border radius when the image does not touch the edges of the card
      if (width < maxWidth) {
        borderTopLeftRadius = 'none';
        borderTopRightRadius = 'none';
      }

      setBannerProps({
        width,
        height,
        borderTopLeftRadius,
        borderTopRightRadius,
      });
    }
  };

  const onLoadImage = ({target}) => {
    const {naturalWidth, naturalHeight} = target;
    setImageDimensionsRef({naturalWidth, naturalHeight});
    getBannerProps();
  };

  useEffect(() => {
    window.addEventListener('resize', getBannerProps);
    return () => {
      window.removeEventListener('resize', getBannerProps);
    };
  }, []);

  if (
    typeof imageUrl === 'undefined' ||
    (fileType !== FILE_TYPE_IMAGE && fileType !== FILE_TYPE_VIDEO)
  ) {
    return null;
  }

  return (
    <Flex
      ref={ref}
      flexDirection="row"
      justifyContent="center"
      width="100%"
      minHeight={75}
      {...props}>
      <Image
        onLoad={onLoadImage}
        src={imageUrl}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        maxHeight={MAX_HEIGHT}
        {...bannerProps}
      />
    </Flex>
  );
};

export default MessagesListItemBanner;
