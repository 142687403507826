import styled from 'styled-components';
import {
  color,
  space,
  layout,
  position,
  flexbox,
  shadow,
  border,
  typography,
  background,
  system,
} from 'styled-system';

const Box = styled.div`
  ${color}
  ${space}
    ${layout}
    ${position}
    ${flexbox}
    ${shadow}
    ${border}
    ${typography}
    ${background}

    ${system({
    transform: true,
  })}
`;

export default Box;
