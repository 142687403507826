import {useMutation, gql} from '@apollo/client';
import DEFAULT_USER_FRAGMENT from '../../../apollo/fragments/defaultUser';

export const UPDATE_USER_MUTATION = gql`
  mutation updateUserMutation($body: UserBodyInput!) {
    updateUser(input: $body)
      @rest(type: "User", path: "/users/me", method: "PUT") {
      ...DefaultUser
    }
  }
  ${DEFAULT_USER_FRAGMENT}
`;

const useUpdateUserMutation = () => useMutation(UPDATE_USER_MUTATION);

export default useUpdateUserMutation;
