import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import EmptyList from '../../utils/EmptyList';
import InputSearch from '../../utils/InputSearch';
import InfiniteScroll from '../../utils/InfiniteScroll';
import ColleagueCard from './ColleagueCard';
import ColleaguesFilters from './ColleaguesFilters';
import Box from '../../utils/Box';
import Page, {PageContentScrollable} from '../../utils/Page';

const ColleaguesPage = ({
  isFetching,
  isFetchingMore,
  hasMorePages,
  users,
  search,
  onChangeSearch,
  onChangeFilters,
  onFetchMore,
}) => (
  <>
    <Header />
    <Page>
      <Box>
        <ColleaguesFilters onChangeFilters={onChangeFilters} />
      </Box>
      <InputSearch value={search} onChange={onChangeSearch} mb={6} />
      <PageContentScrollable>
        <InfiniteScroll
          hasMore={hasMorePages}
          isFetchingMore={isFetchingMore || isFetching}
          onFetchMore={onFetchMore}>
          <Flex flexDirection="column" p={3}>
            {!isFetching && users.length === 0 ? (
              <EmptyList
                icon="noSearch"
                title={<Trans>No colleagues found.</Trans>}
                subTitle={
                  <Trans>Are you sure you entered the right information?</Trans>
                }
              />
            ) : (
              users.map(user => <ColleagueCard key={user.id} user={user} />)
            )}
          </Flex>
        </InfiniteScroll>
      </PageContentScrollable>
    </Page>
  </>
);

export default ColleaguesPage;
