import React, {useState} from 'react';
import styled from 'styled-components';
import {usePopper} from 'react-popper';
import {motion} from 'framer-motion';
import useOpen from '../hooks/useOpen';
import Box from './Box';
import Portal from './Portal';

const POSITION_TOP = 'top';
const POSITION_BOTTOM = 'bottom';
const POSITION_BOTTOM_END = 'bottom-end';
const POSITION_BOTTOM_START = 'bottom-start';
const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

const Popover = ({
  isOpen: isOpenFromProps,
  setIsOpen: setIsOpenFromProps,
  placement = POSITION_BOTTOM_END,
  content,
  children,
}) => {
  const [isOpen, setIsOpen] = useOpen({isOpenFromProps, setIsOpenFromProps});
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement,
  });

  const handleOpen = e => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Wrap ref={setReferenceElement} onClick={handleOpen}>
        {children}
      </Wrap>
      {isOpen && (
        <AnimatedPortal
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          onOutsideClick={handleClose}>
          <Box
            pt={2}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            {content}
          </Box>
        </AnimatedPortal>
      )}
    </>
  );
};

const AnimatedPortal = motion.custom(Portal);

const Wrap = styled.span`
  outline: none;
  background: none;
  border: 0;
`;

Popover.POSITION_TOP = POSITION_TOP;
Popover.POSITION_BOTTOM = POSITION_BOTTOM;
Popover.POSITION_BOTTOM_END = POSITION_BOTTOM_END;
Popover.POSITION_BOTTOM_START = POSITION_BOTTOM_START;
Popover.POSITION_LEFT = POSITION_LEFT;
Popover.POSITION_RIGHT = POSITION_RIGHT;

export default Popover;
