import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import useNotifications from '../../hooks/useNotifications';
import useSetSurveyResponseMutation from './useSetSurveyResponseMutation';
import EditorBlockContainer from '../../../modules/editor/EditorBlockContainer';
import SurveyWithState from '../../../modules/editor/surveys/SurveyWithState';

const TIME_TO_WAIT = 600;

const SurveyBlockWithState = ({surveyId, messageId, company}) => {
  const {dispatchSuccess, dispatchError} = useNotifications();
  const [isPublishing, setIsPublishing] = useState(false);
  const [setSurveyResponse] = useSetSurveyResponseMutation();

  const sleep = time =>
    new Promise(resolve => {
      setTimeout(resolve, time);
    });

  const handleChange = async (setSelectedOption, option) => {
    setIsPublishing(true);
    setSelectedOption(option);
    try {
      const startTime = new Date();
      const variables = {
        startTime,
        messageId,
        surveyId,
        body: {
          option,
          is_published: true,
        },
      };
      await setSurveyResponse({variables});
      const timeElapsed = new Date() - startTime;
      if (timeElapsed < TIME_TO_WAIT) {
        await sleep(TIME_TO_WAIT - timeElapsed);
      }

      dispatchSuccess({
        message: <Trans>Thanks for your feedback!</Trans>,
      });
    } catch (error) {
      dispatchError({
        message: error.message,
      });
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <EditorBlockContainer>
      <SurveyWithState
        surveyId={surveyId}
        messageId={messageId}
        company={company}
        onChange={handleChange}
        isPublishing={isPublishing}
      />
    </EditorBlockContainer>
  );
};

export default SurveyBlockWithState;
