import React from 'react';
import Dropdown from '../../utils/Dropdown';
import Box from '../../../modules/utils/Box';
import Label from '../../../modules/utils/Label';
import Button from '../../../modules/utils/Button';
import Icon from '../../utils/Icon';
import {getLangByCode} from '../../../locales';

const WelcomePageLanguageDropdown = ({
  isOpen,
  setIsOpen,
  currentLanguage,
  languages = [],
  onChangeLanguage,
  ...props
}) => (
  <Box {...props}>
    {languages.length > 0 && (
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listItems={
          <Dropdown.List>
            {languages.map(lang => (
              <Dropdown.ListItem
                key={lang}
                onClick={() => onChangeLanguage(lang)}>
                <Label as="span" variant={3}>
                  {getLangByCode(lang)}
                </Label>
              </Dropdown.ListItem>
            ))}
          </Dropdown.List>
        }>
        <Button variant="link2" alignItems="center">
          {getLangByCode(currentLanguage)}
          <Icon name="chevronDown" />
        </Button>
      </Dropdown>
    )}
  </Box>
);

export default WelcomePageLanguageDropdown;
