/* eslint-disable react/no-danger */
import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Container from '../../utils/Container';
import Button from '../../utils/Button';

const TermsAndConditionsPage = props => {
  const {accept, html, isLoading} = props;

  return (
    <Container
      height="100%"
      flex="1"
      display="flex"
      flexDirection="column"
      pt={5}
      pb={5}>
      <Flex
        bg="white"
        borderRadius={0}
        width="100%"
        height="100%"
        boxShadow={0}
        overflow="scroll">
        {html && <div dangerouslySetInnerHTML={{__html: html}} />}
      </Flex>
      <Button mt={5} flex={1} onClick={accept} disabled={isLoading}>
        <Trans>I accept the Terms and Conditions</Trans>
      </Button>
    </Container>
  );
};
export default TermsAndConditionsPage;
