import React, {useState} from 'react';
import {Trans, t} from '@lingui/macro';
import useLingui from '../../hooks/useLingui';
import Flex from '../../utils/Flex';
import Avatar from '../../utils/Avatar';
import Button from '../../utils/Button';
import TextAreaInput from '../../utils/InputTextArea';

const CommentInput = ({
  value = '',
  avatar,
  onSubmitComment,
  isClearable,
  ...props
}) => {
  const lingui = useLingui();
  const [isFocused, setIsFocused] = useState(value);
  const [textValue, setTextValue] = useState(value);
  const [isCreating, setIsCreating] = useState(false);

  const handleSubmitComment = async () => {
    if (textValue !== '') {
      setIsCreating(true);
      await onSubmitComment(textValue);
      setTextValue('');
      setIsFocused(false);
      setIsCreating(false);
    }
  };

  const handleCancelComment = () => {
    setTextValue('');
    setIsFocused(false);
  };

  return (
    <Flex
      mb={6}
      alignItems="flex-start"
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      borderBottomRightRadius={0}
      width="100%"
      {...props}>
      <Avatar mr={5} size="medium" avatar={avatar} />
      <Flex flex={1} flexDirection="column">
        <TextAreaInput
          disabled={isCreating}
          onFocus={() => setIsFocused(true)}
          value={textValue}
          placeholder={lingui._(t`Write a comment...`)}
          onChange={e => setTextValue(e.target.value)}
          isClearable={isClearable}
        />
        {isFocused && (
          <Flex mt={6} alignItems="center" justifyContent="space-between">
            <Button
              flex={1}
              variant="primary"
              isLoading={isCreating}
              onClick={handleSubmitComment}>
              <Trans>Save</Trans>
            </Button>
            <Button
              flex={1}
              variant="unstyledCentered"
              disabled={isCreating}
              onClick={handleCancelComment}>
              <Trans>Cancel</Trans>
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default CommentInput;
