import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import {stringify} from 'query-string';
import {MODEL_NOT_FOUND} from '../../../utils/constants';
import {updateFormErrors} from '../../../utils/validation';
import useNotifications from '../../hooks/useNotifications';
import useNavigateWithSearch from '../../hooks/useNavigateWithSearch';
import LoginEmailPage from './LoginEmailPage';
import useRequestOtpMutation from './useRequestOtpMutation';
import useLoginEmailFormState from './useLoginEmailFormState';

const LoginEmailPageWithState = () => {
  const formState = useLoginEmailFormState();
  const [isLoading, setIsLoading] = useState(false);
  const navigateWithSearch = useNavigateWithSearch();
  const [requestOtp] = useRequestOtpMutation();
  const {dispatchError} = useNotifications();

  const handleCancel = () => navigateWithSearch('/login');

  const handleSubmit = async () => {
    if (formState.validate()) {
      setIsLoading(true);
      try {
        const body = formState.valuesToInput();
        const variables = {body};
        await requestOtp({variables});
        navigateWithSearch(
          `/login/otp?driver=email&${stringify({receiver: body.email})}`,
        );
      } catch (error) {
        const errorMessage = updateFormErrors(error, formState.updateErrors);
        if (errorMessage === MODEL_NOT_FOUND) {
          dispatchError({
            message: (
              <Trans>
                We were unable to find a user with this email address.
              </Trans>
            ),
          });
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <LoginEmailPage
      isLoading={isLoading}
      formState={formState}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default LoginEmailPageWithState;
