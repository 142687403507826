import React from 'react';
import Audio from '../../utils/Audio';
import Box from '../../utils/Box';

const AudioBlock = ({src, type, ...props}) => (
  <Box whiteSpace="normal" {...props}>
    <Audio width="100%" controls>
      <source src={src} type={type} />
    </Audio>
  </Box>
);

export default AudioBlock;
