export const DE = 'de';
export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';
export const IT = 'it';
export const NL = 'nl';
export const PL = 'pl';
export const PT = 'pt';
export const TR = 'tr';

export const DEFAULT_LANGUAGE = EN;

export const FILE_TYPE_IMAGE = 'Image';
export const FILE_TYPE_WORD = 'Word';
export const FILE_TYPE_EXCEL = 'Excel';
export const FILE_TYPE_POWERPOINT = 'MS PowerPoint';
export const FILE_TYPE_SPREADSHEET = 'Spreadsheet';
export const FILE_TYPE_AUDIO = 'Audio';
export const FILE_TYPE_VIDEO = 'Video';
export const FILE_TYPE_PDF = 'PDF';
export const FILE_TYPE_DEFAULT = 'Document';
export const FILE_TYPE_AVATAR = 'Avatar';

export const DEFAULT_LIST_PAGE = 1;
export const DEFAULT_PAGE_LIMIT = 50;
export const MAX_PAGE_LIMIT = 250;
export const DEFAULT_SORT = 'scheduled_at.desc';
export const DEFAULT_COMMENT_SORT = 'created_at.desc';
export const DEFAULT_COMMENT_PAGE_LIMIT = 25;

export const TYPE_ERROR = 'error';
export const TYPE_WARNING = 'warning';
export const TYPE_SUCCESS = 'success';

export const MODEL_NOT_FOUND = 'not-found';

export const LIST_TYPE_READ = 'read';
export const LIST_TYPE_UNREAD = 'unread';
export const LIST_TYPE_SAVED = 'saved';
export const LIST_TYPE_ALL = 'all';

export const DEPARTMENTS_SLUG = 'departments';

export const WEB_NAVBAR_HEIGHT = 7.4;
export const MOBILE_NAVBAR_HEIGHT = 14.8;

export const H1 = 'header-one';
export const H2 = 'header-two';
export const ATOMIC = 'atomic';
export const UNSTYLED = 'unstyled';
export const BLOCKQUOTE = 'blockquote';
export const ORDERED_LIST_ITEM = 'ordered-list-item';
export const UNORDERED_LIST_ITEM = 'unordered-list-item';
export const MEDIA_BLOCK = 'MediaBlock';
export const EMBED_BLOCK = 'EmbedBlock';
export const SURVEY_BLOCK = 'SurveyBlock';
export const TYPE_RSVP = 'rsvp';
export const TYPE_RATING = 'rating';
export const TYPE_OPINION_SCALE = 'opinion_scale';
export const TYPE_HAPPINESS_POLL = 'happiness_poll';
export const TYPE_MULTIPLE_CHOICE = 'multiple_choice';
export const TYPE_YES_NO_QUESTION = 'yes_no_question';
export const CHOOSE_CHANNELS_BLOCK = 'ChooseChannels';

export const PHONE = 'phone';
export const EMAIL = 'email';
export const WHATS_APP = 'whats_app';

export const CHANNEL_SMS = 'sms';
export const CHANNEL_MAIL = 'mail';
export const CHANNEL_WHATS_APP = 'whats_app';
export const CHANNEL_PUSH_NOTIFICATION = 'pusher';

export const SLUG_21_COMMS = '21-comms';
export const SLUG_HEYLEN = 'heylen';
export const SLUG_WAPPLA = 'wappla';
export const SLUG_VAN_MOER = 'vanmoerconnect';
export const SLUG_VERBRUGGE = 'verbruggeconnect';
export const SLUG_HELLO_VIGO = 'hellovigo';
export const SLUG_CYRIEL = 'cyriel';
export const SLUG_AHPG = 'ahpg';
export const SLUG_PULSE = 'pulse';
export const SLUG_HELLO_COECK = 'hellocoeck';
export const SLUG_HIPERTALK = 'hipertalk';
export const SLUG_BALTA_CONNECT = 'baltaconnect';
export const SLUG_ITL_CONECTA = 'itl-conecta';
export const SLUG_JORIS = 'joris';
export const SLUG_INFOMEERT = 'infomeert';

export const WHITE_LABEL_SLUGS = [
  SLUG_21_COMMS,
  SLUG_HEYLEN,
  SLUG_WAPPLA,
  SLUG_VAN_MOER,
  SLUG_VERBRUGGE,
  SLUG_HELLO_VIGO,
  SLUG_CYRIEL,
  SLUG_AHPG,
  SLUG_PULSE,
  SLUG_HELLO_COECK,
  SLUG_HIPERTALK,
  SLUG_BALTA_CONNECT,
  SLUG_JORIS,
  SLUG_INFOMEERT,
];

export const WHITE_LABEL_INTERNATIONAL_URLS = {
  [SLUG_HIPERTALK]: 'app/hipertalk/id1589211556',
  [SLUG_BALTA_CONNECT]: 'app/balta-connect/id1589080044',
  [SLUG_JORIS]: 'app/joris-ide/id1596276356',
  [SLUG_INFOMEERT]: 'app/infomeert/id1612620544',
  [SLUG_PULSE]: 'app/pulse/id1614251849',
  [SLUG_21_COMMS]: 'app/21-comms/id1597260785',
};

export const CUSTOM_FAVICON_WHITE_LABEL_SLUGS = [SLUG_PULSE, SLUG_VERBRUGGE];

export const WHITE_LABEL_EXTERNAL_CALENDAR_URLS = {
  [SLUG_PULSE]: 'https://dove-echidna-8d95.squarespace.com/',
  [SLUG_WAPPLA]: 'https://dove-echidna-8d95.squarespace.com/',
};

export const CUSTOM_APP_STORE_BUNDLE_IDS = {
  [SLUG_21_COMMS]: 'io.meetroger.roger21comms',
};
