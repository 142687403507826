import React from 'react';
import Text from '../../utils/Text';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import SurveyBlockHeader from './SurveyBlockHeader';

const SIZE = 40;

const SurveyOpinionScale = ({
  survey,
  onChange,
  selectedOption,
  isPublished,
  isPublishing,
}) => {
  const {content, options} = survey;
  return (
    <>
      <SurveyBlockHeader
        question={content.question}
        description={content.description}
        isPublished={isPublished}
        isPublishing={isPublishing}
      />
      <Flex flexDirection="row" flexWrap="wrap" px={6} pb={6}>
        {options.map(option => {
          const isSelected =
            selectedOption && selectedOption.value >= option.value;
          return (
            <Button
              key={option.value}
              onClick={() => onChange(option)}
              disabled={onChange === null}
              variant="unstyled"
              alignItems="center"
              justifyContent="center"
              width={SIZE}
              height={SIZE}
              borderRadius={SIZE / 2}
              bg={isSelected ? 'primary' : 'grey.2'}
              mr={3}
              mb={3}>
              <Text
                fontSize={2}
                fontFamily="bold"
                color={isSelected ? 'white' : 'grey.4'}>
                {option.value}
              </Text>
            </Button>
          );
        })}
      </Flex>
    </>
  );
};

export default SurveyOpinionScale;
