import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import Icon from '../../utils/Icon';
import Text from '../../utils/Text';
import Flex from '../../utils/Flex';
import Label from '../../utils/Label';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Dropdown from '../../utils/Dropdown';
import {getLangByCode} from '../../../locales';

const ProfileLanguageButton = ({
  isUpdatingLanguage,
  language: propLanguage,
  languages,
  onChangeLanguage: propOnChangeLanguage,
  ...props
}) => {
  const [language, setLanguage] = useState(propLanguage);

  const onChangeLanguage = lang => {
    setLanguage(lang);
    propOnChangeLanguage(lang);
  };

  return (
    <Flex flexDirection="column" {...props}>
      <Title variant={2} mb={6}>
        <Trans>Language</Trans>
      </Title>
      <Dropdown
        listItems={
          <Dropdown.List>
            {languages.map(lang => (
              <Dropdown.ListItem
                key={lang}
                onClick={() => onChangeLanguage(lang)}>
                <Label as="span" variant={3}>
                  {getLangByCode(lang)}
                </Label>
              </Dropdown.ListItem>
            ))}
          </Dropdown.List>
        }>
        <Button width="100%" isLoading={isUpdatingLanguage}>
          <Text color="white" fontFamily="bold" fontSize={2}>
            {getLangByCode(language)}
          </Text>
          <Icon name="chevronRight" color="white" />
        </Button>
      </Dropdown>
    </Flex>
  );
};

export default ProfileLanguageButton;
