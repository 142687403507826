import React from 'react';
import Switch from 'react-switch';
import {useTheme} from 'styled-components';
import Label from './Label';

const CustomSwitch = ({
  id,
  checkedIcon = false,
  uncheckedIcon = false,
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Label display="flex" htmlFor={id}>
      <Switch
        id={id}
        onColor={theme.colors.primary}
        offColor={theme.colors.grey[3]}
        onHandleColor={theme.colors.white}
        offHandleColor={theme.colors.white}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        height={22}
        width={40}
        {...props}
      />
      {typeof children !== 'undefined' && <Label>{children}</Label>}
    </Label>
  );
};

export default CustomSwitch;
