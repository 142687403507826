import {rendererFn} from 'medium-draft';
import {ATOMIC} from './constants';
import AtomicBlock from '../components/pages/message-details/AtomicBlock';

export default (
  setEditorState,
  getEditorState,
  contentBlock,
  messageId,
  acceptAnswers,
  company,
  isPublic = false,
  mediaItems = [],
) => {
  const type = contentBlock.getType();

  switch (type) {
    case ATOMIC:
      return {
        component: AtomicBlock,
        editable: false,
        props: {
          messageId,
          acceptAnswers,
          company,
          isPublic,
          mediaItems,
        },
      };
    default:
      return rendererFn(setEditorState, getEditorState)(contentBlock);
  }
};
