import {
  SLUG_PULSE,
  SLUG_WAPPLA,
  SLUG_21_COMMS,
  SLUG_VAN_MOER,
  SLUG_ITL_CONECTA,
  SLUG_BALTA_CONNECT,
} from '../../utils/constants';
import useCompany from './useCompany';

const useCustomApp = () => {
  const {slug: companySlug} = useCompany();
  const isPulse = companySlug === SLUG_PULSE;
  const isWappla = companySlug === SLUG_WAPPLA;
  const is21Comms = companySlug === SLUG_21_COMMS;
  const isVanMoer = companySlug === SLUG_VAN_MOER;
  const isItlConnecta = companySlug === SLUG_ITL_CONECTA;
  const isBaltaConnect = companySlug === SLUG_BALTA_CONNECT;

  const hasSmallBanner = isPulse;
  const hidesNewDocuments = isPulse;
  const homeTitleIsInBanner = isVanMoer;
  const welcomeLogoIsCentered = isVanMoer;
  const hidesNewColleagues = isPulse || isItlConnecta;
  const useCustomAppName = is21Comms || isVanMoer || isPulse || isBaltaConnect;

  return {
    isPulse,
    isWappla,
    is21Comms,
    isVanMoer,
    hasSmallBanner,
    useCustomAppName,
    hidesNewDocuments,
    hidesNewColleagues,
    homeTitleIsInBanner,
    welcomeLogoIsCentered,
  };
};

export default useCustomApp;
