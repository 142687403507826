import React, {useState, useEffect} from 'react';
import {Trans} from '@lingui/macro';
import EditorBlockError from '../EditorBlockError';
import EditorBlockLoading from '../EditorBlockLoading';
import SurveyBlock from './SurveyBlock';
import useFetchSurvey from '../../../apollo/queries/useFetchSurvey';

const SurveyWithState = ({surveyId, messageId, onChange, ...props}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const {isFetching, survey} = useFetchSurvey({surveyId, messageId});
  const publishedOption =
    survey && survey.response && survey.response.optionPublished;

  useEffect(() => {
    if (typeof publishedOption !== 'undefined' && publishedOption !== null) {
      setSelectedOption(publishedOption);
    }
  }, [publishedOption]);

  const handleChange =
    onChange === null ? null : option => onChange(setSelectedOption, option);

  if (isFetching) {
    return <EditorBlockLoading />;
  }

  if (typeof survey === 'undefined') {
    return (
      <EditorBlockError>
        <Trans>This survey does not exist anymore.</Trans>
      </EditorBlockError>
    );
  }

  return (
    <SurveyBlock
      survey={survey}
      isFetching={isFetching}
      isPublished={
        typeof selectedOption !== 'undefined' && selectedOption !== null
      }
      selectedOption={selectedOption || {}}
      onChange={handleChange}
      {...props}
    />
  );
};

export default SurveyWithState;
