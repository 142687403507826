import localforage from 'localforage';
// import mockDriver from 'localforage-memoryStorageDriver'

// eslint-disable-next-line no-underscore-dangle
// const MOCK_DRIVER = mockDriver._driver

// if (process.env.NODE_ENV === 'test') {
//     localforage.defineDriver(mockDriver)
//     localforage.setDriver(MOCK_DRIVER)
// }

const EXPIRES_IN = 'expiresIn';
const LANGUAGE_KEY = 'language';
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const COMPANY_SLUG_KEY = 'companySlug';

// In memory cache
let companySlug = null;
let accessToken = null;
let refreshToken = null;
let expiresIn = null;

export const cacheAccessToken = async () => {
  accessToken = await localforage.getItem(ACCESS_TOKEN_KEY);
};

export const getAccessToken = async () => {
  if (accessToken === null) {
    accessToken = await localforage.getItem(ACCESS_TOKEN_KEY);
  }
  return accessToken;
};

export const setAccessToken = async newAccessToken => {
  await localforage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
  accessToken = newAccessToken;
};

export const removeAccessToken = async () => {
  await localforage.removeItem(ACCESS_TOKEN_KEY);
  accessToken = null;
};

export const getRefreshToken = async () => {
  if (refreshToken === null) {
    refreshToken = await localforage.getItem(REFRESH_TOKEN_KEY);
  }
  return refreshToken;
};

export const setRefreshToken = async newRefreshToken => {
  await localforage.setItem(REFRESH_TOKEN_KEY, newRefreshToken);
  refreshToken = newRefreshToken;
};

export const removeRefreshToken = async () => {
  await localforage.removeItem(REFRESH_TOKEN_KEY);
  refreshToken = null;
};

export const setLanguage = async newLanguage => {
  await localforage.setItem(LANGUAGE_KEY, newLanguage);
};

export const getCompanySlug = async () => {
  if (companySlug === null) {
    companySlug = await localforage.getItem(COMPANY_SLUG_KEY);
  }
  return companySlug;
};

export const setCompanySlug = newCompanySlug => {
  companySlug = newCompanySlug;
  return localforage.setItem(COMPANY_SLUG_KEY, newCompanySlug);
};

export const removeCompanySlug = () => {
  companySlug = null;
  return localforage.removeItem(COMPANY_SLUG_KEY);
};

export const getExpiresIn = async () => {
  if (expiresIn === null) {
    expiresIn = await localforage.getItem(EXPIRES_IN);
  }
  return expiresIn;
};

export const setExpiresIn = async newExpiresIn => {
  await localforage.setItem(EXPIRES_IN, newExpiresIn);
  expiresIn = newExpiresIn;
};

export const removeExpiresIn = async () => {
  await localforage.removeItem(EXPIRES_IN);
  expiresIn = null;
};
