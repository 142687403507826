import React from 'react';
import Tag from './Tag';
import Flex from './Flex';
import Label from './Label';

const FormItem = ({
  label = null,
  error = null,
  required,
  children,
  ...props
}) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    mb={5}
    style={{gap: '0.5rem'}}
    {...props}>
    {label !== null && <Label>{label}</Label>}
    <Flex flexDirection="column" justifyContent="center">
      {children}
      {error !== null && (
        <Flex flexDirection="column" justifyContent="center">
          {error.map((e, index) => {
            if (e !== null) {
              return (
                <Tag
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  variant={1}
                  color="red"
                  mt={1}>
                  {e}
                </Tag>
              );
            }
            return e;
          })}
        </Flex>
      )}
    </Flex>
  </Flex>
);

export default FormItem;
