import React from 'react';
import Styled from 'styled-components';
import {
  color,
  space,
  layout,
  shadow,
  border,
  flexbox,
  position,
  typography,
} from 'styled-system';

const StyledTextArea = Styled.textarea`
    ${color}
    ${space}
      ${layout}
      ${shadow}
      ${border}
      ${flexbox}
      ${position}
      ${typography}
      ${({noResize}) => (noResize ? `resize: none;` : '')}
  `;

const TextArea = props => (
  <StyledTextArea
    borderRadius={0}
    p={3}
    bg="grey.0"
    color="grey.5"
    fontFamily="regular"
    {...props}
  />
);

export default TextArea;
