import React, {useEffect} from 'react';
import {I18nProvider} from '@lingui/react';
import i18n from '../../locales';

const LanguageProvider = ({activeLanguage, children}) => {
  useEffect(() => i18n.activate(activeLanguage), [activeLanguage]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default LanguageProvider;
