import {camelize} from 'humps';
import {RestLink} from 'apollo-link-rest';
import {typePatcher} from 'apollo-type-patcher';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {setContext} from '@apollo/client/link/context';
import {ApolloLink, ApolloClient, InMemoryCache} from '@apollo/client';
import {
  isNotFoundError,
  isWrongInputError,
  isUnauthorizedError,
  isUnauthenticatedError,
} from '../utils/networking';
import typeDefinitions from './typeDefinitions';
import * as storage from '../utils/storage';
import getApiRoot from '../utils/getApiRoot';
import {MODEL_NOT_FOUND} from '../utils/constants';

const cache = new InMemoryCache();

const createRestLink = uri =>
  new RestLink({
    uri,
    fieldNameNormalizer: camelize,
    typePatcher: typePatcher(typeDefinitions),
    // Makes sure apollo-link-rest doesn't do any unwanted formatting on file body's
    bodySerializers: {
      fileEncode: (data, fileHeaders) => ({body: data, headers: fileHeaders}),
    },
    // Apollo-link-rest converts all formData objects to empty objects. Thats why all POST requests including formData should be constructed here.
    customFetch: async (fetchUri, options) => {
      let newOptions = {...options};
      const {body} = options;
      if (typeof body !== 'undefined') {
        const {mediaFile, mediaType} = body;
        if (typeof mediaFile !== 'undefined') {
          const formData = new FormData();
          formData.append('media_file', mediaFile);
          formData.append('media_type', mediaType);
          newOptions = {
            ...options,
            body: formData,
          };
        }
      }
      const response = await fetch(fetchUri, newOptions);
      if (response.status === 404) {
        return new Response(response.body, {
          status: 422,
          statusText: MODEL_NOT_FOUND,
          headers: response.headers,
        });
      }
      return response;
    },
    // If the response contains a single object it will be returned without the data wrapper for better caching
    responseTransformer: async response => {
      try {
        const jsonResponse = await response.json();
        const {data, meta} = jsonResponse;
        if (typeof data === 'undefined') {
          return jsonResponse;
        }
        if (typeof meta === 'undefined') {
          if (Array.isArray(data)) {
            return {data};
          }
          if (typeof data.data !== 'undefined') {
            return {data: data.data};
          }
          // There are edge cases where there are values next to 'data' and 'meta'
          // The next line will thus only return data from the response
          return data;
        }
        return {data, meta};
      } catch (error) {
        throw new Error(error);
      }
    },
  });

const createApolloClient = (uri, links = []) =>
  new ApolloClient({
    link: ApolloLink.from([...links, createRestLink(uri)]),
    cache,
  });

const authLink = setContext(
  ({headers = {}}) =>
    new Promise(resolve => {
      storage.getAccessToken().then(accessToken =>
        resolve({
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
          },
        }),
      );
    }),
);

const createErrorLink = onUnauthenticatedRequest =>
  onError(({forward, operation, networkError}) => {
    if (isUnauthenticatedError(networkError)) {
      return onUnauthenticatedRequest(forward, operation);
    }
    return null;
  });

const createRetryLink = onServerUnavailable =>
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: (count, operation, networkError) => {
      // Don't retry on unauthenticated errors. This is handled by the errorLink
      if (
        isNotFoundError(networkError) ||
        isWrongInputError(networkError) ||
        isUnauthorizedError(networkError) ||
        isUnauthenticatedError(networkError)
      ) {
        return false;
      }
      if (count >= 5) {
        onServerUnavailable();
        return false;
      }
      return true;
    },
  });

export function createUnAuthorizedApolloClient(onServerUnavailable) {
  const retryLink = createRetryLink(onServerUnavailable);
  const links = [retryLink];
  const apiRoot = getApiRoot();
  return createApolloClient(apiRoot, links);
}

export async function createAuthorizedApolloClient(
  onServerUnavailable,
  onUnauthenticatedRequest,
) {
  const retryLink = createRetryLink(onServerUnavailable);
  const errorLink = createErrorLink(onUnauthenticatedRequest);
  const links = [errorLink, authLink, retryLink];
  const apiRoot = getApiRoot();
  return createApolloClient(apiRoot, links);
}
