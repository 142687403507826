import {useMutation, gql} from '@apollo/client';
import DEFAULT_COMMENT from '../../../apollo/fragments/defaultComment';

export const UPDATE_COMMENT_MUTATION = gql`
  mutation updateCommentMutation(
    $messageId: ID!
    $commentId: ID!
    $input: UpdateCommentInput!
  ) {
    updateComment(messageId: $messageId, commentId: $commentId, input: $input)
      @rest(
        type: "Comment"
        path: "/user-messages/{args.messageId}/comments/{args.commentId}"
        method: "PUT"
      ) {
      ...DefaultComment
    }
  }
  ${DEFAULT_COMMENT}
`;

const useUpdateCommentMutation = () => useMutation(UPDATE_COMMENT_MUTATION);

export default useUpdateCommentMutation;
