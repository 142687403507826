import React from 'react';
import {useTheme} from 'styled-components';
import {useHistory} from 'react-router-dom';
import {LIST_TYPE_UNREAD, LIST_TYPE_READ} from '../../../utils/constants';
import PageLoading from '../../utils/PageLoading';
import useAppState from '../../hooks/useAppState';
import useCustomApp from '../../hooks/useCustomApp';
import usePermissions from '../../hooks/usePermissions';
import ErrorPageWithState from '../error/ErrorPageWithState';
import HomePage from './HomePage';
import useFetchHomePageData from './useFetchHomePageData';
import getUrlBySize, {FULL} from '../../../utils/sizeHelper';

const HomePageWithState = () => {
  const {
    hasSmallBanner,
    hidesNewDocuments,
    hidesNewColleagues,
    homeTitleIsInBanner,
  } = useCustomApp();
  const theme = useTheme();
  const {error, newFiles, isFetching, userMessages, newColleagues} =
    useFetchHomePageData();
  const history = useHistory();
  const {canViewColleagues, canViewCalendar} = usePermissions();
  const {currentUser, currentCompany, unreadCount} = useAppState();

  const handleOpenFile = file => window.open(getUrlBySize(file, FULL));

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }
  if (isFetching) {
    return <PageLoading />;
  }

  let companyBanner = null;
  if (currentCompany.settings.banner) {
    companyBanner = currentCompany.settings.banner.sizes.banner;
  }
  const {branding: {colors: companyColors} = {}} = currentCompany;

  return (
    <HomePage
      theme={theme}
      newFiles={newFiles}
      unreadCount={unreadCount}
      currentUser={currentUser}
      userMessages={userMessages}
      onOpenFile={handleOpenFile}
      companyBanner={companyBanner}
      companyColors={companyColors}
      newColleagues={newColleagues != null ? newColleagues : []}
      hasSmallBanner={hasSmallBanner}
      canViewCalendar={canViewCalendar}
      canViewDocuments={!hidesNewDocuments}
      homeTitleIsInBanner={homeTitleIsInBanner}
      canViewColleagues={canViewColleagues && !hidesNewColleagues}
      onNavigateToReadMessages={() =>
        history.push(`/messages/overview/${LIST_TYPE_READ}`)
      }
      onNavigateToUnreadMessages={() =>
        history.push(`/messages/overview/${LIST_TYPE_UNREAD}`)
      }
    />
  );
};

export default HomePageWithState;
