import React, {forwardRef} from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import Label from './Label';
import {StyledSvg} from './Icon';

const CheckBox = forwardRef(
  (
    {error, name, children, className, disabled = false, onChange, ...props},
    ref,
  ) => (
    <Flex as="label" alignItems="center" htmlFor={name}>
      <StyledCheckbox
        ref={ref}
        error={error}
        id={name}
        name={name}
        onChange={e => {
          e.stopPropagation();
          if (disabled) {
            e.preventDefault();
          } else {
            onChange(e);
          }
        }}
        {...props}
      />
      <StyledCheckMarkUnchecked disabled={disabled} />
      <StyledCheckMarkChecked />
      {typeof children !== 'undefined' && (
        <StyledLabel ml={2} variant={3} htmlFor={name} disabled={disabled}>
          {children}
        </StyledLabel>
      )}
    </Flex>
  ),
);

const CheckContainer = ({children, ...props}) => (
  <StyledSvg height="16" width="16" viewBox="0 0 16 16" {...props}>
    {children}
  </StyledSvg>
);

const CheckMarkUnchecked = props => (
  <CheckContainer {...props}>
    <rect x="1" y="1" width="14" height="14" rx="1" ry="1" />
  </CheckContainer>
);

const CheckMarkChecked = props => (
  <CheckContainer {...props}>
    <path d="M14,0H2C0.9,0,0,0.9,0,2v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V2C16,0.9,15.1,0,14,0z M7,11.4L3.6,8L5,6.6 l2,2l4-4L12.4,6L7,11.4z" />
  </CheckContainer>
);

const StyledLabel = styled(Label)(
  ({disabled}) => `
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
`,
);

const StyledCheckMarkUnchecked = styled(CheckMarkUnchecked)`
  display: block;
  fill: none;
  cursor: pointer;
  stroke: ${({theme, disabled}) =>
    disabled ? theme.colors.grey[3] : theme.colors.grey[5]};
  flex-shrink: 0;
`;

const StyledCheckMarkChecked = styled(CheckMarkChecked)`
  display: none;
  cursor: pointer;
  fill: ${({theme}) => theme.colors.primary};
  flex-shrink: 0;
`;

const StyledCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  :checked ~ ${StyledCheckMarkUnchecked} {
    display: none;
  }
  :checked ~ ${StyledCheckMarkChecked} {
    display: block;
  }
`;

export default CheckBox;
