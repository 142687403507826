import styled from 'styled-components';
import {color, space, shadow, layout, flexbox, typography} from 'styled-system';

const Text = styled.span`
  ${color}
  ${space}
    ${layout}
    ${shadow}
    ${flexbox}
    ${typography}
`;

export default Text;
