import React from 'react';
import Box from './Box';
import Link from './Link';
import Avatar from './Avatar';

const HeaderButton = ({
  icon,
  avatar = null,
  hasBadge = false,
  isActive = false,
  ...props
}) => (
  <Link {...props}>
    <Avatar
      width={40}
      height={40}
      avatar={avatar}
      iconName={icon}
      color={isActive ? 'primary' : 'greyDark'}
      border={isActive ? '3px solid' : 'none'}
      borderColor="primary">
      {hasBadge && (
        <Box
          position="absolute"
          width={14}
          height={14}
          top={-2}
          right={-2}
          bg="primary"
          borderRadius="50%"
        />
      )}
    </Avatar>
  </Link>
);

export default HeaderButton;
