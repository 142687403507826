import {useQuery, gql} from '@apollo/client';
import DEFAULT_MESSAGE_FRAGMENT from '../../../apollo/fragments/defaultUserMessage';

export const USER_MESSAGE_QUERY = gql`
  query userMessageQuery($id: ID!, $lang: LANG!) {
    userMessage(id: $id, lang: $lang)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}?lang={args.lang}"
        method: "GET"
      ) {
      ...DefaultMessage
    }
  }
  ${DEFAULT_MESSAGE_FRAGMENT}
`;

const useFetchMessageDetailsScreenData = messageId => {
  const urlParams = new URLSearchParams(window.location.search);
  const variables = {
    id: messageId,
    lang: urlParams.get('lang'),
  };

  const {
    data,
    error,
    loading: isFetching,
  } = useQuery(USER_MESSAGE_QUERY, {variables});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      userMessage: {},
      isFetching,
    };
  }

  const {userMessage} = data;

  return {
    userMessage,
    isFetching,
  };
};

export default useFetchMessageDetailsScreenData;
