import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import ProfileInfoRow from './ProfileInfoRow';
import ProfileInfoCard from './ProfileInfoCard';

const ProfileContactInformation = ({user, ...props}) => {
  const {
    workEmail = null,
    workPhone = null,
    workMobile = null,
    email = null,
    phone = null,
    mobile = null,
  } = user;
  const hasWorkContactInfo = workEmail || workPhone || workMobile;
  const hasPrivateContactInfo = email || phone || mobile;

  if (!hasWorkContactInfo && !hasPrivateContactInfo) {
    return null;
  }

  return (
    <Flex flexDirection="column" {...props}>
      <Title variant={2}>
        <Trans>Contact Information</Trans>
      </Title>
      {hasWorkContactInfo && (
        <Flex flexDirection="column" mt={7}>
          <Flex flexDirection="column" mb={3}>
            <Title variant={4}>
              <Trans>Work</Trans>
            </Title>
            <ProfileInfoRow
              info={workPhone}
              icon="phone"
              mt={6}
              to={`tel:${workPhone}`}
            />
            <ProfileInfoRow
              info={workMobile}
              icon="mobilePhone"
              mt={6}
              to={`tel:${workMobile}`}
            />
            <ProfileInfoRow
              info={workEmail}
              icon="email"
              mt={6}
              to={`mailto:${workEmail}`}
            />
          </Flex>
          <ProfileInfoCard mt={6}>
            <Trans>This data will be visible for your colleagues.</Trans>
          </ProfileInfoCard>
        </Flex>
      )}
      {hasPrivateContactInfo && (
        <Flex flexDirection="column" mt={7}>
          <Flex flexDirection="column" mb={3}>
            <Title variant={4}>
              <Trans>Private</Trans>
            </Title>
            <ProfileInfoRow
              info={phone}
              icon="phone"
              mt={6}
              to={`tel:${phone}`}
            />
            <ProfileInfoRow
              info={mobile}
              icon="mobilePhone"
              mt={6}
              to={`tel:${mobile}`}
            />
            <ProfileInfoRow
              info={email}
              icon="email"
              mt={6}
              to={`mailto:${email}`}
            />
          </Flex>
          <ProfileInfoCard mt={6}>
            <Trans>
              Private information is not visible to your colleagues and is only
              used to log you in.
            </Trans>
          </ProfileInfoCard>
        </Flex>
      )}
    </Flex>
  );
};

export default ProfileContactInformation;
