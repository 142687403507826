import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import useAppState from '../hooks/useAppState';
import Box from './Box';
import Navbar from './Navbar';
import Container from './Container';
import CompanyLogo from './CompanyLogo';
import HeaderButtons from './HeaderButtons';

const Header = ({...props}) => {
  const history = useHistory();
  const {currentUser, currentCompany} = useAppState();
  let companyLogo = null;
  if (
    currentCompany &&
    currentCompany.settings &&
    currentCompany.settings.logo
  ) {
    companyLogo = currentCompany.settings.logo.sizes.regular;
  }
  return (
    <Box top="0" bg="white" width="100%" zIndex={5} {...props}>
      <Container
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent={['center', 'space-between']}>
        <Box
          display={['flex', 'none']}
          flexDirection="row"
          justifyContent="space-between"
          my={6}>
          <CompanyLogoWithStyle
            onClick={() => history.push('/')}
            companyName={currentCompany.name}
            logoSrc={companyLogo}
          />
          <HeaderButtons
            avatar={currentUser?.avatar}
            display={['flex', 'none']}
          />
        </Box>
        <Box flex={1} display={['none', 'flex']}>
          <CompanyLogoWithStyle
            onClick={() => history.push('/')}
            companyName={currentCompany.name}
            logoSrc={companyLogo}
          />
        </Box>
        <Navbar flex={1} justifyContent={['space-between', 'center']} />
        <HeaderButtons
          flex={1}
          avatar={currentUser?.avatar}
          justifyContent="flex-end"
          display={['none', 'flex']}
        />
      </Container>
    </Box>
  );
};

const CompanyLogoWithStyle = styled(CompanyLogo)`
  cursor: pointer;
`;

export default Header;
