import React, {useState} from 'react';
import {whereIsNotId} from '../../../utils';
import ColleaguesPage from './ColleaguesPage';
import useFetchColleaguesPageData from './useFetchColleagesPageData';
import ErrorPageWithState from '../error/ErrorPageWithState';
import useDebounce from '../../hooks/useDebounce';

const ColleaguesPageWithState = () => {
  const [query, setQuery] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const debouncedQuery = useDebounce(query, 300);
  const {
    error,
    users,
    isFetching,
    isRefetching,
    isFetchingMore,
    hasMorePages,
    handleRefetch,
    handleFetchMore,
  } = useFetchColleaguesPageData(
    'first_name.asc',
    debouncedQuery,
    selectedDepartments,
  );

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  return (
    <ColleaguesPage
      isFetching={isFetching}
      isRefreshing={isRefetching}
      isFetchingMore={isFetchingMore}
      hasMorePages={hasMorePages}
      users={users}
      search={query}
      selectedDepartments={selectedDepartments}
      onChangeFilters={setSelectedDepartments}
      onRefetch={handleRefetch}
      onChangeSearch={setQuery}
      onFetchMore={handleFetchMore}
      onRemoveSelectedDepartment={({id}) =>
        setSelectedDepartments(selectedDepartments.filter(whereIsNotId(id)))
      }
    />
  );
};

export default ColleaguesPageWithState;
