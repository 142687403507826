import React from 'react';
import EditorBlockContainer from './EditorBlockContainer';
import Loader from '../../components/utils/Loader';

const EditorBlockLoading = ({...props}) => (
  <EditorBlockContainer
    display="flex"
    flexDirection="row"
    justifyContent="center"
    p={6}
    {...props}>
    <Loader color="primary" />
  </EditorBlockContainer>
);

export default EditorBlockLoading;
