import {useQuery, gql} from '@apollo/client';
import DEFAULT_SURVEY_FRAGMENT from '../fragments/defaultSurvey';

export const SURVEY_QUERY = gql`
  query surveyQuery($messageId: ID!, $surveyId: ID!) {
    survey(messageId: $messageId, surveyId: $surveyId)
      @rest(
        type: "Survey"
        path: "/user-messages/{args.messageId}/surveys/{args.surveyId}"
        method: "GET"
      ) {
      ...DefaultSurvey
    }
  }
  ${DEFAULT_SURVEY_FRAGMENT}
`;

const useFetchSurvey = ({surveyId, messageId}) => {
  const variables = {
    surveyId,
    messageId,
  };
  const {
    data = {},
    error,
    loading: isFetching,
  } = useQuery(SURVEY_QUERY, {variables});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      survey: {},
    };
  }

  const {survey} = data;

  return {
    isFetching,
    survey,
  };
};

export default useFetchSurvey;
