import {format, parseISO} from 'date-fns';

const FORMAT_DATE_STRING = 'yyyy-MM-dd';
const FORMAT_TIME_STRING = 'HH:mm';

export const getNow = () => new Date();

export const parseTime = timeString => {
  const dateTime = new Date();
  const [hours, minutes] = timeString.split(':');
  dateTime.setHours(Number(hours));
  dateTime.setMinutes(Number(minutes));
  dateTime.setSeconds(0);
  return dateTime;
};
export const parseDate = dateString => parseISO(dateString);

export const formatDate = (
  date,
  formatString = FORMAT_DATE_STRING,
  options = {},
) => format(date, formatString, options);
export const formatTime = (
  date,
  formatString = FORMAT_TIME_STRING,
  options = {},
) => format(date, formatString, options);
export const formatDateString = (isoString, formatString, options) =>
  formatDate(parseDate(isoString), formatString, options);
export const formatTimeString = (isoString, formatString, options) =>
  formatTime(parseDate(isoString), formatString, options);

export const DEFAULT_DATE_TIME_FORMAT = 'd MMMM HH:mm';
