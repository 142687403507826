import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Trans} from '@lingui/macro';
import {useMessagesFilter} from './hooks/MessagesFilters';
import Box from '../../utils/Box';
import Select from '../../utils/Select';
import Heading from '../../utils/Heading';
import Icon from '../../utils/Icon';
import Switch from '../../utils/Switch';
import Paragraph from '../../utils/Paragraph';
import useCurrentUser from '../../hooks/useCurrentUser';

const FilterBox = styled(Box)`
  width: 100%;
  /* background-color: ${({theme}) => theme.colors.grey[2]}; */
  border-color: ${({theme}) => theme.colors.grey[3]};
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
  gap: 1rem;
  border-radius: ${({theme}) => theme.radii[0]};
  transition: 0.4s ease all;
  ${({isOpened}) =>
    isOpened
      ? `
  `
      : `
    height: 0;
    opacity: 0;
    margin-top: -2.4rem;
    pointer-events: none;
  `}
`;

const mapFilterToSelect = language => appDomain => ({
  label: appDomain?.translations?.[language] || appDomain?.name,
  value: appDomain?.slug || appDomain?.name,
});
/// TODO: move to separate file
const Filters = ({filters, setFilters, isOpened}) => {
  const currentUser = useCurrentUser();
  const appDomainMapper = useCallback(mapFilterToSelect(currentUser.language), [
    currentUser.language,
  ]);
  const {appDomains} = useMessagesFilter();
  return (
    <FilterBox isOpened={isOpened}>
      <Heading flex={1} variant={4}>
        <Icon name="filter" />
        <Trans>Filters</Trans>
      </Heading>
      <Box ml={2}>
        <Select
          id="appDomains-filter"
          label={<Trans>Categories</Trans>}
          flexDirection="column"
          onChange={(newFilters = []) =>
            setFilters(oldFilters => ({
              ...oldFilters,
              app_domain: newFilters.map(({value}) => value),
            }))
          }
          isMulti
          options={appDomains.map(appDomainMapper)}
        />{' '}
      </Box>
      <Box ml={2}>
        <Switch
          checked={filters.onlyUnread}
          onChange={updatedValue => {
            setFilters(oldFilters => ({
              ...oldFilters,
              onlyUnread: updatedValue,
            }));
          }}>
          <Paragraph flex={1} variant={2} mr={2}>
            <Trans>Show only unread messages</Trans>
          </Paragraph>
        </Switch>
      </Box>
    </FilterBox>
  );
};
export default Filters;
