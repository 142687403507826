import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';
import WelcomePageContainer from './WelcomePageContainer';
import WelcomePageLanguageDropdownWithState from './WelcomePageLanguageDropdownWithState';

const WelcomePage = ({
  canUseWhatsApp,
  canUseEmailAuth,
  canUseSmsAuth,
  canUseAD,
  onNavigateToLogin,
  onADLogin,
  isADLoginLoading,
}) => (
  <>
    <WelcomePageLanguageDropdownWithState position="fixed" top={4} right={4} />
    <WelcomePageContainer
      title={<Trans>Welcome</Trans>}
      info={
        <>
          <Paragraph variant={2}>
            <Trans>
              Roger is a secure communication tool. Therefore we need you to
              confirm your identity on this device.
            </Trans>
          </Paragraph>
          <Paragraph variant={2}>
            <Trans>Choose how you want to receive your unique code.</Trans>
          </Paragraph>
        </>
      }>
      <Flex mb={5}>
        <>
          {canUseEmailAuth && (
            <Button
              mr={canUseSmsAuth ? 5 : 0}
              flex={1}
              onClick={() => onNavigateToLogin('email')}>
              <Icon mr={1} color="white" name="email" />
              <Trans>Email</Trans>
            </Button>
          )}
          {canUseSmsAuth && (
            <Button flex={1} onClick={() => onNavigateToLogin('sms')}>
              <Icon mr={1} color="white" name="chat" />
              <Trans>Sms</Trans>
            </Button>
          )}
        </>
      </Flex>
      <Flex>
        <>
          {canUseWhatsApp && (
            <Button
              flex={1}
              bg="whatsapp"
              onClick={() => onNavigateToLogin('whatsapp')}>
              <Icon mr={1} color="white" name="whatsapp" />
              <Trans>WhatsApp</Trans>
            </Button>
          )}
          {canUseAD && (
            <Button
              flex={1}
              bg="black"
              border="1px solid black"
              onClick={onADLogin}
              ml={canUseWhatsApp ? 5 : 0}
              isLoading={isADLoginLoading}>
              <Icon mr={1} color="none" name="microsoft" />
              Microsoft
            </Button>
          )}
        </>
      </Flex>
    </WelcomePageContainer>
  </>
);

export default WelcomePage;
