/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect, useCallback} from 'react';
import {useLingui} from '@lingui/react';
import debounce from 'lodash/debounce';
import Box from '../../utils/Box';
import Input from '../../utils/Input';
import Label from '../../utils/Label';
import Button from '../../utils/Button';
import FillIcon from '../../utils/FillIcon';
import SurveyBlockHeader from './SurveyBlockHeader';

const SURVEY_TYPE_OPTION = 'option';
const SurveyMultipleChoice = ({
  survey,
  onChange = null,
  selectedOption,
  isPublished,
  isPublishing,
}) => {
  const {locale} = useLingui();
  const [_selectedOption, _setSelectedOption] = useState(selectedOption);

  const {content, options} = survey;
  const selected = _selectedOption && _selectedOption[0];

  useEffect(() => {
    _setSelectedOption(selectedOption);
  }, [selectedOption]);

  const _updateOption = updatedOption => onChange([updatedOption]);
  const _debouncedUpdateOption = useCallback(
    debounce(_updateOption, 2.5 * 1000),
    [],
  );

  const _handleOptionInputChange = (option, value, withDebounce = true) => {
    const _updatedOption = {...option, answer: value};

    if (_selectedOption.value !== option.value) {
      _setSelectedOption([_updatedOption]);
    }

    if (onChange && value !== '') {
      if (withDebounce) _debouncedUpdateOption(_updatedOption);
      else _updateOption(_updatedOption);
    }
  };

  return (
    <>
      <SurveyBlockHeader
        question={content.question}
        description={content.description}
        isPublished={isPublished}
        isPublishing={isPublishing}
      />
      <Box pb={6} px={6}>
        {options.map((option, index) => {
          const isFirstOption = index === 0;
          const isLastOption = options.length === index + 1;
          const isSelectedOption =
            typeof selected !== 'undefined' && selected.value === option.value;
          const isOptionType =
            (option.type || SURVEY_TYPE_OPTION) === SURVEY_TYPE_OPTION;

          if (isOptionType) {
            return (
              <Button
                key={option.value}
                pb={isLastOption ? '0' : 4}
                pt={isFirstOption ? '0' : 4}
                width="100%"
                variant="unstyled"
                borderBottomWidth={isLastOption ? '0' : 1}
                borderBottomColor="grey.1"
                flexDirection="row"
                alignItems="center"
                onClick={() => onChange([option])}
                disabled={onChange === null}>
                <FillIcon name={isSelectedOption ? 'checkRound' : 'circle'} />
                <Label ml={3} variant={3} selected={isSelectedOption}>
                  {option.label || option}
                </Label>
              </Button>
            );
          }

          const _inputValue =
            _selectedOption[0]?.value === option.value
              ? _selectedOption[0]?.answer || ''
              : '';
          let placeholder = '';

          if (typeof option.label === 'object') {
            if (option.label.hasOwnProperty(locale)) {
              placeholder = option.label[locale];
            } else {
              // Just take the first one
              [placeholder] = Object.values(option.label);
            }
          } else {
            // It's just a string
            placeholder = option.label;
          }

          return (
            <Box
              key={option.value}
              pb={isLastOption ? '0' : 4}
              pt={isFirstOption ? '0' : 4}
              width="100%"
              borderBottomWidth={isLastOption ? '0' : 1}
              borderBottomColor="grey.1">
              <FillIcon
                onClick={() =>
                  _handleOptionInputChange(option, option?.answer, false)
                }
                name={isSelectedOption ? 'checkRound' : 'circle'}
              />
              <Input
                ml="1rem"
                width="80%"
                onClick={() => _handleOptionInputChange(option, _inputValue)}
                key={option.value}
                placeholder={placeholder}
                disabled={onChange === null}
                value={_inputValue}
                onChange={e =>
                  _handleOptionInputChange(option, e.target.value, true)
                }
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default SurveyMultipleChoice;
