import React from 'react';
import Header from '../../utils/Header';
import Container from '../../utils/Container';
import ExternalCalendar from '../../utils/ExternalCalendar';
import Page from '../../utils/Page';

const CalendarPage = () => (
  <>
    <Header />
    <Page>
      <Container>
        <ExternalCalendar height="100%" />
      </Container>
    </Page>
  </>
);

export default CalendarPage;
