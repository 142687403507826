import React, {useState} from 'react';
import {whereIsNotId} from '../../../utils';
import {useFetchReadMessages} from '../../../apollo/queries/getReadMessages';
import ErrorPageWithState from '../error/ErrorPageWithState';
import ReadMessagesPage from './ReadMessagesPage';
import useDebounce from '../../hooks/useDebounce';
import {useMessagesFilter} from './hooks/MessagesFilters';

const ReadMessagesPageWithState = () => {
  const [query, setQuery] = useState('');
  const {
    statusFilter,
    onChangeStatusFilter,
    selectedAppDomains = [],
    selectedFilters,
    setSelectedAppDomains,
  } = useMessagesFilter();
  const debouncedQuery = useDebounce(query, 300);
  const {
    error,
    isFetching,
    isFetchingMore,
    hasMorePages,
    fetchMore,
    userMessages,
  } = useFetchReadMessages(selectedFilters, debouncedQuery);

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }
  return (
    <ReadMessagesPage
      userMessages={userMessages}
      isFetching={isFetching}
      isFetchingMore={isFetchingMore}
      hasMorePages={hasMorePages}
      onFetchMore={fetchMore}
      statusFilter={statusFilter}
      onChangeStatusFilter={onChangeStatusFilter}
      selectedAppDomains={selectedAppDomains}
      onChangeFilters={setSelectedAppDomains}
      search={query}
      onChangeSearch={setQuery}
      onRemoveSelectedAppDomain={({id}) =>
        setSelectedAppDomains(selectedAppDomains.filter(whereIsNotId(id)))
      }
    />
  );
};

export default ReadMessagesPageWithState;
