import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {
  FILE_TYPE_IMAGE,
  FILE_TYPE_WORD,
  FILE_TYPE_EXCEL,
  FILE_TYPE_POWERPOINT,
  FILE_TYPE_SPREADSHEET,
  FILE_TYPE_AUDIO,
  FILE_TYPE_VIDEO,
  FILE_TYPE_PDF,
  FILE_TYPE_DEFAULT,
} from '../../../utils/constants';

import Tag from '../../utils/Tag';
import Flex from '../../utils/Flex';
import Label from '../../utils/Label';
import Icon from '../../../components/utils/Icon';

export const getFileTypeFromFileName = fileName => {
  if (typeof fileName === 'undefined' || fileName === null) {
    return FILE_TYPE_DEFAULT;
  }
  const extension = fileName.split('.').pop();

  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return FILE_TYPE_IMAGE;
    case 'doc':
    case 'docx':
      return FILE_TYPE_WORD;
    case 'xls':
    case 'xlsx':
      return FILE_TYPE_EXCEL;
    case 'ppt':
    case 'pptx':
      return FILE_TYPE_POWERPOINT;
    case 'csv':
      return FILE_TYPE_SPREADSHEET;
    case 'mp3':
    case 'wav':
    case 'aac':
    case 'm4a':
      return FILE_TYPE_AUDIO;
    case 'mp4':
    case 'ogv':
    case 'webm':
    case 'weba':
      return FILE_TYPE_VIDEO;
    case 'pdf':
      return FILE_TYPE_PDF;
    default:
      return FILE_TYPE_DEFAULT;
  }
};

const FileBlock = ({file, onOpenFile, ...props}) => {
  let icon;
  const [fileName] = file.fileName.split('.');
  const fileType = getFileTypeFromFileName(file.fileName);
  switch (fileType) {
    case FILE_TYPE_IMAGE:
      icon = 'image';
      break;
    case FILE_TYPE_WORD:
      icon = 'word';
      break;
    case FILE_TYPE_SPREADSHEET:
    case FILE_TYPE_EXCEL:
      icon = 'spreadsheet';
      break;
    case FILE_TYPE_POWERPOINT:
      icon = 'powerpoint';
      break;
    case FILE_TYPE_PDF:
      icon = 'pdf';
      break;

    default:
      icon = 'document';
  }
  if (fileType === FILE_TYPE_IMAGE) {
    icon = 'image';
  }

  return (
    <StyledAnimatedCard
      py={5}
      px={6}
      mb={6}
      width="100%"
      borderRadius={0}
      bg="white"
      alignItems="center"
      boxShadow={0}
      whileTap={{scale: 1}}
      whileHover={{scale: 1.01}}
      onClick={onOpenFile}
      {...props}>
      <Flex flex={1} flexDirection="row" alignItems="center" mr={2}>
        <Icon name={icon} color="grey" noStroke />
        <Flex flexDirection="column" justifyContent="center" ml={3} flex={1}>
          <Label variant={1} textAlign="left">
            {fileName}
          </Label>
          <Flex>
            <Tag variant={1} mr={3}>
              {fileType}
            </Tag>
            <Tag variant={1}>{file.fileSize}</Tag>
          </Flex>
        </Flex>
      </Flex>
    </StyledAnimatedCard>
  );
};

const AnimatedCard = motion.custom(Flex);
export const StyledAnimatedCard = styled(AnimatedCard)`
  cursor: pointer;
`;

export default FileBlock;
