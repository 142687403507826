import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import Avatar, {AVATAR_SIZE_LARGE} from '../../utils/Avatar';
import ProfilePageContainer from '../settings/ProfilePageContainer';
import Heading from '../../utils/Heading';
import Title from '../../utils/Title';
import Paragraph from '../../utils/Paragraph';
import ProfileInfoRow from '../settings/ProfileInfoRow';

const ColleagueDetailsPage = ({user}) => {
  const {
    avatar,
    firstName,
    lastName,
    description,
    segmentGroups,
    workEmail = null,
    workPhone = null,
    workMobile = null,
  } = user;
  const hasWorkContactInfo =
    workEmail !== null || workPhone !== null || workMobile !== null;
  return (
    <>
      <Header mb={7} />
      <ProfilePageContainer>
        <Flex
          width={AVATAR_SIZE_LARGE}
          position="relative"
          height={AVATAR_SIZE_LARGE}
          mt={-AVATAR_SIZE_LARGE / 2}
          mx="auto">
          <Avatar avatar={avatar} size="large" />
        </Flex>
        <Heading variant={2} textAlign="center" my={6}>
          {`${firstName} ${lastName}`}
        </Heading>
        {description !== null && (
          <Flex mt={7} flexDirection="column">
            <Title variant={2} mb={6}>
              <Trans>Description</Trans>
            </Title>
            <Paragraph variant={1} color="grey.5">
              {description}
            </Paragraph>
          </Flex>
        )}
        {segmentGroups.length > 0 &&
          segmentGroups.map(group => (
            <Flex mt={7} flexDirection="column" key={group.slug}>
              <Title variant={2} mb={3}>
                {group.name}
              </Title>
              <Paragraph variant={1} color="grey.5">
                {(group.segments.map(segment => segment.name) || []).join(', ')}
              </Paragraph>
            </Flex>
          ))}
        <Flex mt={7} flexDirection="column">
          <Title variant={2}>
            <Trans>Contact Information</Trans>
          </Title>
          {hasWorkContactInfo && (
            <Flex flexDirection="column" mb={3}>
              <ProfileInfoRow
                info={workPhone}
                icon="phone"
                mt={6}
                to={`tel:${workPhone}`}
              />
              <ProfileInfoRow
                info={workMobile}
                icon="mobilePhone"
                mt={6}
                to={`tel:${workMobile}`}
              />
              <ProfileInfoRow
                info={workEmail}
                icon="email"
                mt={6}
                to={`mailto:${workEmail}`}
              />
            </Flex>
          )}
        </Flex>
      </ProfilePageContainer>
    </>
  );
};

export default ColleagueDetailsPage;
