import React from 'react';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import defaultTheme from '../../utils/theme';
import {pixelsToRem} from '../../utils/index';
import useAppState from '../hooks/useAppState';

const createCompanyTheme = (company = {}) => {
  let colorPrimary;
  if (
    company !== null &&
    typeof company.settings !== 'undefined' &&
    typeof company.settings.brandingColor !== 'undefined'
  ) {
    colorPrimary = company.settings.brandingColor;
  }
  if (typeof colorPrimary !== 'undefined') {
    return {
      ...defaultTheme,
      colors: {
        // eslint-disable-next-line import/no-named-as-default-member
        ...defaultTheme.colors,
        primary: colorPrimary,
      },
    };
  }
  return defaultTheme;
};

const mapTheme = theme => {
  const {space, radii, fontSizes} = theme;
  return {
    ...theme,
    space: space.map(pixelsToRem),
    radii: radii.map(pixelsToRem),
    fontSizes: fontSizes.map(pixelsToRem),
  };
};

const ThemeProvider = ({children}) => {
  const {currentCompany} = useAppState();
  const theme = mapTheme(createCompanyTheme(currentCompany));
  return (
    <StyledComponentsThemeProvider theme={theme}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
