import React from 'react';
import Box from '../../utils/Box';
import Tag from '../../utils/Tag';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Date from '../../utils/Date';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';
import CheerButton from '../../utils/CheerButton';
import CommentInput from './UserCommentInput';
import UserCommentActionsDropdown from './UserCommentActionsDropdown';

const UserComment = ({
  comment,
  isEditing,
  onCheerComment,
  onEditComment,
  onDeleteComment,
  onNavigateToMessage,
  onCancelEditComment,
  onSubmitEditComment,
}) => {
  const {text, createdAt, isCheered, cheerCount, userMessage} = comment;

  return (
    <Box p={6} mb={6} bg="white" boxShadow={0} borderRadius={0}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={5}>
        <Button variant="unstyled" onClick={onNavigateToMessage}>
          <Box textAlign="left">
            <Title variant={4}>{userMessage.title}</Title>
            <Tag variant={2} mt={0}>
              <Date value={createdAt} />
            </Tag>
          </Box>
        </Button>
        <Flex alignItems="center">
          <UserCommentActionsDropdown
            comment={comment}
            onEditComment={onEditComment}
            onCheerComment={onCheerComment}
            onDeleteComment={onDeleteComment}
            onNavigateToMessage={onNavigateToMessage}>
            <Button variant="unstyled" mr={1}>
              <Icon color="grey" name="menuDots" />
            </Button>
          </UserCommentActionsDropdown>
          <CheerButton
            key={isCheered}
            isCheered={isCheered}
            onCheer={onCheerComment}
            cheerCount={cheerCount}
          />
        </Flex>
      </Flex>
      {isEditing ? (
        <CommentInput
          isClearable={false}
          value={comment.text}
          onSubmitComment={onSubmitEditComment}
          onCancelComment={onCancelEditComment}
        />
      ) : (
        <Paragraph mb="none" variant={2}>
          {text}
        </Paragraph>
      )}
    </Box>
  );
};

export default UserComment;
