import {useMutation, gql} from '@apollo/client';

export const LOGIN_OTP_MUTATION = gql`
  mutation loginOtpMutation($body: UserBodyInput!) {
    loginOtp(input: $body)
      @rest(
        type: "LoginOtpResponse"
        path: "/clients/web/token"
        method: "POST"
      ) {
      tokenType
      expiresIn
      accessToken
      refreshToken
    }
  }
`;

const useLoginOtpMutation = () => useMutation(LOGIN_OTP_MUTATION);

export default useLoginOtpMutation;
