import React from 'react';
import {Trans} from '@lingui/macro';
import Icon from '../../utils/Icon';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import Button from '../../utils/Button';
import Loader from '../../utils/Loader';
import EmptyList from '../../utils/EmptyList';
import FileListItem from './FileListItem';
import DirectoryListItem from './DirectoryListItem';
import Page, {PageContentScrollable} from '../../utils/Page';
import Heading from '../../utils/Heading';

const DocumentsPage = ({
  isFetching,
  documents,
  parentDirectory,
  onOpenDirectory,
  onGoUpOneDirectory,
  onOpenFile,
  error,
}) => {
  const renderEmptyDocuments =
    (!isFetching && !documents) ||
    (!isFetching && error) ||
    (!isFetching && documents.length === 0);

  return (
    <>
      <Header />
      <Page>
        <Heading variant={1}>
          <Trans>Documents</Trans>
        </Heading>
        <PageContentScrollable>
          {parentDirectory !== null && (
            <Flex mb={6} flexDirection="row" alignItems="center">
              <Icon name="arrowBackUp" strokeWidth={2.25} />
              <Button variant="link2" ml={3} onClick={onGoUpOneDirectory}>
                <Trans>Back up one level</Trans>
              </Button>
            </Flex>
          )}
          <Flex flexDirection="column">
            {isFetching && (
              <Flex height="50vh" alignItems="center" justifyContent="center">
                <Loader color="primary" />
              </Flex>
            )}
            {renderEmptyDocuments ? (
              <EmptyList
                icon="document"
                title={<Trans id="You don't have any documents yet." />}
                subTitle={
                  <Trans>
                    Documents might become available when you return later.
                  </Trans>
                }
              />
            ) : (
              documents?.map(document => (
                <React.Fragment key={document.id}>
                  {document.isDirectory ? (
                    <DirectoryListItem
                      directory={document}
                      onOpenDirectory={() => onOpenDirectory(document)}
                    />
                  ) : (
                    <FileListItem
                      file={document}
                      onOpenFile={() => onOpenFile(document)}
                    />
                  )}
                </React.Fragment>
              ))
            )}
          </Flex>
        </PageContentScrollable>
      </Page>
    </>
  );
};

export default DocumentsPage;
