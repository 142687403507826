import React from 'react';
import * as Sentry from '@sentry/react';
import useAppState from './hooks/useAppState';
import AppRouter from './AppRouter';
import AppLoading from './AppLoading';
import AppNotFound from './AppNotFound';
import AppUnavailable from './AppUnavailable';

const App = () => {
  const {
    refresh,
    isLoading,
    currentCompany,
    appNeedsUpdate,
    isAppAvailable,
    isAuthenticated,
  } = useAppState();

  if (isLoading) {
    return <AppLoading />;
  }

  if (!isAppAvailable) {
    return <AppUnavailable onReloadApp={refresh} />;
  }

  if (appNeedsUpdate) {
    // TODO
    return null;
  }

  if (currentCompany === null) {
    return <AppNotFound />;
  }

  return <AppRouter isLoading={isLoading} isAuthenticated={isAuthenticated} />;
};

export default Sentry.withProfiler(App);
