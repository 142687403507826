import styled from 'styled-components';
import Box from './Box';

const Page = styled(Box)`
  display: flex;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  max-width: 102.4rem;
  align-self: center;
  padding: ${({theme}) => theme.space[4]};
  margin-top: ${({theme}) => theme.space[4]};
  gap: ${({theme}) => theme.space[4]};
`;

export const PageContentScrollable = styled(Box)`
  > * {
    width: 100%;
  }
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const PageContentFixed = styled(Box)`
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  > * {
    width: 100%;
  }
`;

export default Page;
