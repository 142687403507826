import React from 'react';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Paragraph from '../../utils/Paragraph';

const ProfileInfoRow = ({to, info = null, icon, ...props}) => {
  if (!info) {
    return null;
  }

  return (
    <a href={to}>
      <Flex flexDirection="row" alignItems="center" {...props}>
        <Icon name={icon} />
        <Paragraph variant={1} ml={3} pt={3}>
          {info}
        </Paragraph>
      </Flex>
    </a>
  );
};

export default ProfileInfoRow;
