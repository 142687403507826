import * as React from 'react';

const AnonymousIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}>
    <g fill="#64748B" clipPath="url(#a)">
      <path d="M2.104 7.826H4.57L7.135.45c.103-.28.28-.45.47-.45.504.61 1.01.902 1.517.902.507 0 1.043-.31 1.56-.902.191 0 .368.17.471.451l2.555 7.375h3.188c.61 0 1.104.323 1.104 1.191 0 .868-.494 1.571-1.104 1.571H2.104C1.494 10.588 1 9.885 1 9.017s.494-1.19 1.104-1.19Zm4.318-2.492c0 .293.304.53.68.53h4.317c.375 0 .68-.237.68-.53v-.232c0-.293-.305-.53-.68-.455H7.102c-.376-.076-.68.162-.68.455v.232ZM14.11 16.102l1.024 1.402a.53.53 0 0 1-.04.66.358.358 0 0 1-.257.113.368.368 0 0 1-.298-.161l-1.024-1.403a.53.53 0 0 1 .04-.66c.164-.168.412-.147.555.048Z" />
      <path d="M4.24 12.145c1.702 0 3.143 1.507 3.343 3.46h3.95c.197-1.954 1.612-3.46 3.286-3.46 1.825 0 3.31 1.762 3.31 3.928 0 2.165-1.485 3.927-3.31 3.927-1.578 0-2.952-1.356-3.25-3.18H7.546C7.243 18.645 5.846 20 4.239 20 2.382 20 .871 18.238.871 16.073c0-2.166 1.511-3.928 3.368-3.928Zm10.579 6.64c1.26 0 2.285-1.217 2.285-2.712 0-1.496-1.025-2.712-2.285-2.712-.523 0-1.004.21-1.389.56a.357.357 0 0 1 .38.15l1.024 1.402a.53.53 0 0 1-.04.66.358.358 0 0 1-.257.114.368.368 0 0 1-.298-.162l-1.024-1.403a.536.536 0 0 1-.08-.441 2.994 2.994 0 0 0-.6 1.83c0 1.496 1.025 2.712 2.285 2.712l-.001.001Zm-10.58 0c1.286 0 2.33-1.217 2.33-2.712 0-1.496-1.045-2.712-2.33-2.712-1.284 0-2.33 1.216-2.33 2.712 0 1.495 1.046 2.711 2.33 2.711Z" />
      <path d="m3.967 13.803 1.024 1.403a.53.53 0 0 1-.04.659.358.358 0 0 1-.257.114.368.368 0 0 1-.298-.162l-1.024-1.402a.53.53 0 0 1 .04-.66c.163-.169.413-.147.555.047v.001ZM4.267 15.834l1.024 1.403a.53.53 0 0 1-.04.659.358.358 0 0 1-.257.114.368.368 0 0 1-.298-.162l-1.024-1.402a.53.53 0 0 1 .04-.66c.163-.169.412-.147.555.047v.001Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M19 0H0v20h19z" />
      </clipPath>
    </defs>
  </svg>
);
export default AnonymousIcon;
