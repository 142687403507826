import React from 'react';
import {Trans} from '@lingui/macro';
import {isToday, parseISO} from 'date-fns';
import useLocalizedDateFunctions from '../hooks/useLocalizedDateFunctions';
import {DEFAULT_DATE_TIME_FORMAT} from '../../utils/dates';

const Date = ({
  language,
  value,
  format = DEFAULT_DATE_TIME_FORMAT,
  options,
}) => {
  const {formatDateString} = useLocalizedDateFunctions(language);
  if (!isToday(parseISO(value))) {
    return formatDateString(value, format, options);
  }

  return (
    <>
      <Trans>Today</Trans> {formatDateString(value, 'HH:mm')}
    </>
  );
};

export default Date;
