import {gql} from '@apollo/client';

export const UNREAD_COUNT_QUERY = gql`
  query unreadCountQuery {
    unreadCount
      @rest(
        type: "UnreadCount"
        path: "/user-messages/stats-unread"
        method: "GET"
      ) {
      count
    }
  }
`;

export default async function getUnreadMessagesCount(apollo, options) {
  const query = UNREAD_COUNT_QUERY;
  const response = await apollo.query({query, ...options});
  return response.data.unreadCount.count;
}
