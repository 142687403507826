import {useQuery, gql} from '@apollo/client';

export const PROFILE_SCREEN_QUERY = gql`
  query profileScreenQuery {
    channels
      @rest(
        type: "Channels"
        path: "/notifications/configured-channels"
        method: "GET"
      ) {
      data
    }
    userChannels
      @rest(
        type: "UserChannels"
        path: "/user-notifications/configured-channels"
        method: "GET"
      ) {
      data
    }
  }
`;

const useFetchProfilePageData = () => {
  const {error, loading, data, updateQuery} = useQuery(PROFILE_SCREEN_QUERY);

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (loading) {
    return {
      isFetching: true,
      channels: [],
      userChannels: [],
    };
  }

  const {
    channels: {data: channels},
    userChannels: {data: userChannels},
  } = data;

  return {
    isFetching: false,
    updateQuery,
    channels,
    userChannels,
  };
};

export default useFetchProfilePageData;
