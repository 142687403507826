import React from 'react';
import {Trans} from '@lingui/macro';
import Button from './utils/Button';
import WelcomePageContainer from './pages/welcome/WelcomePageContainer';

const AppUnavailable = ({onReloadApp}) => (
  <WelcomePageContainer
    title={<Trans id="The app is currently not available." />}>
    <Button
      onClick={onReloadApp}
      borderRadius={0}
      borderWidth={1}
      borderColor="white">
      <Trans>Reload app</Trans>
    </Button>
  </WelcomePageContainer>
);

export default AppUnavailable;
