import {useMutation, gql} from '@apollo/client';

export const UNCHEER_USER_MESSAGE_MUTATION = gql`
  mutation uncheerUserMessageMutation($id: ID!) {
    uncheerUserMessage(input: {}, id: $id)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}/uncheer"
        method: "POST"
      ) {
      id
      isCheered
      cheerCount
    }
  }
`;

export default function useUncheerUserMessageMutation() {
  return useMutation(UNCHEER_USER_MESSAGE_MUTATION);
}
