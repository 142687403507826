import React from 'react';
import Tag from './Tag';
import Icon from './Icon';
import Button from './Button';

const CommentButton = ({commentsCount, onClick, ...props}) => (
  <Button
    className="commentButton"
    variant="unstyled"
    flexDirection="row"
    alignItems="center"
    borderRadius={0}
    py={1}
    px={2}
    onClick={e => {
      e.stopPropagation();
      onClick();
    }}
    {...props}>
    {commentsCount > 0 && (
      <Tag mr={1} variant={1} color="grey.5">
        {commentsCount}
      </Tag>
    )}
    <Icon name="comments" color="greyDark" />
  </Button>
);

export default CommentButton;
