import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {DEFAULT_LIST_PAGE, DEFAULT_PAGE_LIMIT} from '../../../utils/constants';

export const COLLEAGUES_QUERY = gql`
  query colleaguesQuery(
    $page: Int
    $limit: Int
    $query: String
    $sort: String
    $departments: [Int]
  ) {
    users(
      page: $page
      limit: $limit
      query: $query
      sort: $sort
      departments: $departments
    ) @rest(type: "Users", path: "/colleagues?{args}", method: "GET") {
      data {
        id
        uuid
        firstName
        lastName
        language
        workEmail
        workPhone
        workMobile
        description
        isOnline
        commentsCount
        createdAt
        updatedAt
        deletedAt
        email
        phone
        mobile
        birthdate
        firstDayOfWork
        onboardingType
        onboardingAt
        # Not fetching the id of segmentGroups because it makes the cache behave strangely.
        # All users have the same segment group with id 8 which are the departments. The first
        # users' departments get cached and then the cache just assumes that all other users
        # have the same departments because the segmentGroup has the same id. This could be
        # solved by adding unique identifiers for every segmentGroup nested inside a user.
        segmentGroups {
          name
          slug
          createdAt
          updatedAt
          segments {
            id
            name
            slug
            createdAt
            updatedAt
          }
        }
        roles {
          name
          slug
          label
          createdAt
          updatedAt
        }
        avatar {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            regular
          }
          customProperties {
            generatedConversions {
              thumb
              regular
            }
          }
        }
      }
      meta {
        currentPage
        from
        lastPage
        path
        perPage
        to
        total
      }
    }
  }
`;

const useFetchColleaguesPageData = (sort, query, selectedDepartments) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const variables = {
    sort,
    page: DEFAULT_LIST_PAGE,
    limit: DEFAULT_PAGE_LIMIT,
    query: query === '' ? undefined : query,
    departments: selectedDepartments.map(({id}) => id),
  };
  const {
    error,
    loading: isFetching,
    data,
    refetch,
    fetchMore,
  } = useQuery(COLLEAGUES_QUERY, {variables});

  if (typeof error !== 'undefined') {
    return {
      error,
      isFetching,
    };
  }

  if (isFetching) {
    return {
      users: [],
      isFetching,
    };
  }

  const {
    users: {
      data: users,
      meta: {currentPage, lastPage},
    },
  } = data;

  const hasMorePages = currentPage < lastPage;
  const handleFetchMore = async () => {
    if (!isFetchingMore && hasMorePages) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            users: {
              ...fetchMoreResult.users,
              data: [...prev.users.data, ...fetchMoreResult.users.data],
            },
          };
        },
      });
      setIsFetchingMore(false);
    }
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  return {
    users,
    isFetching,
    isRefetching,
    isFetchingMore,
    hasMorePages,
    handleRefetch,
    handleFetchMore,
  };
};

export default useFetchColleaguesPageData;
