import {useMemo, useState} from 'react';
import {useRogerFetch} from '../../components/hooks/RogerFetch';
import useCompany from '../../components/hooks/useCompany';

function snakeCase(str) {
  return str
    .replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
    .replace(/^_/, '');
}
const useAdminMessages = () => {
  const {authFetch} = useRogerFetch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const company = useCompany();
  const forms = useMemo(
    () =>
      Object.entries(company.settings.adminInbox.forms).reduce(
        (accumulator, [key, {enabled}]) => {
          if (!enabled) return accumulator;
          return [...accumulator, snakeCase(key)];
        },
        [],
      ),
    [company],
  );
  const postAdminInboxMessage = async form => {
    setIsLoading(true);

    setError(null);

    try {
      const response = await authFetch('/admin-inbox/messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      setIsLoading(false);
      return response;
    } catch (e) {
      setIsLoading(false);
      setError(e);
      throw new Error('Failed to post message');
    }
  };

  return {postAdminInboxMessage, isLoading, error, forms};
};

export default useAdminMessages;
