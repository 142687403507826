import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import Icon from '../../utils/Icon';
import Flex from '../../utils/Flex';
import Label from '../../utils/Label';

const ToolListItem = ({tool, onOpenTool, ...props}) => (
  <StyledAnimatedCard
    py={5}
    px={6}
    mb={6}
    bg="white"
    width="100%"
    boxShadow={0}
    borderRadius={0}
    alignItems="center"
    whileTap={{scale: 1}}
    whileHover={{scale: 1.01}}
    onClick={() => onOpenTool(tool)}
    {...props}>
    <Flex mr={2} flex={1} flexDirection="row" alignItems="center">
      <Icon name="link" color="grey" />
      <Label ml={3} variant={1} textAlign="left">
        {tool.title}
      </Label>
    </Flex>
  </StyledAnimatedCard>
);

const AnimatedCard = motion.custom(Flex);
const StyledAnimatedCard = styled(AnimatedCard)`
  cursor: pointer;
`;

export default ToolListItem;
