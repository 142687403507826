import React from 'react';
import {Trans} from '@lingui/macro';
import Box from '../../utils/Box';
import Paragraph from '../../utils/Paragraph';

const MediaBlockNotFound = () => (
  <Box p={5} bg="grey.2" borderRadius={0}>
    <Paragraph variant={2} alignItems="center" textAlign="center">
      <Trans>This media item can not be found.</Trans>
      {'\n'}
      <Trans>Our apologies for the inconvenience.</Trans>
    </Paragraph>
  </Box>
);

export default MediaBlockNotFound;
