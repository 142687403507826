import React from 'react';
import styled from 'styled-components';
import {
  color,
  space,
  layout,
  position,
  flexbox,
  shadow,
  border,
} from 'styled-system';

const ImageWithStyles = styled.img`
  ${color}
  ${space}
    ${layout}
    ${position}
    ${flexbox}
    ${shadow}
    ${border}
`;

const Image = ({...props}) => <ImageWithStyles {...props} />;

export default Image;
