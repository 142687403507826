import React from 'react';
import styled from 'styled-components';
import {
  color,
  space,
  layout,
  shadow,
  border,
  flexbox,
  position,
  typography,
} from 'styled-system';

export const StyledInput = styled.input`
  ${color}
  ${space}
    ${layout}
    ${shadow}
    ${border}
    ${flexbox}
    ${position}
    ${typography}
`;

const Input = props => (
  <StyledInput
    type="text"
    borderRadius={0}
    p={3}
    bg="grey.2"
    color="grey.5"
    fontFamily="regular"
    {...props}
  />
);

export default Input;
