import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import Dropdown from '../../utils/Dropdown';

const UserCommentActionsDropdown = ({
  comment,
  onEditComment,
  onCheerComment,
  onDeleteComment,
  onNavigateToMessage,
  children,
  ...props
}) => {
  const [actionsModalisOpen, setActionsModalisOpen] = useState(false);
  const {isCheered} = comment;

  return (
    <Dropdown
      isOpen={actionsModalisOpen}
      setIsOpen={setActionsModalisOpen}
      listItems={
        <Dropdown.List>
          <Dropdown.NavigationItem
            onClick={onNavigateToMessage}
            iconName="chat">
            <Trans>Go to message details</Trans>
          </Dropdown.NavigationItem>
          <Dropdown.DropdownListItem
            onClick={() => {
              onCheerComment(comment);
              setActionsModalisOpen(false);
            }}
            iconName="cheer">
            {isCheered ? (
              <Trans>Uncheer this comment</Trans>
            ) : (
              <Trans>Cheer this comment</Trans>
            )}
          </Dropdown.DropdownListItem>
          <Dropdown.DropdownListItem
            onClick={() => {
              onEditComment(comment);
              setActionsModalisOpen(false);
            }}
            iconName="pen">
            <Trans>Edit comment</Trans>
          </Dropdown.DropdownListItem>
          <Dropdown.DropdownListItem
            onClick={() => {
              setActionsModalisOpen(false);
              onDeleteComment(comment);
            }}
            iconName="bin">
            <Trans>Delete comment</Trans>
          </Dropdown.DropdownListItem>
        </Dropdown.List>
      }
      {...props}>
      {children}
    </Dropdown>
  );
};

export default UserCommentActionsDropdown;
