import React, {createContext} from 'react';
import {withI18n} from '@lingui/react';

export const LinguiContext = createContext();

const {Provider} = LinguiContext;

const LinguiProvider = ({i18n, ...props}) => (
  <Provider value={i18n} {...props} />
);

export default withI18n()(LinguiProvider);
