import React from 'react';
import useCompany from '../../hooks/useCompany';
import ProfileLanguageButton from './ProfileLanguageButton';

const ProfileLanguageButtonWithState = ({
  language,
  onChangeLanguage,
  ...props
}) => {
  const {languages} = useCompany();

  return (
    <ProfileLanguageButton
      language={language}
      languages={languages}
      onChangeLanguage={onChangeLanguage}
      {...props}
    />
  );
};

export default ProfileLanguageButtonWithState;
