import {useMutation, gql} from '@apollo/client';

export const CHEER_COMMENT_MUTATION = gql`
  mutation cheerCommentMutation($messageId: ID!, $commentId: ID!) {
    cheerComment(messageId: $messageId, commentId: $commentId, input: {})
      @rest(
        type: "Comment"
        path: "/user-messages/{args.messageId}/comments/{args.commentId}/cheer"
        method: "POST"
      ) {
      id
      isCheered
      cheerCount
    }
  }
`;

const useCheerCommentMutation = () => useMutation(CHEER_COMMENT_MUTATION);

export default useCheerCommentMutation;
