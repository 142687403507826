import React from 'react';
import UserCommentInput from '../user-comments/UserCommentInput';
import Tag from '../../utils/Tag';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Date from '../../utils/Date';
import Title from '../../utils/Title';
import Button from '../../utils/Button';
import Avatar from '../../utils/Avatar';
import Paragraph from '../../utils/Paragraph';
import CheerButton from '../../utils/CheerButton';
import CommentsActionsDropdown from './CommentsActionsDropdown';

const Comment = ({
  comment,
  currentUser,
  isEditing,
  onCheerComment,
  onEditComment,
  onDeleteComment,
  onCancelEditComment,
  onSubmitEditComment,
  ...props
}) => {
  const {text, createdBy, createdAt, isCheered, cheerCount} = comment;

  return (
    <Flex
      p={6}
      mb={6}
      bg="white"
      boxShadow={0}
      flexDirection="column"
      borderTopLeftRadius={0}
      borderTopRightRadius={0}
      borderBottomRightRadius={0}
      width="100%"
      {...props}>
      <Flex mb={3} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Avatar avatar={createdBy?.avatar} />
          <Flex ml={3} flexDirection="column">
            <Title variant={4}>
              {createdBy
                ? `${createdBy?.firstName} ${createdBy?.lastName}`
                : '-'}
            </Title>
            <Tag variant={2}>
              <Date value={createdAt} />
            </Tag>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <CommentsActionsDropdown
            comment={comment}
            currentUser={currentUser}
            onEditComment={onEditComment}
            onCheerComment={onCheerComment}
            onDeleteComment={onDeleteComment}>
            <Button variant="unstyled" mr={1}>
              <Icon color="grey" name="menuDots" />
            </Button>
          </CommentsActionsDropdown>
          <CheerButton
            key={isCheered}
            bg={isCheered ? 'primary' : 'grey.1'}
            isCheered={isCheered}
            cheerCount={cheerCount}
            onCheer={onCheerComment}
          />
        </Flex>
      </Flex>
      {isEditing ? (
        <UserCommentInput
          isClearable={false}
          value={comment.text}
          onSubmitComment={onSubmitEditComment}
          onCancelComment={onCancelEditComment}
          initiallyFocused={isEditing}
        />
      ) : (
        <Paragraph variant={2}>{text}</Paragraph>
      )}
    </Flex>
  );
};

export default Comment;
