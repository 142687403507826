import {i18n} from '@lingui/core';
import {de, en, es, fr, it, nl, pl, pt, tr} from 'make-plural/plurals';

import deMessages from './de/messages';
import enMessages from './en/messages';
import esMessages from './es/messages';
import frMessages from './fr/messages';
import itMessages from './it/messages';
import nlMessages from './nl/messages';
import plMessages from './pl/messages';
import ptMessages from './pt/messages';
import trMessages from './tr/messages';

import countriesDe from './de/countries.json';
import countriesEn from './en/countries.json';
import countriesEs from './es/countries.json';
import countriesFr from './fr/countries.json';
import countriesIt from './it/countries.json';
import countriesNl from './nl/countries.json';
import countriesPl from './pl/countries.json';
import countriesPt from './pt/countries.json';
import countriesTr from './tr/countries.json';

import languages from './languages.json';

export const DE = 'de';
export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';
export const IT = 'it';
export const NL = 'nl';
export const PL = 'pl';
export const PT = 'pt';
export const TR = 'tr';

export const countries = {
  de: countriesDe,
  en: countriesEn,
  es: countriesEs,
  fr: countriesFr,
  it: countriesIt,
  nl: countriesNl,
  pl: countriesPl,
  pt: countriesPt,
  tr: countriesTr,
};

const SUPPORTED_LANGUAGES = [
  {
    code: DE,
    name: 'Deutsch',
    messages: deMessages.messages,
    plurals: de,
  },
  {
    code: EN,
    name: 'English',
    messages: enMessages.messages,
    plurals: en,
  },
  {
    code: ES,
    name: 'Español',
    messages: esMessages.messages,
    plurals: es,
  },
  {
    code: FR,
    name: 'Français',
    messages: frMessages.messages,
    plurals: fr,
  },
  {
    code: IT,
    name: 'Italiano',
    messages: itMessages.messages,
    plurals: it,
  },
  {
    code: NL,
    name: 'Nederlands',
    messages: nlMessages.messages,
    plurals: nl,
  },
  {
    code: PL,
    name: 'Polski',
    messages: plMessages.messages,
    plurals: pl,
  },
  {
    code: PT,
    name: 'Português',
    messages: ptMessages.messages,
    plurals: pt,
  },
  {
    code: TR,
    name: 'Türk',
    messages: trMessages.messages,
    plurals: tr,
  },
];

export const getLangByCode = code => languages?.[code];

export const getActiveLanguage = (currentUser, currentCompany) => {
  if (currentUser !== null) {
    return currentUser.language;
  }

  if (currentCompany !== null) {
    return currentCompany.defaultLanguage;
  }

  return EN;
};

SUPPORTED_LANGUAGES.forEach(({code, messages, plurals}) => {
  i18n.loadLocaleData(code, {plurals});
  i18n.load(code, messages);
});

i18n.activate(EN);

i18n.on('missing', event => {
  if (window.location.host.endsWith('.app.meetroger.localhost:3001')) {
    // eslint-disable-next-line no-console
    console.log(
      `Translation in '${event.locale}' for '${event.id}' is missing!`,
    );
  }
});

export default i18n;
