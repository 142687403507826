import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import useNotifications from '../hooks/useNotifications';
import Box from './Box';
import Toast from './Toast';

const ToastMessages = () => {
  const {notifications, removeNotification} = useNotifications();
  return (
    <Box position="fixed" bottom={5} right={5} zIndex={2}>
      <AnimatePresence>
        {notifications.map(notification => (
          <AnimatedToast
            key={notification.createdAt}
            data={notification.data}
            removeNotification={() => removeNotification(notification)}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            drag="x"
            onDragEnd={() => removeNotification(notification)}
          />
        ))}
      </AnimatePresence>
    </Box>
  );
};

const AnimatedToast = motion.custom(Toast);

export default ToastMessages;
