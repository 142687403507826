import {gql} from '@apollo/client';

const DEFAULT_APP_DOMAIN_FRAGMENT = gql`
  fragment DefaultAppDomain on AppDomain {
    id
    slug
    color
    icon
    name
    translations
  }
`;

export default DEFAULT_APP_DOMAIN_FRAGMENT;
