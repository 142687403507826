import React, {useState} from 'react';
import {motion} from 'framer-motion';
import useOpen from '../hooks/useOpen';
import Box from './Box';
import Portal from './Portal';

const Modal = ({
  isOpen: isOpenFromProps,
  setIsOpen: setIsOpenFromProps,
  isDismissable = true,
  children,
}) => {
  const [isOpen, setIsOpen] = useOpen({isOpenFromProps, setIsOpenFromProps});
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <Portal>
      <AnimatedContainer
        top={0}
        left={0}
        position="fixed"
        minHeight="100%"
        width="100%"
        overflow="auto"
        zIndex={10}
        display={isAnimating || isOpen ? 'flex' : 'none'}
        alignItems="center"
        justifyContent="center"
        p={5}
        animate={
          isOpen
            ? {
                opacity: 1,
              }
            : {
                opacity: 0,
              }
        }
        onAnimationStart={() => setIsAnimating(true)}>
        <AnimatedContainer
          position="relative"
          zIndex={1}
          bg="white"
          p={6}
          borderRadius={0}
          minWidth={30}
          animate={
            isOpen
              ? {
                  opacity: 1,
                  scale: 1,
                }
              : {
                  opacity: 0,
                  scale: 0,
                }
          }>
          {children}
        </AnimatedContainer>
        <Box
          bg="shadow"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          onClick={() => {
            if (isDismissable) {
              setIsOpen(false);
            }
          }}
        />
      </AnimatedContainer>
    </Portal>
  );
};

const AnimatedContainer = motion.custom(Box);

export default Modal;
