import {useMutation, gql} from '@apollo/client';

export const REQUEST_OTP_MUTATION = gql`
  mutation requestOtpMutation($body: UserBodyInput!) {
    requestOtp(input: $body)
      @rest(
        type: "RequestOtpResponse"
        path: "/clients/web/otp"
        method: "POST"
      )
  }
`;

const useRequestOtpMutation = () =>
  useMutation(REQUEST_OTP_MUTATION, {fetchPolicy: 'no-cache'});

export default useRequestOtpMutation;
