import {useMutation, gql} from '@apollo/client';

export const CHEER_USER_MESSAGE_MUTATION = gql`
  mutation cheerUserMessageMutation($id: ID!) {
    cheerUserMessage(input: {}, id: $id)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}/cheer"
        method: "POST"
      ) {
      id
      isCheered
      cheerCount
    }
  }
`;

export default function useCheerUserMessageMutation() {
  return useMutation(CHEER_USER_MESSAGE_MUTATION);
}
