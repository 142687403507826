import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import useAppState from '../hooks/useAppState';
import Box from './Box';
import Container from './Container';
import CompanyLogo from './CompanyLogo';

const PublicHeader = ({...props}) => {
  const history = useHistory();
  const {currentCompany} = useAppState();
  let companyLogo = null;
  if (
    currentCompany &&
    currentCompany.settings &&
    currentCompany.settings.logo
  ) {
    companyLogo = currentCompany.settings.logo.sizes.regular;
  }
  return (
    <Box position="fixed" bg="white" width="100%" zIndex={5} {...props}>
      <Container
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent={['center', 'space-between']}>
        <Box my={6}>
          <CompanyLogoWithStyle
            onClick={() => history.push('/')}
            companyName={currentCompany.name}
            logoSrc={companyLogo}
          />
        </Box>
      </Container>
    </Box>
  );
};

const CompanyLogoWithStyle = styled(CompanyLogo)`
  cursor: pointer;
`;

export default PublicHeader;
