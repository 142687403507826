/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import {variant} from 'styled-system';
import Text from './Text';
import {variantToTag} from './Heading';

const TitleWithVariants = styled(Text)(
  variant({
    variants: {
      1: {
        fontSize: 7,
        lineHeight: 9,
      },
      2: {
        fontSize: 5,
        lineHeight: 6,
      },
      3: {
        fontSize: 3,
        lineHeight: 4,
      },
      4: {
        fontSize: 2,
        lineHeight: 3,
      },
      5: {
        fontSize: 1,
        lineHeight: 2,
      },
    },
  }),
);

const Title = ({children, ...props}) => (
  <TitleWithVariants
    as={variantToTag(props.variant)}
    fontFamily="semiBold"
    color="grey.9"
    {...props}>
    {children}
  </TitleWithVariants>
);

export default Title;
