import {useQuery, gql} from '@apollo/client';
import {DEFAULT_LIST_PAGE} from '../../../utils/constants';
import {useUnreadMessagesQuery} from '../../../apollo/queries/getUnreadMessages';

export const HOME_QUERY = gql`
  query homeQuery($page: Int, $limit: Int, $sort: String) {
    users(page: $page, limit: $limit, sort: $sort)
      @rest(type: "Users", path: "/colleagues/?{args}", method: "GET") {
      data {
        id
        uuid
        firstName
        lastName
        language
        workEmail
        workPhone
        workMobile
        description
        isOnline
        commentsCount
        createdAt
        updatedAt
        deletedAt
        email
        phone
        mobile
        birthdate
        firstDayOfWork
        onboardingType
        onboardingAt
        # Not fetching the id of segmentGroups because it makes the cache behave strangely.
        # All users have the same segment group with id 8 which are the departments. The first
        # users' departments get cached and then the cache just assumes that all other users
        # have the same departments because the segmentGroup has the same id. This could be
        # solved by adding unique identifiers for every segmentGroup nested inside a user.
        segmentGroups {
          name
          slug
          createdAt
          updatedAt
          segments {
            id
            name
            slug
            createdAt
            updatedAt
          }
        }
        roles {
          name
          slug
          label
          createdAt
          updatedAt
        }
        avatar {
          id
          name
          fileName
          mimeType
          manipulations
          orderColumn
          usedAt
          createdAt
          updatedAt
          sizes {
            full
            thumb
            regular
          }
          customProperties {
            generatedConversions {
              thumb
              regular
            }
          }
        }
      }
    }
    files(page: $page, limit: $limit, sort: $sort)
      @rest(type: "Files", path: "/user/files?{args}", method: "GET") {
      data {
        id
        name
        fileName
        mimeType
        manipulations
        orderColumn
        usedAt
        fileSize
        createdAt
        updatedAt
        sizes {
          full
          thumb
          regular
        }
      }
    }
  }
`;

const useFetchHomeScreenData = () => {
  const variables = {
    page: DEFAULT_LIST_PAGE,
    limit: 5,
    sort: 'first_day_of_work.desc,created_at.desc',
  };
  const {error, loading: isFetching, data} = useQuery(HOME_QUERY, {variables});
  const {
    error: userMessagesError,
    isFetching: isFetchingUserMessages,
    userMessages,
  } = useUnreadMessagesQuery();

  if (
    typeof error !== 'undefined' ||
    typeof userMessagesError !== 'undefined'
  ) {
    return {error: error || userMessagesError};
  }

  if (isFetching || isFetchingUserMessages) {
    return {
      isFetching: true,
      userMessages: [],
      newColleagues: [],
      newFiles: [],
    };
  }

  const {
    users: {data: newColleagues},
    files: {data: newFiles},
  } = data;

  return {
    isFetching: false,
    userMessages: userMessages.slice(0, 5),
    newColleagues,
    newFiles,
  };
};

export default useFetchHomeScreenData;
