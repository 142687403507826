import 'react-phone-input-2/lib/style.css';
import {createGlobalStyle} from 'styled-components';

const AppGlobalStyles = createGlobalStyle`
    ${({theme}) => `
        *,
        *:after,
        *:before {
            box-sizing: inherit;
            border-width: 0;
            border-style: solid;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        html {
            height: 100%;
            box-sizing: border-box;
            font-size: 62.5%;
            margin:0;
            padding: 0;
        }

        body {
            height: 100%;
            overflow: hidden;
            color: ${theme.colors.text};
            font-family: ${theme.fonts.regular}, "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size: ${theme.fontSizes[1]};
            font-weight: ${theme.fontWeights.normal};
            background-color: ${theme.colors.grey[1]};
            margin: 0;
            padding: 0;
        }

        #root {
            height: 100vh;
            display: flex;
            flex-direction: column;
        }

        *:focus {
            outline-color: ${theme.colors.primary};
        }

        a {
            text-decoration: none;
            color: ${theme.colors.text};
            cursor: pointer;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }  

        img {
            max-width: 100%;
        }

        svg {
            vertical-align: middle;
        }

        td, th {
            padding: 0;
            margin: 0;
        }

        button {
            color: inherit;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
        }

        label {
            cursor: inherit;
        }

        input {
            margin: 0;
        }

        .react-tel-input input[type=tel] {
            width: 100%;
            height: 100%;
            padding: ${theme.space[3]};
            padding-left: 5rem;
            border-radius: ${theme.radii[0]};
            background-color: ${theme.colors.grey[2]};
        }

        .react-tel-input .flag-dropdown,
        .react-tel-input .selected-flag,
        .react-tel-input .flag-dropdown.open,
        .react-tel-input .flag-dropdown.open .selected-flag {
            background-color: ${theme.colors.white};
            background-color: ${theme.colors.grey[2]};
            border-top-left-radius: ${theme.radii[0]};
            border-bottom-left-radius: ${theme.radii[0]};
        }

        .react-code-input {
            display: flex;
            justify-content: space-between;
        }
    
        .react-code-input input {
            width: 3.5rem;
            box-shadow: ${theme.shadows[0]};
            text-align: center;
            padding: 0 !important;
            font-family: ${theme.fonts.regular} !important;
        }

        .react-code-input input::-webkit-outer-spin-button,
        .react-code-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
            margin: 0;
        }

        .md-editor-toolbar {
            display: none;
        }

        /* Firefox */
        .react-code-input input[type=number] {
            -moz-appearance: textfield;
        }
        *::-webkit-scrollbar {
            width: 1.2rem;
        }

        *::-webkit-scrollbar-track {
            background: transparent;
         }

        *::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.grey[2]};
            border-radius: 20px;
        }
    `}
`;

export default AppGlobalStyles;
