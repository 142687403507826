import React from 'react';
import {useParams} from 'react-router-dom';
import PageLoading from '../../utils/PageLoading';
import ErrorPageWithState from '../error/ErrorPageWithState';
import ColleagueDetailsPage from './ColleagueDetailsPage';
import useFetchColleagueDetailsPageData from './useFetchColleagueDetailsPageData';

const ColleagueDetailsPageWithState = () => {
  const {userId} = useParams();
  const {user, error, isFetching} = useFetchColleagueDetailsPageData(userId);

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  if (isFetching) {
    return <PageLoading />;
  }

  return <ColleagueDetailsPage user={user} />;
};

export default ColleagueDetailsPageWithState;
