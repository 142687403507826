import {useMutation, gql} from '@apollo/client';

export const REDEEM_CODE_MUTATION = gql`
  mutation redeemCodeMutation {
    redeemCode(input: {})
      @rest(
        type: "RedeemCodeResponse"
        path: "/mobile-app-install/redeem-code"
        method: "POST"
      ) {
      url
      code
    }
  }
`;

const useRedeemCodeMutation = () => useMutation(REDEEM_CODE_MUTATION);

export default useRedeemCodeMutation;
