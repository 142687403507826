import React from 'react';
import {Trans} from '@lingui/macro';
import MessagesListItemWithState from '../messages/MessagesListItemWithState';
import Box from '../../../modules/utils/Box';
import Flex from '../../../modules/utils/Flex';
import Button from '../../../modules/utils/Button';
import Icon from '../../utils/Icon';
import Title from '../../../modules/utils/Title';

const HomePageMessages = ({
  userMessages,
  unreadCount,
  theme,
  onNavigateToUnreadMessages,
  onNavigateToReadMessages,
  ...props
}) => (
  <Box {...props}>
    {userMessages.map(message => (
      <MessagesListItemWithState
        key={message.id}
        message={message}
        width="100%"
      />
    ))}
    <Flex flexDirection="column" alignItems="center" my={7}>
      {unreadCount === 0 && (
        <>
          <Icon name="inbox" size="larger" />
          <Title variant={3} m={3} textAlign="center">
            <Trans>You have no unread messages.</Trans>
          </Title>
        </>
      )}
      <Button
        variant="link1"
        mt={5}
        py={3}
        px={6}
        borderRadius={0}
        border="1px solid"
        borderColor="primary"
        onClick={
          unreadCount > 0
            ? onNavigateToUnreadMessages
            : onNavigateToReadMessages
        }>
        {unreadCount > 0 ? (
          <Trans>All unread messages</Trans>
        ) : (
          <Trans>Go to read messages</Trans>
        )}
      </Button>
    </Flex>
  </Box>
);

export default HomePageMessages;
