import React from 'react';
import Paragraph from '../utils/Paragraph';
import EditorBlockContainer from './EditorBlockContainer';

const EditorBlockError = ({children, ...props}) => (
  <EditorBlockContainer p={6} {...props}>
    <Paragraph m={0} variant={2}>
      {children}
    </Paragraph>
  </EditorBlockContainer>
);

export default EditorBlockError;
