import React from 'react';
import {Trans} from '@lingui/macro';
import {MODEL_NOT_FOUND} from '../../../utils/constants';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Title from '../../utils/Title';
import PageContainer from '../../utils/PageContainer';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';

const ErrorPage = ({error = null, onReloadApp}) => (
  <PageContainer height="100%">
    <Flex
      px={7}
      flex={1}
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <Icon name="error" size="extraLarge" />
      <Title variant={2} my={7} textAlign="center">
        <Trans>Something went wrong, please try again later.</Trans>
      </Title>
      {error !== null && (
        <Paragraph variant={1}>
          {error.statusText === MODEL_NOT_FOUND
            ? '404: Not found'
            : error.message}
        </Paragraph>
      )}
      {typeof onReloadApp === 'function' && (
        <Button
          onClick={onReloadApp}
          borderRadius={0}
          borderWidth={1}
          borderColor="white">
          <Trans>Reload app</Trans>
        </Button>
      )}
    </Flex>
  </PageContainer>
);

export default ErrorPage;
