import React from 'react';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import FillIcon from '../../utils/FillIcon';
import SurveyBlockHeader from './SurveyBlockHeader';
import Icon from '../../../components/utils/Icon';

const SurveyRating = ({
  survey,
  onChange = null,
  selectedOption,
  isPublished,
  isPublishing,
}) => {
  const {content, options} = survey;
  return (
    <>
      <SurveyBlockHeader
        question={content.question}
        description={content.description}
        isPublished={isPublished}
        isPublishing={isPublishing}
      />
      <Flex flexDirection="row" flexWrap="wrap" pb={6} px={6}>
        {options.map(option => {
          const isSelected =
            selectedOption && selectedOption.value >= option.value;
          return (
            <Button
              key={option.value}
              onClick={() => onChange(option)}
              disabled={onChange === null}
              variant="unstyled"
              alignItems="center"
              justifyContent="center"
              mr={3}
              mb={3}>
              {isSelected ? (
                <FillIcon
                  name="star"
                  size="large"
                  strokeWidth="1.5"
                  color="primary"
                  strokeColor="primary"
                />
              ) : (
                <Icon name="star" color="grey" size="large" strokeWidth="1.5" />
              )}
            </Button>
          );
        })}
      </Flex>
    </>
  );
};

export default SurveyRating;
