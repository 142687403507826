import React from 'react';
import {motion} from 'framer-motion';
import {DEPARTMENTS_SLUG} from '../../../utils/constants';
import {whereSlug} from '../../../utils/index';
import Tag from '../../utils/Tag';
import Flex from '../../utils/Flex';
import Link from '../../utils/Link';
import Icon from '../../utils/Icon';
import Label from '../../utils/Label';
import Avatar from '../../utils/Avatar';

const ColleagueCard = ({user}) => {
  const {id, avatar, lastName, firstName, segmentGroups} = user;
  const userDepartmentSegmentGroup = segmentGroups.find(
    whereSlug(DEPARTMENTS_SLUG),
  );
  const userDepartments =
    typeof userDepartmentSegmentGroup !== 'undefined'
      ? userDepartmentSegmentGroup.segments.map(({name}) => name)
      : [];

  return (
    <Link to={`/colleagues/${id}`}>
      <AnimatedCard
        py={4}
        px={6}
        mb={4}
        width="100%"
        borderRadius={0}
        bg="white"
        alignItems="center"
        boxShadow={0}
        whileTap={{scale: 1}}
        whileHover={{scale: 1.01}}>
        <Flex mr={2} flex={1} flexDirection="row" alignItems="center">
          <Avatar avatar={avatar} />
          <Flex flex={1} flexDirection="column" ml={3}>
            <Label variant={1}>{`${firstName} ${lastName}`}</Label>
            {userDepartments.length > 0 && (
              <Tag variant={1}>{userDepartments.join(', ')}</Tag>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Icon name="chevronRight" color="greyLight" />
        </Flex>
      </AnimatedCard>
    </Link>
  );
};

const AnimatedCard = motion.custom(Flex);

export default ColleagueCard;
