import {useReducer} from 'react';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const createReducer = () => (state, action) => {
  const {type, initialState} = action;
  switch (type) {
    case OPEN_MODAL:
      return {
        isOpen: true,
        ...state.initialState,
        ...initialState,
      };
    case CLOSE_MODAL:
      return {
        isOpen: false,
        ...initialState,
      };
    default:
      throw new Error();
  }
};

const useModalState = (initialState = {}) => {
  const [state, dispatch] = useReducer(createReducer(), {
    isOpen: false,
    ...initialState,
  });

  const open = (newState = {}) =>
    dispatch({
      type: OPEN_MODAL,
      initialState: newState,
    });

  const close = () =>
    dispatch({
      type: CLOSE_MODAL,
      initialState,
    });

  return {
    ...state,
    open,
    close,
  };
};

export default useModalState;
