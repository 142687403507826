import {useQuery, gql} from '@apollo/client';
import DEFAULT_USER_FRAGMENT from '../fragments/defaultUser';

export const ME_QUERY = gql`
  query meQuery {
    me @rest(type: "User", path: "/users/me", method: "GET") {
      ...DefaultUser
    }
  }
  ${DEFAULT_USER_FRAGMENT}
`;

export const useMeQuery = () => useQuery(ME_QUERY);

export default async function getMe(apollo, options) {
  const query = ME_QUERY;
  const response = await apollo.query({query, ...options});
  return response.data.me;
}
