import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import {useUploadFileMutation} from '../../../apollo/mutations/uploadFile';
import useCompany from '../../hooks/useCompany';
import useNotifications from '../../hooks/useNotifications';
import ProfilePageHeader from './ProfilePageHeader';

const ProfilePageHeaderWithState = ({onUpdateAvatar, ...props}) => {
  const {maxFileUpload} = useCompany();
  const {dispatchError} = useNotifications();
  const [isUploadingAvatar, setIsUploadingAvatar] = useState();

  const [uploadFile] = useUploadFileMutation();

  const handleFileChange = async files => {
    const file = files && files.length ? files[0] : null;
    if (file !== null) {
      setIsUploadingAvatar(true);
      if (file.size > maxFileUpload) {
        dispatchError({
          message: <Trans>The file is larger than the allowed size.</Trans>,
        });
      } else {
        try {
          const {
            data: {uploadFile: uploadedFile},
          } = await uploadFile({
            variables: {
              body: {
                mediaFile: file,
                mediaType: 'avatar',
              },
            },
          });
          const {id: newAvatarId} = uploadedFile;
          await onUpdateAvatar(newAvatarId);
        } catch (error) {
          dispatchError({
            message: error.message,
          });
        } finally {
          setIsUploadingAvatar(false);
        }
      }
    }
  };

  return (
    <ProfilePageHeader
      isUploadingAvatar={isUploadingAvatar}
      onFileChange={handleFileChange}
      {...props}
    />
  );
};

export default ProfilePageHeaderWithState;
