import React from 'react';
import * as Sentry from '@sentry/react';
import {useHistory} from 'react-router-dom';
import useAppState from '../../hooks/useAppState';
import ErrorPage from './ErrorPage';

const ErrorPageWithState = ({error}) => {
  const history = useHistory();
  const {refresh} = useAppState();

  const handleReloadApp = () => {
    refresh();
    history.push('/');
  };

  Sentry.captureException(error);

  return <ErrorPage error={error} onReloadApp={handleReloadApp} />;
};

export default ErrorPageWithState;
