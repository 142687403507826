import {createGlobalStyle} from 'styled-components';

const root = '.md-RichEditor-root';
const block = '.md-block';

const EditorStyles = createGlobalStyle`
    ${({theme}) => `
        ${root} {
            background: none;
            font-family: ${theme.fonts.regular};
            font-size: ${theme.fontSizes[2]};
            line-height: 2.2rem;
            padding-bottom: 5rem;
        }

        ${root} .md-block-paragraph${block},
        ${root} h1${block},
        ${root} h2${block},
        ${root} h3${block},
        ${root} blockquote${block},
        ${root} ul${block},
        ${root} ol${block} {
            margin: 0;
        }

        ${root} h1${block},
        ${root} h2${block},
        ${root} h3${block} {
            font-weight: ${theme.fonts.bold};
        }

        ${root} h1${block} {
            margin-bottom: 5.5rem;
            font-size: 3.2rem;
            line-height: 3.7rem;
        }

        ${root} h2${block} {
            font-size: 2rem;
            line-height: 2.3rem;
            margin-bottom: 4.5rem;
        }

        ${root} .md-block-paragraph${block} {
            margin-bottom: 10px;
        }

        ${root} blockquote${block} {
            background-color: transparent;
            border: 0;
        }

        ${root} blockquote${block} > div {
            margin: 3.8rem 4rem;
            padding-left: 2.5rem;

            font-size: 2.4rem;
            line-height: 2.8rem;
            font-weight: ${theme.fonts.bold};
            font-style: italic;
            border-left: solid .2rem ${theme.colors.primary};

            background-color: transparent;
        }

        ${root} ul${block},
        ${root} ol${block} {
            margin-left: 3rem;
            color: red;
        }

        ${root} a {
            color: ${theme.colors.primary};
            text-decoration: none;
        }

        ${block}:not(figure) {
            padding-left: ${theme.space[2]};
            padding-right: ${theme.space[2]};
        }

        .public-DraftStyleDefault-unorderedListItem,
        .public-DraftStyleDefault-orderedListItem {
            margin-bottom: 10px;
            margin-left: 2.4rem;
        }

        .public-DraftStyleDefault-unorderedListItem {
            list-style-type: disc;
        }
        .public-DraftStyleDefault-orderedListItem {
            list-style-type: decimal;
        }

    `}
`;

export default EditorStyles;
