import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Dropdown from '../../utils/Dropdown';
import Button from '../../utils/Button';
import Icon from '../../utils/Icon';
import CommentButton from '../../utils/CommentButton';
import CheerButton from '../../utils/CheerButton';

const MessageActions = ({
  isOpen,
  setIsOpen,
  message,
  onBookmarkMessage,
  isChangingBookMarkState,
  onCheerMessage,
  isChangingCheerState,
  onNavigateToComments,
  ...props
}) => {
  const {cheerCount, commentsCount, isBookmarked, isCheered, enableComments} =
    message;

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      {...props}>
      {enableComments && (
        <CommentButton
          mr={0}
          onClick={onNavigateToComments}
          commentsCount={commentsCount}
        />
      )}
      <CheerButton
        key={isCheered}
        onCheer={onCheerMessage}
        isCheered={isCheered}
        cheerCount={cheerCount}
      />
      <Dropdown
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        listItems={
          <Dropdown.List>
            <Dropdown.DropdownListItem
              iconName="bookmark"
              onClick={onBookmarkMessage}
              isLoading={isChangingBookMarkState}>
              {isBookmarked ? (
                <Trans>Remove this message from bookmarks</Trans>
              ) : (
                <Trans>Bookmark this message</Trans>
              )}
            </Dropdown.DropdownListItem>
            <Dropdown.DropdownListItem
              iconName="cheer"
              onClick={onCheerMessage}
              isLoading={isChangingCheerState}>
              {isCheered ? (
                <Trans>Uncheer this message</Trans>
              ) : (
                <Trans>Cheer this message</Trans>
              )}
            </Dropdown.DropdownListItem>
            {enableComments && (
              <Dropdown.NavigationItem
                iconName="comments"
                onClick={onNavigateToComments}>
                <Trans>View comments</Trans>
              </Dropdown.NavigationItem>
            )}
          </Dropdown.List>
        }>
        <Button variant="unstyled" mr={0}>
          <Icon name="menuDots" color="greyDark" />
        </Button>
      </Dropdown>
    </Flex>
  );
};

export default MessageActions;
