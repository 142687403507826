import {useQuery, gql} from '@apollo/client';

export const COLLEAGUE_DETAILS_QUERY = gql`
  query colleagueDetailsQuery($userId: String) {
    user(userId: $userId)
      @rest(type: "Users", path: "/colleagues/{args.userId}", method: "GET") {
      id
      uuid
      firstName
      lastName
      language
      workEmail
      workPhone
      workMobile
      description
      isOnline
      commentsCount
      createdAt
      updatedAt
      deletedAt
      email
      phone
      mobile
      birthdate
      firstDayOfWork
      onboardingType
      onboardingAt
      # Not fetching the id of segmentGroups because it makes the cache behave strangely.
      # All users have the same segment group with id 8 which are the departments. The first
      # users' departments get cached and then the cache just assumes that all other users
      # have the same departments because the segmentGroup has the same id. This could be
      # solved by adding unique identifiers for every segmentGroup nested inside a user.
      segmentGroups {
        name
        slug
        createdAt
        updatedAt
        segments {
          id
          name
          slug
          createdAt
          updatedAt
        }
      }
      avatar {
        id
        name
        fileName
        mimeType
        manipulations
        orderColumn
        usedAt
        createdAt
        updatedAt
        sizes {
          full
          thumb
          regular
        }
        customProperties {
          generatedConversions {
            thumb
            regular
          }
        }
      }
    }
  }
`;

const useFetchColleagueDetailsPageData = userId => {
  const variables = {userId};
  const {
    error,
    loading: isFetching,
    data,
  } = useQuery(COLLEAGUE_DETAILS_QUERY, {variables});

  if (isFetching) {
    return {
      isFetching,
      user: {},
    };
  }
  if (typeof error !== 'undefined') {
    return {
      error,
      isFetching,
      user: {},
    };
  }

  const {user = {}} = data;

  return {
    user,
    isFetching,
  };
};

export default useFetchColleagueDetailsPageData;
