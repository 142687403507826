/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import {variant} from 'styled-system';
import Text from './Text';

export const variantToTag = v => {
  switch (v) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
    default:
      return 'h4';
  }
};

const HeadingWithVariants = styled(Text)(
  variant({
    variants: {
      1: {
        fontSize: 7,
        lineHeight: 8,
      },
      2: {
        fontSize: 6,
        lineHeight: 7,
      },
      3: {
        fontSize: 4,
        lineHeight: 5,
      },
      4: {
        fontSize: 3,
        lineHeight: 4,
      },
    },
  }),
);

const Heading = ({children, ...props}) => (
  <HeadingWithVariants
    as={variantToTag(props.variant)}
    fontFamily="extraBold"
    color="grey.9"
    {...props}>
    {children}
  </HeadingWithVariants>
);

export default Heading;
