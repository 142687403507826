import React from 'react';
import Flex from '../../utils/Flex';
import Icon from '../../utils/Icon';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';

const ProfileActionCard = ({
  icon,
  actionText,
  action,
  hasBadge,
  children,
  ...props
}) => (
  <Flex position="relative" {...props}>
    <Flex
      width="100%"
      px={5}
      py={6}
      bg="grey.1"
      borderRadius={0}
      alignItems="center"
      flexDirection="column">
      <Icon name={icon} size="large" />
      <Paragraph variant={2} my={6} textAlign="center">
        {children}
      </Paragraph>
      <Button variant="link2" onClick={action}>
        {actionText}
      </Button>
    </Flex>
    {hasBadge && (
      <Flex
        width={14}
        height={14}
        position="absolute"
        top={-4}
        left={-4}
        bg="primary"
        borderRadius={5}
      />
    )}
  </Flex>
);

export default ProfileActionCard;
