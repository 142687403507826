import React from 'react';
import styled from 'styled-components';
import {whereSize} from '../../utils';
import Box from './Box';
import Icon from './Icon';
import Flex from './Flex';
import Loader from './Loader';
import Image from './ImageWithState';

export const AVATAR_SIZE_SMALL = 40;
export const AVATAR_SIZE_MEDIUM = 80;
export const AVATAR_SIZE_LARGE = 145;

const avatarSizeStyles = [
  {
    size: 'small',
    width: AVATAR_SIZE_SMALL,
    height: AVATAR_SIZE_SMALL,
    avatarSize: 'thumb',
    iconSize: 'default',
    spinnerSize: 'normal',
  },
  {
    size: 'medium',
    width: AVATAR_SIZE_MEDIUM,
    height: AVATAR_SIZE_MEDIUM,
    avatarSize: 'regular',
    iconSize: 'larger',
    spinnerSize: 'large',
  },
  {
    size: 'large',
    width: AVATAR_SIZE_LARGE,
    height: AVATAR_SIZE_LARGE,
    avatarSize: 'regular',
    iconSize: 'largest',
    spinnerSize: 'small',
  },
];

const Avatar = ({
  avatar,
  size = 'small',
  iconName = 'user',
  isLoading,
  children,
  iconComponent,
  color,
  ...props
}) => {
  const {width, height, avatarSize, iconSize, spinnerSize} =
    avatarSizeStyles.find(whereSize(size));

  const imageSource = !isLoading && !!avatar ? avatar.sizes[avatarSize] : '';

  return (
    <Flex
      position="relative"
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
      bg="grey.2"
      borderRadius="50%"
      backgroundSize="cover"
      backgroundPosition="center"
      {...props}>
      {!!avatar && (
        <ImageWithStyle
          width={width}
          height={height}
          maxHeight="100%"
          borderRadius="50%"
          src={imageSource}
          FallbackComponent={
            <Icon name={iconName} color={color || 'greyDark'} size={iconSize} />
          }
        />
      )}
      {isLoading && (
        <Box position="absolute" zIndex={10}>
          <Loader variant={spinnerSize} />
        </Box>
      )}
      {!isLoading && typeof iconComponent === 'function' && iconComponent()}
      {!isLoading && avatar === null && iconComponent !== null && (
        <Icon name={iconName} color={color || 'greyDark'} size={iconSize} />
      )}
      {children}
    </Flex>
  );
};

const ImageWithStyle = styled(Image)`
  object-fit: cover;
`;

export default Avatar;
