import React, {useState} from 'react';
import {Trans, t} from '@lingui/macro';
import useLingui from '../../hooks/useLingui';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import TextAreaInput from '../../utils/InputTextArea';

const CommentInput = ({
  value = '',
  onSubmitComment,
  onCancelComment,
  isClearable = false,
}) => {
  const lingui = useLingui();
  const [textValue, setTextValue] = useState(value);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmitComment = async () => {
    if (textValue !== '') {
      setIsUpdating(true);
      await onSubmitComment(textValue);
      setIsUpdating(false);
    }
  };

  return (
    <>
      <TextAreaInput
        value={textValue}
        placeholder={lingui._(t`Write a comment...`)}
        onChange={e => setTextValue(e.target.value)}
        isClearable={isClearable}
        width="100%"
      />
      <Flex mt={5} alignItems="center" justifyContent="space-between">
        <Button
          flex={1}
          variant="primary"
          isLoading={isUpdating}
          onClick={handleSubmitComment}>
          <Trans>Save</Trans>
        </Button>
        <Button
          flex={1}
          variant="unstyledCentered"
          disabled={isUpdating}
          onClick={onCancelComment}>
          <Trans>Cancel</Trans>
        </Button>
      </Flex>
    </>
  );
};

export default CommentInput;
