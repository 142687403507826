import React from 'react';
import styled from 'styled-components';
import Flex from './Flex';
import Icon from './Icon';
import Heading from './Heading';

const ModalTopBar = ({title, onClose, ...props}) => {
  const hasCloseButton = typeof onClose !== 'undefined';

  return (
    <Flex
      mb={6}
      alignItems="center"
      justifyContent={hasCloseButton ? 'space-between' : 'flex-start'}
      {...props}>
      <Heading variant={3}>{title}</Heading>
      {hasCloseButton && (
        <IconWithStyle onClick={onClose} color="grey" name="cross" />
      )}
    </Flex>
  );
};

const IconWithStyle = styled(Icon)`
  cursor: pointer;
`;

export default ModalTopBar;
