import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import useAppState from '../hooks/useAppState';
import {countries} from '../../locales';

const InputPhone = ({locale, enableLongNumbers = true, ...props}) => {
  const {currentCompany} = useAppState();
  let country = 'be';
  if (currentCompany !== null) {
    country = currentCompany.defaultCountry;
  }

  return (
    <ReactPhoneInput
      country={country}
      localization={countries[locale]}
      enableLongNumbers={enableLongNumbers}
      {...props}
    />
  );
};

export default InputPhone;
