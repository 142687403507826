import React from 'react';
import {Trans} from '@lingui/macro';
import {useLocation} from 'react-router-dom';
import useAppState from '../hooks/useAppState';
import usePermissions from '../hooks/usePermissions';
import Tag from './Tag';
import Link from './Link';
import Flex from './Flex';
import Icon from './Icon';

const Navbar = ({...props}) => {
  const {unreadCount} = useAppState();
  const {pathname: path} = useLocation();
  const {canViewCalendar, canViewColleagues, canViewTools} = usePermissions();

  return (
    <Flex flexDirection="row" {...props}>
      <NavbarItem
        title={<Trans>Home</Trans>}
        icon="home"
        to="/"
        isActive={path === '/'}
      />
      <NavbarItem
        title={<Trans>Messages</Trans>}
        icon="chat"
        to="/messages"
        isActive={path.startsWith('/messages') || path.startsWith('/message')}
        unreadCount={unreadCount}
      />
      <NavbarItem
        title={<Trans>Documents</Trans>}
        icon="folders"
        to="/documents"
        isActive={path.includes('documents')}
      />
      {canViewColleagues && (
        <NavbarItem
          title={<Trans>Colleagues</Trans>}
          icon="users"
          to="/colleagues"
          isActive={path.includes('colleagues')}
        />
      )}
      {canViewCalendar && (
        <NavbarItem
          title={<Trans>Calendar</Trans>}
          icon="calendar"
          to="/calendar"
          display={['none', 'flex']}
          isActive={path.includes('calendar')}
        />
      )}
      {canViewTools && (
        <NavbarItem
          title={<Trans>Tools</Trans>}
          icon="tools"
          to="/tools"
          isActive={path.includes('tools')}
        />
      )}
    </Flex>
  );
};

const NavbarItem = ({to, icon, title, isActive, unreadCount, ...props}) => (
  <Link
    to={to}
    position="relative"
    display="flex"
    flexDirection="column"
    alignItems="center"
    px={[4, 6]}
    py={[3, 5]}
    borderBottom={isActive ? '3px solid' : 'none'}
    borderBottomColor="primary"
    {...props}>
    {unreadCount ? (
      <Flex
        justifyContent="center"
        alignItems="center"
        width={18}
        height={18}
        bg="primary"
        borderRadius={0}
        position="absolute"
        top={1}
        right={[3, 5]}>
        <Tag fontSize="1rem" fontFamily="semiBold" color="white">
          {unreadCount}
        </Tag>
      </Flex>
    ) : null}
    <Icon name={icon} strokeWidth={2} color={isActive ? 'primary' : 'grey'} />
    <Tag
      mt={1}
      style={{whiteSpace: 'nowrap'}}
      variant={2}
      color={isActive ? 'black' : 'grey.4'}
      fontFamily="semiBold">
      {title}
    </Tag>
  </Link>
);

export default Navbar;
