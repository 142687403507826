import React from 'react';
import {Trans} from '@lingui/macro';
import ReactCodeInput from 'react-code-input';
import WelcomePageContainer from '../welcome/WelcomePageContainer';
import Flex from '../../utils/Flex';
import Button from '../../utils/Button';
import FormItem from '../../utils/FormItem';
import Paragraph from '../../utils/Paragraph';

const LoginOtpPage = ({formState, onCancel, onSubmit, isLoading}) => {
  const {getInputProps, getErrorMessages} = formState;
  return (
    <WelcomePageContainer
      title={<Trans>Code</Trans>}
      info={
        <Paragraph variant={2}>
          <Trans id="We've sent you a secure login code. Use it to log in." />
        </Paragraph>
      }>
      <FormItem error={getErrorMessages('token')} required textAlign="center">
        <ReactCodeInput type="number" fields={6} {...getInputProps('token')} />
      </FormItem>
      <Flex>
        <Button flex={1} onClick={onCancel} variant="outline" mr={5}>
          <Trans>Cancel</Trans>
        </Button>
        <Button flex={1} isLoading={isLoading} onClick={onSubmit}>
          <Trans>Login</Trans>
        </Button>
      </Flex>
    </WelcomePageContainer>
  );
};

export default LoginOtpPage;
