import {useMutation, gql} from '@apollo/client';
import DEFAULT_COMMENT from '../../../apollo/fragments/defaultComment';

export const CREATE_COMMENT_MUTATION = gql`
  mutation createCommentMutation($messageId: ID!, $body: CreateCommentInput!) {
    createComment(id: $messageId, input: $body)
      @rest(
        type: "Comment"
        path: "/user-messages/{args.id}/comments"
        method: "POST"
      ) {
      ...DefaultComment
    }
  }
  ${DEFAULT_COMMENT}
`;

const useCreateCommentMutation = () => useMutation(CREATE_COMMENT_MUTATION);

export default useCreateCommentMutation;
