import {
  color,
  space,
  layout,
  shadow,
  border,
  flexbox,
  position,
  typography,
  background,
} from 'styled-system';
import styled from 'styled-components';

const Iframe = styled.iframe`
  ${color}
  ${space}
    ${layout}
    ${position}
    ${flexbox}
    ${shadow}
    ${border}
    ${typography}
    ${background}
`;

export default Iframe;
