import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import {
  DEFAULT_COMMENT_SORT,
  DEFAULT_LIST_PAGE,
  DEFAULT_COMMENT_PAGE_LIMIT,
} from '../../../utils/constants';

const COMMENTS_PAGE_QUERY = gql`
  query commentsScreenQuery(
    $messageId: ID!
    $page: Int
    $limit: Int
    $sort: String
  ) {
    comments(messageId: $messageId, page: $page, limit: $limit, sort: $sort)
      @rest(
        type: "Comments"
        path: "/user-messages/{args.messageId}/comments?{args}"
        method: "GET"
      ) {
      data {
        id
        text
        isCheered
        cheerCount
        deletedAt
        updatedAt
        createdAt
        createdBy {
          id
          firstName
          lastName
          avatar {
            id
            sizes {
              thumb
            }
          }
        }
      }
      meta {
        currentPage
        from
        lastPage
        path
        perPage
        to
        total
      }
    }
    userMessage(id: $messageId)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}"
        method: "GET"
      ) {
      id
      messageId
      commentsCount
    }
  }
`;

const useFetchCommentPageData = messageId => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const variables = {
    sort: DEFAULT_COMMENT_SORT,
    page: DEFAULT_LIST_PAGE,
    messageId,
    limit: DEFAULT_COMMENT_PAGE_LIMIT,
  };

  const {
    data,
    error,
    loading: isFetching,
    refetch,
    fetchMore,
    updateQuery,
  } = useQuery(COMMENTS_PAGE_QUERY, {variables});

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      userMessage: {},
      userMessageComments: [],
    };
  }

  const {
    comments: {
      data: comments,
      meta: {lastPage, currentPage},
    },
    userMessage,
  } = data;

  const hasMorePages = currentPage < lastPage;
  const handleFetchMore = async () => {
    if (!isFetchingMore && hasMorePages) {
      setIsFetchingMore(true);
      await fetchMore({
        variables: {
          page: currentPage + 1,
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...prev,
            comments: {
              ...fetchMoreResult.comments,
              data: [...prev.comments.data, ...fetchMoreResult.comments.data],
            },
          };
        },
      });
      setIsFetchingMore(false);
    }
  };

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };

  return {
    isFetching,
    isRefetching,
    isFetchingMore,
    hasMorePages,
    userMessage,
    userMessageComments: comments,
    handleRefetch,
    handleFetchMore,
    updateQuery,
  };
};

export default useFetchCommentPageData;

export const getCommentsPageData = async (apollo, messageId, options) => {
  const query = COMMENTS_PAGE_QUERY;
  const variables = {
    sort: DEFAULT_COMMENT_SORT,
    page: DEFAULT_LIST_PAGE,
    messageId,
    limit: DEFAULT_COMMENT_PAGE_LIMIT,
  };
  const response = await apollo.query({query, variables, ...options});
  return response.data;
};
