import sortby from 'lodash.sortby';
import {useQuery, gql} from '@apollo/client';
import DEFAULT_APP_DOMAIN from '../fragments/defaultAppDomain';

export const APP_DOMAINS_QUERY = gql`
  query appDomainsQuery {
    appDomains @rest(type: "AppDomains", path: "/appdomains", method: "GET") {
      data {
        ...DefaultAppDomain
      }
    }
  }
  ${DEFAULT_APP_DOMAIN}
`;

export const useAppDomainsQuery = () => {
  const {error, loading: isFetching, data} = useQuery(APP_DOMAINS_QUERY);

  if (typeof error !== 'undefined') {
    return {error};
  }

  if (isFetching) {
    return {
      isFetching,
      appDomains: [],
    };
  }

  const {
    appDomains: {data: appDomains},
  } = data;

  const sortedAppDomains = sortby(appDomains, a => a.name);
  return {
    isFetching,
    appDomains: sortedAppDomains,
  };
};

export default async function getAppDomains(apollo, options) {
  const query = APP_DOMAINS_QUERY;
  const response = await apollo.query({query, ...options});
  return response.data.appDomains.data;
}
