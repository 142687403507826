import * as React from 'react';

const AddMessageIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.1 20.69" {...props}>
    <path
      d="M12.39 1.3A9.84 9.84 0 0 0 10 1C5.05 1 1 4.64 1 9.18v.15c0 2.68 1.42 5.05 3.6 6.54v3.21l3.53-1.75c.62.12 1.24.18 1.87.18 4.42 0 8.12-2.91 8.86-6.77M18.1 1.01v6M15.1 4.01h6"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeLinecap: 'round',
        strokeMiterlimit: 10,
        strokeWidth: 2,
      }}
    />
  </svg>
);
export default AddMessageIcon;
