import {gql} from '@apollo/client';

const DEFAULT_USER_FRAGMENT = gql`
  fragment DefaultUser on User {
    id
    uuid
    firstName
    lastName
    language
    workEmail
    workPhone
    workMobile
    description
    isOnline
    commentsCount
    createdAt
    updatedAt
    deletedAt
    email
    phone
    mobile
    birthdate
    firstDayOfWork
    onboardingType
    onboardingAt
    acceptedTerms
    apiExporterUserConfigs {
      driver
      createdAt
      updatedAt
      config {
        key
        url
      }
    }
    segmentGroups {
      name
      slug
      createdAt
      updatedAt
      segments {
        id
        name
        slug
        createdAt
        updatedAt
      }
    }
    roles {
      name
      slug
      label
      createdAt
      updatedAt
    }
    avatar {
      id
      name
      fileName
      mimeType
      manipulations
      orderColumn
      usedAt
      createdAt
      updatedAt
      sizes {
        full
        thumb
        regular
      }
      customProperties {
        generatedConversions {
          small
          thumb
          regular
        }
      }
    }
  }
`;

export default DEFAULT_USER_FRAGMENT;
