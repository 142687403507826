import React from 'react';
import {useTheme} from 'styled-components';
import Title from '../../utils/Title';
import Button from '../../utils/Button';

export const ARTEEL = 'arteel';

export const ArteelLogo = ({fill}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6v6h12V0H0v6zM16 6v6h12V0H16v6zM0 22v6h12V16H0v6zM16 22v6h12V16H16v6z"
      fill={fill}
    />
  </svg>
);

export const VanMoerDiamond = ({fill}) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 70 52"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.1 8.4l-5 7.3 16.2 17.4c8.9 9.6 16.4 17.4 16.7 17.4.3 0 7.8-7.8 16.7-17.4l16.2-17.4-5-7.3-5-7.4H12.1l-5 7.4zm19.2 0l-3.8 5.4-4.7-4.6c-2.7-2.5-4.8-5-4.8-5.4 0-.4 3.8-.8 8.5-.8h8.6l-3.8 5.4zm15.2.8l4.6 6.3-5.6.3c-3 .2-8 .2-11 0l-5.6-.3 4.6-6.3C31.4 5.2 33.7 3 35 3c1.3 0 3.6 2.2 6.5 6.2zM57 3.8c0 .4-2.1 2.9-4.7 5.4l-4.8 4.6-3.8-5.4L39.9 3h8.6c4.7 0 8.5.4 8.5.8zm-41 7.7l4.4 4.5H4.8l1.4-2.3c.8-1.2 2.1-3.2 2.9-4.5C9.9 8 10.7 7 11 7c.3 0 2.6 2 5 4.5zm44.9-2.3c.8 1.3 2.1 3.3 2.9 4.5l1.4 2.3H49.6l4.4-4.5C56.4 9 58.7 7 59 7c.3 0 1.1 1 1.9 2.2zM25.5 29.1c2 6 3.5 11.2 3.3 11.4-.4.4-17.2-17.3-19.6-20.7C8 18.1 8.3 18 14.9 18h6.9l3.7 11.1zM45 19.3c0 3.3-8.8 27.2-10 27.2-1.2 0-10-23.9-10-27.2 0-1 2.5-1.3 10-1.3s10 .3 10 1.3zm15.8.5c-2.4 3.4-19.2 21.1-19.6 20.7-.2-.2 1.3-5.4 3.3-11.4L48.2 18h6.9c6.6 0 6.9.1 5.7 1.8z"
      fill={fill}
    />
  </svg>
);

const Arteel = ({config, ...props}) => {
  const theme = useTheme();
  const isVanMoer = true;

  return (
    <Button
      bg={isVanMoer ? 'vanmoerSecondary' : 'arteel'}
      onClick={() => {
        window.location.href = config.url;
      }}
      {...props}>
      {isVanMoer ? (
        <VanMoerDiamond fill={theme.colors.white} />
      ) : (
        <ArteelLogo fill={theme.colors.white} />
      )}
      <Title variant={3} color="white" ml={6}>
        {isVanMoer ? 'Blue Diamond' : 'Arteel'}
      </Title>
    </Button>
  );
};

export default Arteel;
