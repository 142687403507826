import React from 'react';
import Flex from './Flex';
import Label from './Label';
import Title from './Title';

const OrderedListItem = ({count, label}) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-start"
    mb={5}>
    <Flex
      width="3rem"
      height="3rem"
      alignItems="center"
      justifyContent="center"
      mr={5}
      my="auto"
      bg="primary"
      color="white"
      borderRadius="50%">
      <Title variant={4} color="white">
        {count}
      </Title>
    </Flex>
    <Label flex={1} variant={3}>
      {label}
    </Label>
  </Flex>
);

export default OrderedListItem;
