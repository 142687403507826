import React from 'react';
import CheckBox from '../../utils/CheckBox';
import {whereId} from '../../../utils/index';
import {useMessagesFilter} from './hooks/MessagesFilters';
import Box from '../../../modules/utils/Box';

const MessagesFilters = ({disabled, ...props}) => {
  const {isFetching, appDomains, selectedFilters, handleFilter} =
    useMessagesFilter();
  return (
    <Box
      bg="white"
      borderRadius={0}
      alignSelf="flex-start"
      px={5}
      py={7}
      {...props}>
      {!isFetching &&
        appDomains.map(appDomain => {
          const isFilterChecked =
            typeof selectedFilters.find(whereId(appDomain.id)) !== 'undefined';
          return (
            <Box key={appDomain.id} mb={3}>
              <CheckBox
                onChange={() => handleFilter(appDomain)}
                checked={isFilterChecked}
                name={`pageFilter-${appDomain.name}`}
                disabled={disabled}>
                {appDomain.name}
              </CheckBox>
            </Box>
          );
        })}
    </Box>
  );
};

export default MessagesFilters;
