import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import {whereId} from '../../../utils/index';
import Button from '../../utils/Button';
import Dropdown from '../../utils/Dropdown';
import CheckBox from '../../utils/CheckBox';
import {useMessagesFilter} from './hooks/MessagesFilters';

const MessagesPageHeaderFilters = ({...props}) => {
  const {
    isFetching,
    appDomains,
    selectedFilters,
    onApplyFilters,
    onClearFilters,
    handleFilter,
  } = useMessagesFilter();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      listItems={
        <Dropdown.List>
          {!isFetching &&
            appDomains.map(appDomain => {
              const isFilterChecked = !!selectedFilters.find(
                whereId(appDomain.id),
              );
              return (
                <Dropdown.ListItem key={appDomain.id} mb={3}>
                  <CheckBox
                    onChange={() => handleFilter(appDomain)}
                    checked={isFilterChecked}
                    name={`headerFilter-${appDomain.name}`}>
                    a - {appDomain.name}
                  </CheckBox>
                </Dropdown.ListItem>
              );
            })}
          <Button mt={5} variant="primary" onClick={onApplyFilters}>
            <Trans>Apply filters</Trans>
          </Button>
          <Button
            mt={5}
            justifyContent="center"
            variant="link2"
            onClick={onClearFilters}>
            <Trans>Clear filters</Trans>
          </Button>
        </Dropdown.List>
      }
      {...props}>
      <Button variant="link2">
        <Trans>Filter</Trans>
      </Button>
    </Dropdown>
  );
};

export default MessagesPageHeaderFilters;
