import React, {useEffect, useReducer} from 'react';
import {Trans} from '@lingui/macro';
import Box from './Box';
import Image from './Image';
import Paragraph from './Paragraph';
import Loader from '../../components/utils/Loader';

const MAX_ATTEMPTS = 5;
const FETCHING = 'FETCHING';
const COMPLETE = 'COMPLETE';
const FAILED = 'FAILED';
const UPDATE_SOURCE = 'UPDATE_SOURCE';
const INCREMENT_ATTEMPT = 'INCREMENT_ATTEMPT';
const SET_STATUS = 'SET_STATUS';

const initialState = {
  status: FETCHING,
  src: null,
  attempts: 0,
};

const reducer = (state, {type, payload}) => {
  switch (type) {
    case INCREMENT_ATTEMPT: {
      const attempts = state.attempts + 1;
      return {
        ...state,
        attempts,
        src: `${payload.src}#attempt${attempts}`,
      };
    }
    case SET_STATUS:
      return {
        ...state,
        status: payload,
      };
    case UPDATE_SOURCE:
      return {
        ...state,
        src: payload,
      };
    default:
      throw new Error(`Action not defined: ${type}`);
  }
};

const ImageWithLoader = ({
  src: srcFromProps,
  alt,
  disableLoader,
  hideOnFail,
  retryIfNotFound,
  onClick = () => {},
  onLoad = () => {},
  ...props
}) => {
  const [{attempts, status, src}, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({type: UPDATE_SOURCE, payload: srcFromProps});
  }, [srcFromProps]);

  const handleImageLoaded = () => {
    onLoad();
    dispatch({type: SET_STATUS, payload: COMPLETE});
  };

  const handleError = () => {
    if (retryIfNotFound && attempts < MAX_ATTEMPTS) {
      setTimeout(
        () => {
          dispatch({type: INCREMENT_ATTEMPT, payload: {src: srcFromProps}});
        },
        500 + attempts * 500,
      );
    } else {
      dispatch({type: SET_STATUS, payload: FAILED});
    }
  };

  return (
    <>
      <Image
        display={status === FETCHING || status === FAILED ? 'none' : 'block'}
        src={src}
        alt={alt}
        onLoad={handleImageLoaded}
        onError={handleError}
        onClick={onClick}
        {...props}
      />
      {(() => {
        if (status === FAILED) {
          return (
            <Box p={5} bg="grey.2" borderRadius={0}>
              <Paragraph variant={2} alignItems="center" textAlign="center">
                <Trans>This image could not be loaded.</Trans>
                {'\n'}
                <Trans>Our apologies for the inconvenience.</Trans>
              </Paragraph>
            </Box>
          );
        }
        if (status === FETCHING && !disableLoader) {
          return (
            <Box
              boxShadow={0}
              borderRadius={0}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              {...props}>
              <Loader color="primary" />
            </Box>
          );
        }
        return null;
      })()}
    </>
  );
};

export default ImageWithLoader;
