import React from 'react';
import {Trans} from '@lingui/macro';
import useFormState, {createFormValidation} from 'use-form-state';
import {isValidEmail} from '../../../utils/validation';

const INITIAL_VALUES = {
  email: '',
};

const validation = createFormValidation([
  {
    path: 'email',
    validate: isValidEmail,
    message: <Trans>Please fill in a valid email address.</Trans>,
  },
]);

const valuesToInput = ({email}) => ({
  email,
  grant_type: 'otp',
});

const useLoginEmailFormState = (values, options) =>
  useFormState(
    {
      ...INITIAL_VALUES,
      ...values,
    },
    {
      validation,
      valuesToInput,
      ...options,
    },
  );

export default useLoginEmailFormState;
