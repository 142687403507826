import {useMutation, gql} from '@apollo/client';

export const BOOKMARK_USER_MESSAGE_MUTATION = gql`
  mutation bookmarkUserMessageMutation($id: ID!) {
    bookmarkUserMessage(input: {}, id: $id)
      @rest(
        type: "UserMessage"
        path: "/user-messages/{args.id}/bookmark"
        method: "POST"
      ) {
      id
      isBookmarked
    }
  }
`;

export default function useBookmarkUserMessageMutation() {
  return useMutation(BOOKMARK_USER_MESSAGE_MUTATION);
}
