import React from 'react';
import ErrorPageWithState from '../error/ErrorPageWithState';
import ToolsPage from './ToolsPage';
import useFetchToolsPageData from './useFetchToolsPageData';

const ToolsPageWithState = () => {
  const {error, tools, isFetching} = useFetchToolsPageData();

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  return (
    <ToolsPage
      tools={tools}
      isFetching={isFetching}
      onOpenTool={({href}) => window.open(href, 'blank')}
    />
  );
};

export default ToolsPageWithState;
