import React from 'react';
import {variant} from 'styled-system';
import styled, {useTheme} from 'styled-components';
import {StyledSvg} from './Icon';

const Svg = styled(StyledSvg)`
  animation-name: spin;
  animation-duration: 850ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.55, 0.09, 0.68, 0.53);

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoaderWithVariants = styled(Svg)(
  variant({
    variants: {
      extraSmall: {
        width: 25,
        height: 25,
      },
      small: {
        width: 50,
        height: 50,
      },
      normal: {
        width: 75,
        height: 75,
      },
      large: {
        width: 100,
        height: 100,
      },
      extraLarge: {
        width: 156,
        height: 156,
      },
    },
  }),
);

const Loader = ({size = 50, color = 'white', ...props}) => {
  const theme = useTheme();
  return (
    <LoaderWithVariants
      width={size}
      height={size}
      viewBox="0 0 50 50"
      {...props}>
      <path
        fill={theme.colors[color]}
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        transform="rotate(219.86 25 25)"
      />
    </LoaderWithVariants>
  );
};

export default Loader;
