import {useState, useEffect} from 'react';
import humps from 'humps';
import debounce from 'lodash/debounce';
import {useRogerFetch} from '../../../hooks/RogerFetch';

const ENDPOINT = '/user-messages';

const useAllMessages = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [messages, setMessages] = useState([]);
  const [filters, setFilters] = useState({});
  const [totalMessages, setTotalMessages] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [search, setSearch] = useState('');
  const {authFetch} = useRogerFetch();

  const onChangeSearch = debounce(newSearchValue => {
    setSearch(newSearchValue);
  }, 500);
  const fetchMessages = async clean => {
    setIsFetching(true);
    const newPage = clean ? 1 : page;
    try {
      const qs = new URLSearchParams({
        page: newPage,
        query: search,
        ...(filters.onlyUnread ? {is_read: 0} : {}),
        ...(filters.app_domain?.length ? {app_domain: filters.app_domain} : {}),
      }).toString();
      const response = await authFetch(`${ENDPOINT}?${qs}`);
      const {
        data,
        meta: {total},
      } = response;
      setMessages(prevMessages => [
        ...(clean ? [] : prevMessages),
        ...data.map(message => humps.camelizeKeys(message)),
      ]);
      setTotalMessages(total);
      setPage(newPage + 1);
    } catch (error) {
      // console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchMessages(true);
  }, [search, filters]);
  useEffect(() => {
    if (messages.length) {
      return setHasMore(messages.length < totalMessages);
    }
    return setHasMore(false);
  }, [messages, totalMessages]);

  return {
    isFetching,
    fetchMore: fetchMessages,
    messages,
    hasMore,
    onChangeSearch,
    setFilters,
    filters,
  };
};

export default useAllMessages;
