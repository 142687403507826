import {useState, useEffect} from 'react';
import isEqual from 'lodash/isEqual';
import {whereIsNotId, whereId} from '../../utils';

const useFilterState = (initialFilters = []) => {
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  useEffect(() => {
    if (
      !isEqual(initialFilters, selectedFilters) &&
      initialFilters.length > 0
    ) {
      setSelectedFilters(initialFilters);
    }
  }, [initialFilters]);

  const addFilter = filter => {
    setSelectedFilters(prevFilters => {
      const existingFilter = prevFilters.find(whereId(filter.id));
      if (typeof existingFilter !== 'undefined') {
        return prevFilters;
      }
      return [...prevFilters, filter];
    });
  };

  const addFilters = filters => {
    setSelectedFilters(filters);
  };

  const removeFilter = filter => {
    setSelectedFilters(prevFilters =>
      prevFilters.filter(whereIsNotId(filter.id)),
    );
  };

  const handleFilter = filter => {
    const isFilterChecked =
      typeof selectedFilters.find(whereId(filter.id)) !== 'undefined';
    if (isFilterChecked) {
      removeFilter(filter);
    } else {
      addFilter(filter);
    }
  };

  return {
    addFilter,
    addFilters,
    removeFilter,
    handleFilter,
    selectedFilters,
  };
};

export default useFilterState;
