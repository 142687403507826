import {gql} from '@apollo/client';

const DEFAULT_APP_HEALTH_FRAGMENT = gql`
  fragment DefaultAppHealth on AppHealth {
    id
    createdAt
    updatedAt
    clientName
    clientId
    minClientVersion
    available
  }
`;

export default DEFAULT_APP_HEALTH_FRAGMENT;
