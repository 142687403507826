import React from 'react';
import styled from 'styled-components';
import {variant, color} from 'styled-system';
import Text from './Text';

const LabelWithVariants = styled(Text)(
  variant({
    variants: {
      1: {
        fontSize: 2,
        color: 'grey.9',
        lineHeight: 2,
        fontFamily: 'medium',
      },
      2: {
        fontSize: 2,
        color: 'grey.9',
        lineHeight: 2,
        fontFamily: 'regular',
      },
      3: {
        fontSize: 1,
        color: 'grey.5',
        lineHeight: 1,
        fontFamily: 'medium',
      },
    },
  }),
  color,
);

const Label = ({children, ...props}) => (
  <LabelWithVariants as="label" {...props}>
    {children}
  </LabelWithVariants>
);

export default Label;
