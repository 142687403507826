import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {LIST_TYPE_UNREAD} from '../../../../utils/constants';
import useFilterState from '../../../hooks/useFilterState';
import {useAppDomainsQuery} from '../../../../apollo/queries/getAppDomains';

const MessagesFilterContext = createContext();

// note:
// STATUS FILTER = UNREAD, READ or SAVED
// APP DOMAIN FILTER = APP DOMAIN
export const MessagesFilterProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {handleFilter, selectedFilters} = useFilterState();
  const {error, isFetching, appDomains} = useAppDomainsQuery();
  const history = useHistory();

  const handleChangeStatusFilter = newStatusFilter => {
    history.push(`/messages/overview/${newStatusFilter}`);
  };
  const {statusFilter} = useParams();

  useEffect(() => {
    if (typeof statusFilter === 'undefined') {
      handleChangeStatusFilter(LIST_TYPE_UNREAD);
    }
  }, [statusFilter]);
  if (typeof error !== 'undefined') {
    return null;
  }
  const ProviderValue = useMemo(
    () => ({
      isOpen,
      isFetching,
      appDomains,
      selectedFilters,
      // TODO: deprecate selectedAppDomains if prop drilling is cleaned up
      selectedAppDomains: selectedFilters,
      setIsOpen,
      handleFilter,
      handleChangeStatusFilter,
      onChangeStatusFilter: handleChangeStatusFilter,
      statusFilter,
    }),
    [isOpen, isFetching, appDomains, selectedFilters, statusFilter],
  );
  return (
    <MessagesFilterContext.Provider value={ProviderValue}>
      {children}
    </MessagesFilterContext.Provider>
  );
};

// Create a custom hook to use the context
export const useMessagesFilter = () => {
  const context = useContext(MessagesFilterContext);
  if (context === undefined) {
    throw new Error(
      'useMessagesFilter must be used within a MessagesFilterProvider',
    );
  }
  return context;
};
