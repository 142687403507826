import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';
import useModalState from '../../hooks/useModalState';
import {useUserCommentsQuery} from '../../../apollo/queries/getUserComments';
import ErrorPageWithState from '../error/ErrorPageWithState';
import useCheerCommentMutation from '../comments/useCheerCommentMutation';
import useUpdateCommentMutation from '../comments/useUpdateCommentMutation';
import useUncheerCommentMutation from '../comments/useUncheerCommentMutation';
import {getCommentsPageData} from '../comments/useFetchCommentsPageData';
import UserCommentsPage from './UserCommentsPage';
import DeleteCommentModalWithState from './DeleteCommentModalWithState';

const UserCommentsPageWithState = () => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const deleteModalState = useModalState();
  const [editingCommentId, setEditingCommentId] = useState(null);
  const {
    error,
    isFetching,
    isFetchingMore,
    hasMorePages,
    userComments,
    handleFetchMore,
    handleRefetch,
  } = useUserCommentsQuery();

  const [cheerComment] = useCheerCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [uncheerComment] = useUncheerCommentMutation();

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  const handleEditComment = comment => {
    setEditingCommentId(comment.id);
  };

  const handleSubmitEditComment = async (comment, text) => {
    const variables = {
      messageId: comment.userMessage.id,
      commentId: comment.id,
      input: {text},
    };
    await updateComment({variables});
    setEditingCommentId(null);
  };

  const handleCancelEditComment = () => {
    setEditingCommentId(null);
  };

  const handleCheerComment = async comment => {
    const {userMessage, isCheered} = comment;
    const variables = {commentId: comment.id, messageId: userMessage.id};
    if (isCheered) {
      uncheerComment({variables});
    } else {
      cheerComment({variables});
    }
  };

  const handleDeleteComment = comment => {
    const {userMessage} = comment;
    deleteModalState.open({comment, userMessage});
  };

  const handleDeleteComplete = comment => {
    const {userMessage} = comment;
    handleRefetch();
    getCommentsPageData(apolloClient, userMessage.id, {
      fetchPolicy: 'network-only',
    });
  };

  const handleNavigateToMessage = ({userMessage}) => {
    history.push(`/messages/${userMessage.id}`, {isRead: userMessage.isRead});
  };

  return (
    <>
      <UserCommentsPage
        isFetching={isFetching}
        isFetchingMore={isFetchingMore}
        hasMorePages={hasMorePages}
        comments={userComments}
        editingCommentId={editingCommentId}
        onFetchMore={handleFetchMore}
        onEditComment={handleEditComment}
        onCheerComment={handleCheerComment}
        onDeleteComment={handleDeleteComment}
        onCancelEditComment={handleCancelEditComment}
        onSubmitEditComment={handleSubmitEditComment}
        onNavigateToMessage={handleNavigateToMessage}
      />
      {deleteModalState.isOpen && (
        <DeleteCommentModalWithState
          onClose={() => deleteModalState.close()}
          onDeleteComplete={handleDeleteComplete}
          {...deleteModalState}
        />
      )}
    </>
  );
};

export default UserCommentsPageWithState;
