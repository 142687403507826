import React, {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import Box from './Box';

const THRESHOLD = [0.25, 0.5, 0.75];

const ScrollSentinel = ({height, onIntersect}) => {
  const [ref, inView] = useInView({threshold: THRESHOLD});
  useEffect(() => {
    if (inView) {
      onIntersect();
    }
  }, [inView, onIntersect]);
  return (
    <Box ref={ref} height={`${height}rem`} left={0} right={0} bottom={0} />
  );
};

export default ScrollSentinel;
