import React from 'react';
import {DialogContext} from '../providers/DialogProvider';

const useDialog = () => {
  const {dispatch} = React.useContext(DialogContext);

  const open = ({...options}) =>
    new Promise(res => {
      dispatch({type: 'OPEN', values: {actionCallback: res, ...options}});
    });

  return {
    open,
    confirm: () => dispatch({type: 'CONFIRM'}),
    dismiss: () => dispatch({type: 'DISMISS'}),
  };
};

export default useDialog;
