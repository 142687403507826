import React from 'react';
import {Trans} from '@lingui/macro';
import useFormState, {createFormValidation} from 'use-form-state';
import {isValidPhone} from '../../../utils/validation';

const INITIAL_VALUES = {
  phone: '',
};

const validation = createFormValidation([
  {
    path: 'phone',
    validate: value => isValidPhone(`+${value}`),
    message: <Trans>Please fill in a valid phone number.</Trans>,
  },
]);

const valuesToInput = ({phone}) => ({
  phone: `+${phone}`,
  grant_type: 'otp',
});

const useLoginPhoneFormState = (values, options) =>
  useFormState(
    {
      ...INITIAL_VALUES,
      ...values,
    },
    {
      validation,
      valuesToInput,
      ...options,
    },
  );

export default useLoginPhoneFormState;
