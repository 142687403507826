import {gql} from '@apollo/client';

const DEFAULT_FILE_FRAGMENT = gql`
  fragment DefaultFile on File {
    id
    name
    fileName
    mimeType
    manipulations
    orderColumn
    usedAt
    createdAt
    updatedAt
    sizes {
      full
      thumb
      small
      regular
    }
    customProperties {
      generatedConversions {
        thumb
        small
        regular
      }
    }
  }
`;

export default DEFAULT_FILE_FRAGMENT;
