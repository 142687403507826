import React from 'react';
import {Trans} from '@lingui/macro';
import Modal from '../../utils/Modal';
import ModalBottomConfirm from '../../utils/ModalBottomConfirm';
import Box from '../../utils/Box';
import Paragraph from '../../utils/Paragraph';
import ModalTopBar from '../../utils/ModalTopBar';

const DeleteCommentModal = ({
  isOpen,
  isConfirming,
  onOpen,
  onCancel,
  onDelete,
}) => (
  <Modal isOpen={isOpen} setIsOpen={onOpen}>
    <ModalTopBar title={<Trans>Delete comment</Trans>} onClose={onCancel} />
    <Box>
      <Paragraph mb={6} variant={2}>
        <Trans>Are you sure you want to delete this comment?</Trans>
      </Paragraph>
    </Box>
    <ModalBottomConfirm
      isConfirming={isConfirming}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  </Modal>
);

export default DeleteCommentModal;
