import React from 'react';
import {Trans} from '@lingui/macro';
import {LIST_TYPE_READ} from '../../../utils/constants';
import {useUnreadMessagesQuery} from '../../../apollo/queries/getUnreadMessages';
import EmptyList from '../../utils/EmptyList';
import ErrorPageWithState from '../error/ErrorPageWithState';
import MessagesList from './MessagesList';
import {useMessagesFilter} from './hooks/MessagesFilters';
import Flex from '../../../modules/utils/Flex';

const UnreadMessagesPageWithState = () => {
  const {onChangeStatusFilter} = useMessagesFilter();
  const {
    error,
    isFetching,
    isFetchingMore,
    hasMorePages,
    fetchMore,
    userMessages,
  } = useUnreadMessagesQuery();

  if (typeof error !== 'undefined') {
    return <ErrorPageWithState error={error} />;
  }

  return (
    <Flex flexDirection="column" alignItems="center" mt={7}>
        <MessagesList
          userMessages={userMessages}
          isFetching={isFetching}
          isFetchingMore={isFetchingMore}
          hasMorePages={hasMorePages}
          onFetchMore={fetchMore}
          emptyList={
            <EmptyList
              icon="inbox"
              title={<Trans>You have no unread messages.</Trans>}
              subTitle={
                <Trans>
                  Please come back later and maybe there will be new messages
                  for you.
                </Trans>
              }
              action={() => onChangeStatusFilter(LIST_TYPE_READ)}
              actionText={<Trans>Read messages</Trans>}
              mt={7}
            />
          }
          width={['100%', '50%']}
        />
      </Flex>
  );
};

export default UnreadMessagesPageWithState;
