import {parsePhoneNumberFromString} from 'libphonenumber-js/max';
import {MODEL_NOT_FOUND} from './constants';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isRequired = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  value !== '' &&
  value.length !== 0;

export const isNumber = value =>
  typeof value === 'number' && !Number.isNaN(value);

export const isGreaterThan = max => value => value < max;

export const isGreaterOrEqualThan = max => value => value >= max;

export const isLessThan = max => value => value < max;

export const isLessOrEqualThan = max => value => value <= max;

export const isValidEmail = value => emailRegex.test(value);

export const isValidPhone = (number = '') => {
  const parsedNumber = parsePhoneNumberFromString(number);
  return typeof parsedNumber !== 'undefined' && parsedNumber.isValid();
};

export const updateFormErrors = (error, callback) => {
  if (
    typeof error !== 'undefined' &&
    typeof error.networkError !== 'undefined' &&
    typeof error.networkError.result !== 'undefined'
  ) {
    const {errors} = error.networkError.result;
    if (typeof errors !== 'undefined') {
      const mappedError = Object.keys(errors).reduce((acc, curr) => {
        const newObj = errors[curr].map(errMessage => ({
          path: curr,
          message: errMessage,
        }));
        return [...acc, ...newObj];
      }, []);
      callback(mappedError);
    } else if (error.networkError.response.statusText === MODEL_NOT_FOUND) {
      return MODEL_NOT_FOUND;
    } else if (typeof error.networkError.result.message !== 'undefined') {
      const {message} = error.networkError.result;
      return message;
    }
  }
  return null;
};
