import React from 'react';
import {Trans} from '@lingui/macro';
import {IOS, ANDROID} from '../../../utils';
import Flex from '../../utils/Flex';
import Loader from '../../utils/Loader';
import WelcomePageContainer from '../welcome/WelcomePageContainer';
import WelcomePageLanguageDropdownWithState from '../welcome/WelcomePageLanguageDropdownWithState';
import MobileAppInstallIos from './MobileAppInstallIos';
import MobileAppInstallDesktop from './MobileAppInstallDesktop';
import MobileAppInstallUnknown from './MobileAppInstallUnknown';

const MobileAppInstallPage = ({
  device,
  currentCompany,
  isLoading,
  isMobileBrowser,
  onRedeemCode,
  onNavigateToPlayStore,
  onNavigateToAppStore,
  hasInternationalFlow,
}) => (
  <>
    <WelcomePageLanguageDropdownWithState position="fixed" top={4} right={4} />
    <WelcomePageContainer title={<Trans>Install mobile app</Trans>}>
      {(() => {
        if (isLoading) {
          return (
            <Flex justifyContent="center">
              <Loader color="primary" />
            </Flex>
          );
        }
        if (isMobileBrowser) {
          if (device === IOS) {
            if (!hasInternationalFlow) {
              return (
                <MobileAppInstallIos
                  currentCompany={currentCompany}
                  onRedeemCode={onRedeemCode}
                />
              );
            }
            onNavigateToAppStore();
          }
          if (device === ANDROID) {
            onNavigateToPlayStore();
          }
          return (
            <MobileAppInstallUnknown
              onRedeemCode={onRedeemCode}
              onNavigateToPlayStore={onNavigateToPlayStore}
            />
          );
        }
        return <MobileAppInstallDesktop />;
      })()}
    </WelcomePageContainer>
  </>
);

export default MobileAppInstallPage;
