import React from 'react';
import {Trans} from '@lingui/macro';
import {
  CHANNEL_SMS as SMS,
  CHANNEL_MAIL as MAIL,
  CHANNEL_WHATS_APP as WHATS_APP,
  CHANNEL_PUSH_NOTIFICATION as PUSH_NOTIFICATION,
} from '../../../utils/constants';
import Box from '../../utils/Box';
import Title from '../../utils/Title';
import Channel from './ProfileNotificationChannel';
import usePermissions from '../../hooks/usePermissions';
import useCompany from '../../hooks/useCompany';
import useCustomApp from '../../hooks/useCustomApp';

export const getChannelInfo = (channel, me, permissions, appName) => {
  const {email, workEmail, mobile, workMobile} = me;
  const {canUseWhatsApp} = permissions;
  const APP_NAME = appName;

  switch (channel) {
    case MAIL:
      if (email !== null || workEmail !== null) {
        return {
          title: <Trans>Email</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you an email when new messages are available.
            </Trans>
          ),
          icon: 'email',
        };
      }
      return null;
    case SMS:
      if (mobile !== null || workMobile !== null) {
        return {
          title: <Trans>Sms</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you an sms when new messages are available.
            </Trans>
          ),
          icon: 'chat',
        };
      }
      return null;
    case PUSH_NOTIFICATION:
      return {
        title: <Trans>{APP_NAME} App</Trans>,
        description: (
          <Trans>
            {APP_NAME} will send you a push notification when new messages are
            available.
          </Trans>
        ),
        icon: 'mobilePhone',
      };
    case WHATS_APP:
      if (canUseWhatsApp && (mobile !== null || workMobile !== null)) {
        return {
          title: <Trans>WhatsApp</Trans>,
          description: (
            <Trans>
              {APP_NAME} will send you a message on Whatsapp when new messages
              are available.
            </Trans>
          ),
          icon: 'whatsapp',
        };
      }
      return null;
    default:
      return null;
  }
};

const ProfileNotificationSettings = ({
  user,
  channels,
  userChannels,
  onUpdateUserChannels,
  ...props
}) => {
  const permissions = usePermissions();
  // TODO: Refactor this to move the app name revolving to a more appropriate place.
  let {REACT_APP_NAME: appName} = process.env;
  const {useCustomAppName} = useCustomApp();
  const {name} = useCompany();

  if (useCustomAppName) {
    appName = name;
  }

  return (
    <Box {...props}>
      <Title variant={2} mb={6}>
        <Trans>Notifications</Trans>
      </Title>
      <Box mt={6}>
        {channels.map(channel => {
          const channelInfo = getChannelInfo(
            channel,
            user,
            permissions,
            appName,
          );
          if (channelInfo === null) {
            return null;
          }
          const channelIsConfigured = userChannels.includes(channel);
          return (
            <Channel
              mb={6}
              key={channel}
              channel={channel}
              channelInfo={channelInfo}
              value={channelIsConfigured}
              onChange={onUpdateUserChannels}
              canToggleWhatsApp={
                !userChannels.includes(PUSH_NOTIFICATION) || channelIsConfigured
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ProfileNotificationSettings;
