import React from 'react';
import {Trans} from '@lingui/macro';
import {LIST_TYPE_READ, LIST_TYPE_SAVED} from '../../../utils/constants';
import MessagesPageHeaderFilters from './MessagesPageHeaderFilters';
import {useMessagesFilter} from './hooks/MessagesFilters';
import Box from '../../../modules/utils/Box';
import Flex from '../../../modules/utils/Flex';
import Heading from '../../../modules/utils/Heading';

const MessagesPageHeader = ({...props}) => {
  const {statusFilter} = useMessagesFilter();
  return (
    <Flex alignItems="center" mb={6} {...props}>
      <Heading flex={1} variant={1}>
        <Trans>Messages</Trans>
      </Heading>
      <Box display={['block', 'none']}>
        {[LIST_TYPE_READ, LIST_TYPE_SAVED].includes(statusFilter) && (
          <MessagesPageHeaderFilters />
        )}
      </Box>
    </Flex>
  );
};

export default MessagesPageHeader;
