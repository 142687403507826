import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import EmptyList from '../../utils/EmptyList';
import InfiniteScroll from '../../utils/InfiniteScroll';
import Comment from './Comment';
import CommentInput from './CommentInput';
import CommentsPageHeader from './CommentsPageHeader';
import Page, {PageContentScrollable} from '../../utils/Page';

const CommentsPage = ({
  isFetching,
  isFetchingMore,
  hasMorePages,
  currentUser,
  comments,
  editingCommentId,
  onGoBack,
  onFetchMore,
  onEditComment,
  onCheerComment,
  onDeleteComment,
  onSubmitEditComment,
  onCancelEditComment,
  onSubmitCreateComment,
}) => (
  <>
    <Header />
    <Page>
      <CommentsPageHeader onGoBack={onGoBack} />
      <Flex
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
        overflow="hidden"
        mx="auto"
        maxWidth="50rem">
        <CommentInput
          avatar={currentUser.avatar}
          onSubmitComment={onSubmitCreateComment}
        />
        <PageContentScrollable>
          <InfiniteScroll
            hasMore={hasMorePages}
            isFetchingMore={isFetchingMore || isFetching}
            onFetchMore={onFetchMore}
            width="100%">
            <Flex flexDirection="column" height="100%" width="100%">
              {!isFetching && comments.length === 0 ? (
                <EmptyList
                  icon="comments"
                  title={<Trans>No comments yet</Trans>}
                  subTitle={
                    <>
                      <Trans>No one has commented on this message yet.</Trans>
                      {'\n'}
                      <Trans>Enter your comment here.</Trans>
                    </>
                  }
                />
              ) : (
                comments.map(comment => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    currentUser={currentUser}
                    isEditing={editingCommentId === comment.id}
                    onCheerComment={() => onCheerComment(comment)}
                    onEditComment={() => onEditComment(comment)}
                    onDeleteComment={() => onDeleteComment(comment)}
                    onCancelEditComment={onCancelEditComment}
                    onSubmitEditComment={value =>
                      onSubmitEditComment(comment, value)
                    }
                  />
                ))
              )}
            </Flex>
          </InfiniteScroll>
        </PageContentScrollable>
      </Flex>
    </Page>
  </>
);

export default CommentsPage;
