import {gql} from '@apollo/client';
import {getRefreshToken} from '../../utils/storage';

export const REFRESH_ACCESS_TOKEN_MUTATION = gql`
  mutation refreshAccessTokenMutation($body: UserBodyInput!) {
    refreshAccessToken(input: $body)
      @rest(
        type: "RefreshAccessTokenResponse"
        path: "/clients/web/token"
        method: "POST"
      ) {
      accessToken
      refreshToken
    }
  }
`;

export default async function refreshAccessToken(apollo) {
  const refreshToken = await getRefreshToken();
  const body = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };
  const variables = {body};
  const mutation = REFRESH_ACCESS_TOKEN_MUTATION;
  const response = await apollo.mutate({mutation, variables});
  return response.data.refreshAccessToken;
}
