import React, {useState} from 'react';
import {Trans} from '@lingui/macro';
import {getAppStoreUrl, hasInternationalFlow} from '../../../utils/whiteLabel';
import {isMobileBrowser, getMobileOperatingSystem} from '../../../utils/index';
import useCompany from '../../hooks/useCompany';
import useNotifications from '../../hooks/useNotifications';
import MobileAppInstallPage from './MobileAppInstallPage';
import useRedeemCodeMutation from './useRedeemCodeMutation';

const device = getMobileOperatingSystem();
const isUsingMobileBrowser = isMobileBrowser();

const MobileAppInstallPageWithState = () => {
  const currentCompany = useCompany();
  const [isLoading, setIsLoading] = useState(false);
  const {dispatchError} = useNotifications();
  const [redeemCode] = useRedeemCodeMutation();
  const companyhasInternationalFlow = hasInternationalFlow(currentCompany);

  const handleNavigateToAppStore = () => {
    window.location.href = getAppStoreUrl(currentCompany);
  };

  const handleRedeemCode = async () => {
    if (companyhasInternationalFlow) {
      handleNavigateToAppStore();
    } else {
      try {
        setIsLoading(true);
        const {data} = await redeemCode();
        window.location.href = data.redeemCode.url;
      } catch (error) {
        dispatchError({
          message: (
            <Trans>
              It looks like something went wrong redeeming your iOS code.
            </Trans>
          ),
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNavigateToPlayStore = () => {
    window.location.href = `https://play.google.com/store/apps/details?id=${currentCompany.appBundleId}`;
  };

  return (
    <MobileAppInstallPage
      device={device}
      currentCompany={currentCompany}
      isLoading={isLoading}
      isMobileBrowser={isUsingMobileBrowser}
      onRedeemCode={handleRedeemCode}
      onNavigateToPlayStore={handleNavigateToPlayStore}
      onNavigateToAppStore={handleNavigateToAppStore}
      hasInternationalFlow={companyhasInternationalFlow}
    />
  );
};

export default MobileAppInstallPageWithState;
