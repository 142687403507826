import {useHistory, useLocation} from 'react-router-dom';

const useNavigateWithSearch = () => {
  const history = useHistory();
  const {search} = useLocation();

  return route => {
    history.push(route + search);
  };
};

export default useNavigateWithSearch;
