import {CUSTOM_APP_STORE_BUNDLE_IDS} from '../../utils/constants';
import {hasWhiteLabel} from '../../utils/whiteLabel';
import useAppState from './useAppState';

const useCompany = () => {
  const {currentCompany} = useAppState();
  if (currentCompany === null) {
    return {};
  }
  let appBundleId = 'io.meetroger.roger';
  if (hasWhiteLabel(currentCompany)) {
    appBundleId =
      CUSTOM_APP_STORE_BUNDLE_IDS[currentCompany.slug] ||
      `io.meetroger.${currentCompany.slug}`;
  }
  return {
    ...currentCompany,
    appBundleId,
  };
};

export default useCompany;
