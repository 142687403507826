/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {Trans} from '@lingui/macro';
import OrderedListItem from '../../../modules/utils/OrderedListItem';
import Icon from '../../utils/Icon';
import Button from '../../utils/Button';
import Paragraph from '../../utils/Paragraph';

const STEPS = [
  <Trans>
    Press the button below, this action will automatically send you to the App
    Store.
  </Trans>,
  <Trans>
    When prompted for a login, you will be prompted to enter your Apple ID
    password. This way Apple can be sure that it is you! If Apple doesn't ask
    for a login, you have ever set this up somewhere so that Apple doesn't have
    to keep asking.
  </Trans>,
  <Trans>The app will be downloaded automatically. Great!</Trans>,
];

const MobileAppInstallIos = ({currentCompany, onRedeemCode}) => {
  const APP_NAME = currentCompany.name;
  return (
    <>
      <Paragraph mb={5} variant={2}>
        <Trans>
          To download the {APP_NAME} iOS app, an access code is used in the App
          Store.
        </Trans>
      </Paragraph>
      <Paragraph mb={5} variant={2}>
        <Trans>Below are the steps to complete this process:</Trans>
      </Paragraph>
      {STEPS.map((step, index) => (
        <OrderedListItem count={index + 1} label={step} />
      ))}
      <Button mt={5} onClick={onRedeemCode}>
        <Icon name="apple" color="white" />
        <Trans>Go to the App Store!</Trans>
      </Button>
    </>
  );
};

export default MobileAppInstallIos;
