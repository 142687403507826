import React from 'react';
import {Trans} from '@lingui/macro';
import Flex from '../../utils/Flex';
import Header from '../../utils/Header';
import Loader from '../../utils/Loader';
import Heading from '../../utils/Heading';
import EmptyList from '../../utils/EmptyList';
import ToolListItem from './ToolListItem';
import Page, {PageContentScrollable} from '../../utils/Page';

const ToolsPage = ({tools, isFetching, onOpenTool}) => (
  <>
    <Header />
    <Page>
      <PageContentScrollable>
        <Heading my={6} variant={1}>
          <Trans>My tools</Trans>
        </Heading>
        <Flex flexDirection="column">
          {isFetching ? (
            <Flex height="50vh" alignItems="center" justifyContent="center">
              <Loader color="primary" />
            </Flex>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {tools.length === 0 ? (
                <EmptyList
                  icon="tools"
                  title={<Trans id="You don't have any tools yet." />}
                  subTitle={
                    <Trans id="Tools might become available when you return later." />
                  }
                />
              ) : (
                tools.map(tool => (
                  <ToolListItem
                    tool={tool}
                    key={tool.id}
                    onOpenTool={onOpenTool}
                  />
                ))
              )}
            </>
          )}
        </Flex>
      </PageContentScrollable>
    </Page>
  </>
);

export default ToolsPage;
