import {useMutation, gql} from '@apollo/client';
import DEFAULT_SURVEY_FRAGMENT from '../../../apollo/fragments/defaultSurvey';

export const SET_SURVEY_RESPONSE_MUTATION = gql`
  mutation setSurveyResponseMutation(
    $messageId: ID!
    $surveyId: ID!
    $body: SetSurveyResponseInput!
  ) {
    setSurveyResponse(messageId: $messageId, surveyId: $surveyId, input: $body)
      @rest(
        type: "Survey"
        path: "/user-messages/{args.messageId}/surveys/{args.surveyId}/response"
        method: "PUT"
      ) {
      ...DefaultSurvey
    }
  }
  ${DEFAULT_SURVEY_FRAGMENT}
`;

const useSetSurveyResponseMutation = () =>
  useMutation(SET_SURVEY_RESPONSE_MUTATION);

export default useSetSurveyResponseMutation;
