import React from 'react';
import {ErrorBoundary} from '@sentry/react';
import ErrorPage from './pages/error/ErrorPage';

const AppErrorBoundary = ({children}) => (
  <ErrorBoundary fallback={ErrorPage} showDialog>
    {children}
  </ErrorBoundary>
);

export default AppErrorBoundary;
