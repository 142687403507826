import React, {useState, useEffect} from 'react';
import {WHATS_APP} from '../../../utils/constants';
import Flex from '../../utils/Flex';
import Title from '../../utils/Title';
import Icon from '../../utils/Icon';
import Switch from '../../utils/Switch';
import Paragraph from '../../utils/Paragraph';

const Channel = ({
  channel,
  channelInfo,
  value,
  onChange,
  canToggleWhatsApp,
}) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  return (
    <Flex key={channel} flexDirection="column">
      <Flex alignItems="center" mb={1}>
        <Icon
          name={channelInfo.icon}
          color={channel === WHATS_APP ? 'whatsapp' : 'default'}
        />
        <Title variant={4} ml={3}>
          {channelInfo.title}
        </Title>
      </Flex>
      <Flex justifyContent="space-between">
        <Paragraph flex={1} variant={2} mr={3}>
          {channelInfo.description}
        </Paragraph>
        <Switch
          checked={isChecked}
          onChange={async () => {
            setIsChecked(!isChecked);
            const channelIsChanged = await onChange(channel, isChecked);
            if (!channelIsChanged) {
              setIsChecked(isChecked);
            }
          }}
          disabled={channel === WHATS_APP && !canToggleWhatsApp}
        />
      </Flex>
    </Flex>
  );
};

export default Channel;
