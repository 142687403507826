import {gql} from '@apollo/client';

const DEFAULT_COMMENT_FRAGMENT = gql`
  fragment DefaultComment on Comment {
    id
    text
    cheerCount
    deletedAt
    createdAt
    updatedAt
    isCheered
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        sizes {
          full
          thumb
          regular
        }
        customProperties {
          generatedConversions {
            thumb
            regular
          }
        }
      }
    }
  }
`;

export default DEFAULT_COMMENT_FRAGMENT;
